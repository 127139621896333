/* REAL - DASH - SERVICES - REMITTANCES */
/* File: /src/pages/Dashboard/DashboardPages/Services-Remittances/Remittances.js */
import                                      "../ManageStyle.css";
import { UserContext }                      from "../../../../App";

import { useContext }                       from "react";
import React, 
        { useState, 
          useEffect
        }                                   from "react";
import { 
        Button, 
        Table, 
        Modal,
        }                                   from "react-bootstrap";
import { toast }                            from "react-toastify";
import { BiSolidPencil }                    from "react-icons/bi";
import { 
        MdAddChart,
        MdDelete 
        }                                   from "react-icons/md";
import { 
        useUploadRemittanceMutation,
        useDeleteRemittanceMutation,
        useUpdateRemittanceMutation,    
        useGetAllRemittancesQuery,
        useGetRemittanceByIdQuery,

        }                                   from "../../../../services/svcRemittanceApi";
import { 
        useGetSubscriptionsByTypeMutation,
        useUpdateSubscriptionsMutation,
        }                                   from "../../../../services/userUserStoreApi";

import {
        useListServicesQuery,
        useUpdateServiceMutation,
        }                                   from "../../../../services/svcServiceStoreApi";

import { 
        useGetUserInfoMutation
        }                                   from "../../../../services/userUserApi";

import DeleteModal                          from "../../../../components/Modal/DeleteModal";


const ManageRemittance = () => {
    const Source_Name           = "MANAGE - SERVICES - REMITTANCE";

    /* Remittance Model */
    const [selectedId,            setSelectedId]            = useState("");
    const [serviceCode,           setServiceCode]           = useState("");
    const [countryName,           setCountryName]           = useState("");
    const [price,                 setPrice]                 = useState("");
    const [description,           setDescription]           = useState([]);
    const [servicePublished,      setServicePublished]      = useState(false);
    
    /* Modals */
    const [showModal,             setShowModal]             = useState(false);
    const [showReqModal,          setShowReqModal]          = useState(false);
    const [deleteModal,           setDeleteModal]           = useState(false);
    const [modalTitle,            setModalTitle]            = useState('');
    const [isNewRemittance,       setIsNewRemittance]       = useState(false);

    /* Selections */
    const [serviceEntry,          setServiceEntry]          = useState(null);
    const [selectedItem,          setSelectedItem]          = useState(null);
    const [userData,              setUserData]              = useState([]);
    const [subscriptionCounts,    setSubscriptionCounts]    = useState({ subscriptions: {}, requests: {}, suspended: {}, inactive: {} });
    const [selectedUserInfos,     setSelectedUserInfos]     = useState([]);
    const [selectedUsers,         setSelectedUsers]         = useState([]);

    const { user }                                      = useContext(UserContext);
    const [User_GetInfo]                                = useGetUserInfoMutation();
    const { data: remittanceData }                      = useGetRemittanceByIdQuery(selectedId);
    const { data: remittances_List }                    = useGetAllRemittancesQuery({ source: Source_Name });
    const { data: Services_List }                       = useListServicesQuery({ source: Source_Name });
    const [Remittance_uploadRemittance]                 = useUploadRemittanceMutation();
    const [Remittance_deleteRemittance]                 = useDeleteRemittanceMutation();
    const [Remittance_updateRemittance]                 = useUpdateRemittanceMutation();
    const [ServiceStore_updateService]                  = useUpdateServiceMutation();
    const [UserStore_getSubscriptionsByType]            = useGetSubscriptionsByTypeMutation();
    const [UserStore_UpdateSubscriptions]               = useUpdateSubscriptionsMutation();


    const STG_Service_TypeLabel = "remittances"

    /* Section 01.00 - initializeValues */
    const initializeValues = () => {
        setServiceCode("");
        setCountryName("");
        setPrice("");
        setDescription([]);
        setServicePublished(false);
    };

    /* Section 02.00 - handle_Remittance_Add */
    const handle_Remittance_Add = () => {
        const data = {
            serviceCode,
            countryName,
            price,
            description,
            published: servicePublished
        };
        Remittance_uploadRemittance({ data, source: Source_Name }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                initializeValues();
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 03.00 - handle_Remittance_Delete */
    const handle_Remittance_Delete = (id) => {
        Remittance_deleteRemittance({ id, source: Source_Name }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setDeleteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 04.00 - handle_Remittance_Update */
    const handle_Remittance_Update = () => {
        Remittance_updateRemittance({
            id: selectedId,
            data: { 
                serviceCode:    serviceCode,
                countryName:    countryName, 
                price:          price, 
                description:    description,
                published:      servicePublished 
            },
            source: Source_Name 
        }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setShowModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 06.00 - handle_NewPackage  - Function to handle opening the modal */
    const handle_NewPackage = () => {
        initializeValues();
        setIsNewRemittance(true);
        setShowModal(true);
        
    };

    /* Section 05.00 - handle_Publish_ServiceEntry */
    const handle_Publish_ServiceEntry = async () => {
        try {
            if (serviceEntry) {  
                const updatedService = {
                    id: serviceEntry._id,
                    data: { 
                        published: !serviceEntry.published
                    }
                };
                const result = await ServiceStore_updateService({ payload: updatedService, source: Source_Name }).unwrap();

                if (result?.status) {
                    toast.success(`Service "remittances" has been ${!serviceEntry.published ? 'published' : 'unpublished'} successfully.`);
                    setServiceEntry(prevState => ({ ...prevState, published: !prevState.published })); 
                } else {
                    toast.error(result?.message || 'Failed to update the service.');
                }
            } else {
                toast.error(`Service "remittances" not found.`);
            }
        } catch (error) {
            console.error('Error in handle_Publish_ServiceEntry:', error);
            toast.error('An error occurred while updating the service.');
        }
    };

    /* Section 09.00 - handle_Modal_Delete  */
    const handle_Modal_Delete = (item) => {
        setSelectedItem(item); // set the entire item, not just id
        setDeleteModal(true);
    };

    /* Section 10.00 - Function to handle clicking on the "Req" column */
    /* Function to handle clicking on the 'Act', 'Ina', 'Req', and 'Sus' cells */
    const handle_Modal_Edit = async (status, serviceCode) => {
        try {
            // Aggregate users based on the selected status and specific service code
            const userServices = userData.flatMap(user =>
                user.services
                    .filter(service => 
                        (serviceCode === 'all' ? service.serviceStatus === status : status === 'all' ? service.serviceCode === serviceCode : service.serviceStatus === status && service.serviceCode === serviceCode)
                    )
                    .map(service => ({
                        userId:         user.userId,
                        providerName:   service.providerName,
                        serviceCode:    service.serviceCode,
                    }))
            );
            
            // Fetch user info based on user IDs and include the service code
            const userInfos = await Promise.all(
                userServices.map(async (userService) => {
                    try {
                        const res = await User_GetInfo({ id: userService.userId, source: Source_Name }).unwrap();
                        return {
                            userId:         userService.userId,
                            memberId:       res?.result?.memberId || "N/A",
                            email:          res?.result?.email || "N/A",
                            firstName:      res?.result?.firstName || "N/A",
                            lastName:       res?.result?.lastName || "N/A",
                            serviceCode:    userService.serviceCode, // Include the service code
                        };
                    } catch (error) {
                        console.error("Error fetching user info:", error);
                        return {
                            userId:         userService.userId,
                            memberId:       "N/A",
                            email:          "N/A",
                            firstName:      "N/A",
                            lastName:       "N/A",
                            serviceCode:    userService.serviceCode, // Include the service code even in case of error
                        };
                    }
                })
            );

            // Set the modal title based on the status
            let modalTitle;
            switch (status) {
                case "active":       modalTitle = "Active Users";       break;
                case "inactive":     modalTitle = "Inactive Users";     break;
                case "requested":    modalTitle = "Requested Users";    break;
                case "suspended":    modalTitle = "Suspended Users";    break;
                default:             modalTitle = "Users";              break;
            }

            setModalTitle(modalTitle);
            setSelectedUserInfos(userInfos); // Store the user info data
            setShowReqModal(true);
        } catch (error) {
            console.error('Error in handle_Modal_Edit:', error);
        }
    };

    /* Section 06.00 - useEffect - remittanceData */
    useEffect(() => {
        if (remittanceData) {
            setServiceCode(  remittanceData?.result?.serviceCode);
            setCountryName(  remittanceData?.result?.countryName);
            setPrice(        remittanceData?.result?.price);
            setDescription(  remittanceData?.result?.description);
            setServicePublished(remittanceData?.result?.published);
        }
    }, [remittanceData]);

    /* Section 07.00 - useEffect - UserStore_getSubscriptionsByType */
    useEffect(() => {
        const fetchSubscriptionCounts = async () => {
            try {
                const response = await UserStore_getSubscriptionsByType({data: { type: STG_Service_TypeLabel }, source: Source_Name  }).unwrap();
                
                const counts = response.data.reduce((acc, user) => {
                    user.services.forEach(service => {
                        if (service.serviceStatus === "active") {
                            acc.subscriptions[service.serviceCode] = (acc.subscriptions[service.serviceCode] || 0) + 1;
                        } else if (service.serviceStatus === "inactive") {
                            acc.inactive[service.serviceCode] = (acc.inactive[service.serviceCode] || 0) + 1;
                        } else if (service.serviceStatus === "requested") {
                            acc.requests[service.serviceCode] = (acc.requests[service.serviceCode] || 0) + 1;
                        } else if (service.serviceStatus === "suspended") {
                            acc.suspended[service.serviceCode] = (acc.suspended[service.serviceCode] || 0) + 1;
                        }
                    });
                    return acc;
                }, { subscriptions: {}, requests: {}, suspended: {}, inactive: {} });
                setSubscriptionCounts(counts);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching subscription counts:', error);
            }
        };

        fetchSubscriptionCounts();
    }, [UserStore_getSubscriptionsByType]);


        /* Section 13.00 - useEffect - Services_List  */
        useEffect(() => {
            if (Services_List && Array.isArray(Services_List.data)) {
                const service = Services_List.data.find(service => service.serviceName === "remittances");
                setServiceEntry(service);  // Store the service in the state variable
                console.log("service FIND:", service);
            }
        }, [Services_List]);


    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });

    const handle_UpdSubs = (Full_Data) => {
        UserStore_UpdateSubscriptions(Full_Data).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };


    /* Section 17.0 - const handleSubscribe */
    const handleSubscribe = (serviceType, serviceCode, serviceStatus) => {
        setShowReqModal(false)
        handle_UpdSubs(  { 
            ids: selectedUsers, 
            data: { 
                subscriptions: serviceType, serviceStatus, serviceCode, providerName: "CAM Transfer",
                auditActions: createAuditAction(`Updated subscription serviceStatus to ${serviceStatus}`)
            },
            source: Source_Name,  
        } ); 

    };

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelectedUsers => {
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter(id => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    const handleGrantClick = () => {
        handleSubscribe("remittances", "remittances", "active");
    };
    
    return (
        <div className="manage-video-page">
            <div className="video-table-container">
                <h2 className="video-page-title">REMITTANCE PACKAGES</h2>

                <div className="d-flex justify-content-between align-items-center flex-wrap mt-2 mb-3">
                    {/* Add Package */}
                    <Button 
                        variant="primary" 
                        style={{ marginRight: '10px' }} 
                        disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={() => handle_NewPackage()}
                    >      
                        <MdAddChart />      
                    </Button>

                    {/* Publish/UnPublish */}
                    <Button 
                        className="btn btn-primary me-2" 
                        variant="success" 
                        style={{ marginRight: '10px' }} 
                        disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={handle_Publish_ServiceEntry}
                    >       
                        {serviceEntry?.published ? 'UnPublish Remittance' : 'Make Available'}
                    </Button>

                    <div>
                        {/* Edit */}
                        <Button 
                            className="action-icon btn btn-primary me-2" 
                            variant="warning" 
                            style={{ marginRight: '10px' }} 
                            disabled={!(user?.role === "power" || user?.role === "admin" || user?.role === "support")}
                            onClick={() => {    
                                setIsNewRemittance(false);   
                                setShowModal(true);
                            }}
                        >
                            <BiSolidPencil />
                        </Button>

                        {/* Delete */}
                        <Button 
                            className="action-icon delete btn btn-danger me-2" 
                            variant="danger" 
                            style={{ marginRight: '10px' }} 
                            disabled={user?.role !== "admin" || !selectedItem}
                            onClick={() => handle_Modal_Delete(selectedItem)}
                        >
                            <MdDelete />
                        </Button>
                    </div>

                </div>

                <div className="table-data">
                    <Table striped bordered hover>
                        {/* Table Header */}
                        <thead>
                            <tr>
                                <th></th>
                                <th>No.</th>
                                <th>Provider</th>
                                <th>Service Code</th>
                                <th>Country Name</th>
                                <th>Price</th>
                                <th>Package Description</th>
                                <th>Pkg Published</th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("active", "all")}
                                >
                                    Act<br />
                                    {(Array.isArray(remittances_List?.data) ? remittances_List.data.reduce((sum, item) => sum + (subscriptionCounts.active?.[item.serviceCode] || 0), 0) : 0)}
                                </th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("inactive", "all")}
                                >
                                    Ina<br />
                                    {(Array.isArray(remittances_List?.data) ? remittances_List.data.reduce((sum, item) => sum + (subscriptionCounts.inactive?.[item.serviceCode] || 0), 0) : 0)}
                                </th>

                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("requested", "all")}
                                >
                                    Req<br />
                                    {(Array.isArray(remittances_List?.data) ? remittances_List.data.reduce((sum, item) => sum + (subscriptionCounts.requests?.[item.serviceCode] || 0), 0) : 0)}
                                </th>


                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("suspended", "all")}
                                >
                                    Sus<br />
                                    {(Array.isArray(remittances_List?.data) ? remittances_List.data.reduce((sum, item) => sum + (subscriptionCounts.suspended?.[item.serviceCode] || 0), 0) : 0)}
                                </th>

                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("all", "all")}
                                >
                                    Tot<br />
                                    {(Array.isArray(remittances_List?.data) ? remittances_List.data.reduce((sum, item) =>
                                        sum + 
                                        (subscriptionCounts.active?.[item.serviceCode] || 0) +
                                        (subscriptionCounts.inactive?.[item.serviceCode] || 0) +
                                        (subscriptionCounts.requests?.[item.serviceCode] || 0) +
                                        (subscriptionCounts.suspended?.[item.serviceCode] || 0),
                                        0
                                    ) : 0)}
                                </th>
                            </tr>
                        </thead>

                        {/* Table Body (Data) */}
                        <tbody>
                            {remittances_List?.data?.map((item, i) => (
                                <tr key={i++}
                                    onClick={() => { setSelectedItem(item); setSelectedId(item._id); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td>
                                        <input 
                                            type="radio" 
                                            name="selectedRemittance" 
                                            value={item._id} 
                                            checked={selectedId === item._id} 
                                            readOnly
                                        />
                                    </td>
                                    <td>{i + 1}</td>
                                    <td>{item.providerName}</td>
                                    <td>{item.serviceCode}</td>
                                    <td>{item.countryName}</td>
                                    <td>${item?.price}/m</td>
                                    <td>{item?.description}</td>
                                    <td>{item.published ? 'Yes' : 'No'}</td>

                                    <td
                                        style={{ cursor: subscriptionCounts.subscriptions?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.subscriptions?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("active", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.subscriptions?.[item.serviceCode] || 0}
                                    </td>

                                    <td
                                        style={{ cursor: subscriptionCounts.inactive?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.inactive?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("inactive", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.inactive?.[item.serviceCode] || 0}
                                    </td>

                                    <td
                                        style={{ cursor: subscriptionCounts.requests?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.requests?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("requested", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.requests?.[item.serviceCode] || 0}
                                    </td>

                                    <td
                                        style={{ cursor: subscriptionCounts.suspended?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.suspended?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("suspended", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.suspended?.[item.serviceCode] || 0}
                                    </td>

                                    <td
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handle_Modal_Edit("all", item.serviceCode)}
                                    >
                                        {(
                                            (subscriptionCounts.subscriptions?.[item.serviceCode] || 0) +
                                            (subscriptionCounts.inactive?.[item.serviceCode] || 0) +
                                            (subscriptionCounts.requests?.[item.serviceCode] || 0) +
                                            (subscriptionCounts.suspended?.[item.serviceCode] || 0)
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>


            <Modal show={showReqModal} onHide={() => setShowReqModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {selectedUserInfos.map((userInfo, index) => (
                            <li key={index}>
                                <input 
                                    type="checkbox" 
                                    checked={selectedUsers.includes(userInfo.userId)} 
                                    onChange={() => handleCheckboxChange(userInfo.userId)}
                                    style={{ marginRight: '8px' }}
                                />
                                {userInfo.memberId.slice(0, 2).toUpperCase() + userInfo.memberId.slice(2).replace(/^0+/, '')}, 
                                <span style={{ color: 'blue' }}> {userInfo.firstName}</span> {userInfo.lastName}, 
                                <span style={{ color: 'green' }}> {userInfo.email}</span>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleGrantClick}>Set Active</Button>
                    <Button variant="secondary" onClick={() => setShowReqModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{isNewRemittance ? "Register New Remittance Package" : "Update Remittance Package"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form">
                        <div className="video-input-box-modal">

                            {/* Service Code */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Service Code</label>
                                <input  type="text"     id="serviceCode"    placeholder="Service Code"      value={serviceCode}
                                        onChange={e => setServiceCode(e.target.value)}
                                />
                            </div>

                            {/* Country Name */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Country Name</label>
                                <input  type="text"     id="countryName"    placeholder="Country Name"      value={countryName}
                                        onChange={e => setCountryName(e.target.value)}
                                />
                            </div>

                            {/* Price */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Monthly Price($)</label>
                                <input  type="text"     id="price"          placeholder="Price"             value={price}
                                        onChange={e => setPrice(e.target.value)}
                                />
                            </div>

                            {/* Description */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Description</label>
                                <input  type="text"     id="description"    placeholder="Description"       value={description}
                                        onChange={e => setDescription(e.target.value)}
                                />
                            </div>

                            {/* Published */}
                            <div className="form-check form-switch ps-0" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked" style={{ marginRight: "10px" }}>Publish Package: </label>
                                <input
                                    className="form-check-input mx-2"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={servicePublished}
                                    onChange={() => setServicePublished(!servicePublished)}
                                />
                                <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked">{servicePublished ? 'On' : 'Off'}</label>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isNewRemittance ? ( <Button onClick={handle_Remittance_Add}    variant="primary">   Add Package </Button>
                    ) : (               <Button onClick={handle_Remittance_Update} variant="primary">   Update      </Button>
                    )}

                    <Button variant="secondary" onClick={() => setShowModal(false)}>    Close   </Button>
                </Modal.Footer>
            </Modal>

            <DeleteModal
                question            ={`Will delete Remittance Package '${selectedItem?.serviceCode}'?`}
                heading             ={"Package Deletion"}
                deleteModal         ={deleteModal}
                setDeleteModal      ={setDeleteModal}
                handleDeleteItem    ={handle_Remittance_Delete}
                id                  ={selectedItem?._id}
            />
        </div>
    );
};

export default ManageRemittance;
