/* REAL - MY SUBSCRIPTIONS (MENU)  */
/* File: /src/pages/Subscriptions/_SubscriptionTab/SubscriptionsTabs.jsx */
import { useTranslation 
        }                       from 'react-i18next';
import { 
        AnimatePresence, 
        motion 
        }                       from "framer-motion";

import React,
        { 
        useEffect, 
        useState 
        }                       from "react";

import { 
        useLocation, 
        NavLink 
        }                       from "react-router-dom";

import { 
        TabComponentWrapper, 
        TabContentBox, 
        TabNavItem, 
        TabNavList, 
        TabNavHeader,
        }                       from "./SubscriptionsTabsStyle";
import {
        useListServicesQuery,
        }                       from "../../../services/svcServiceStoreApi";

// The services ***************************************
import MyInterests              from '../Interests';    
import PartsOrder               from '../PartsOrders';
import Internet                 from '../Internet';
import Learning                 from '../Learning';
import MyPromo                  from '../Promo';
import PartsQuotes              from '../PartsQuotes';
import Transcript               from '../Transcript';
import Remittance               from '../Remittance';
import Transactions             from '../Transactions';

// Mapping of tab IDs to query parameters
const tabMapping = {
    'my-interests': { label: 'tabs.my-interests.label', serviceName: "marketings"  },
    'my-promo':     { label: 'tabs.my-promo.label',     serviceName: "marketings" },
    'remittance':   { label: 'tabs.remittance.label',   serviceName: "remittances" },
    'transactions': { label: 'tabs.transactions.label', serviceName: "remittances" },
    'internet':     { label: 'tabs.internet.label',     serviceName: "internet" },
    'transcript':   { label: 'tabs.transcript.label',   serviceName: "transcripts" },
    'parts-order':  { label: 'tabs.parts-order.label',  serviceName: "vehicleParts" },
    'parts-quotes': { label: 'tabs.parts-quotes.label', serviceName: "vehicleParts" },
    'learning':     { label: 'tabs.learning.label',     serviceName: "courses" },
    
};


const SubscriptionsTabs = ({ tabs, user, refetch, setRefetch }) => {
    const { t } = useTranslation('subsMenu');
    const Source_Name           = "MY SUBSCRIPTIONS (MENU)";
    const location                          = useLocation();
    const [activeTab,   setActiveTab]       = useState('my-interests'); // default tab
    const { data: servicesList}             = useListServicesQuery({ source: Source_Name });

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab   = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        }
    }, [location.search]);

    // Filter the tabs based on published status
    const filteredTabMapping = Object.keys(tabMapping).filter(tabKey => {
        const serviceName = tabMapping[tabKey].serviceName;
        if (!serviceName) return true; // Tabs without a serviceName are always included
        const service = servicesList?.data?.find(service => service.serviceName === serviceName);
        return service?.published;
    });
    
    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <TabComponentWrapper id="Subscriptions">
            <TabNavHeader>
                <TabNavList $selectedid={activeTab} $tabcount={filteredTabMapping.length}>
                    {filteredTabMapping.map((tabKey) => (
                        <TabNavItem
                            key={tabKey}
                            className={tabKey === activeTab ? "TabNavItem selected" : "TabNavItem"}
                            onClick={() => handleTabChange(tabKey)}
                        >
                            <NavLink to={`/subscriptions?tab=${tabKey}`}>
                                <span>{t(tabMapping[tabKey].label)}</span> {/* Use translation here */}
                            </NavLink>
                        </TabNavItem>
                    ))}
                    <div className="slider">
                        <div className="indicator"></div>
                    </div>
                </TabNavList>
            </TabNavHeader>

            <TabContentBox>
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {activeTab === 'my-interests'   && <MyInterests   user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'my-promo'       && <MyPromo       user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'internet'       && <Internet      user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'learning'       && <Learning      user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'parts-order'    && <PartsOrder    user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'parts-quotes'   && <PartsQuotes   user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'transcript'     && <Transcript    user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'remittance'     && <Remittance    user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'transactions'   && <Transactions  user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {/* Add more tab components here based on their respective activeTab values */}
                    </motion.div>
                </AnimatePresence>
            </TabContentBox>
        </TabComponentWrapper>
    );
};

export default SubscriptionsTabs;
