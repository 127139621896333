/* REAL - HOME PAGE - SERVICE - REMITTANCE */
/* File: /src/pages/Home/ServicesSection/Remittance/index.jsx */
import "../servicesStyle.css";
import { 
    useTranslation 
    }                       from 'react-i18next';
import { toast }                                         from "react-toastify";
import { 
         Image,
         Tooltip, 
         OverlayTrigger 
       }                                                    from "react-bootstrap";
import { 
         useNavigate 
       }                                                    from "react-router-dom";
import { 
         useContext,
         useState,
         useEffect,
       }                                                    from "react";
import { 
         UserContext
       }                                                    from "../../../../App";
import { 
         FeatureList, 
         FeatureListItem, 
         RemittancePlanBox, 
       }                                                    from "./RemittanceStyle";
import { 
         SVGImages 
       }                                                    from "../../../../config/images";

import { 
        useGetAllRemittancesQuery 
       }                                                    from "../../../../services/svcRemittanceApi";
import { 
         useGetSubscriptionsMutation,
       }                                                    from "../../../../services/userUserStoreApi";
import {
        useRetrieveDocumentListByUserIdMutation, 
        }                                                    from "../../../../services/userDocumentApi";
import { 
        useGetTransactionsByUserQuery 
       }                                                    from "../../../../services/orderTransactionApi";
import { 
         BoldContent, 
         TabContentContainer, 
         TabTitle,
         OrderButton,
         RestrictionText
       }                                                    from "../../../Tabs/tabContentStyle";
import CardSlider                                           from "../../../../components/UI/CardSlider";
import SvcRemittanceModal                                     from "../../../Modal/SvcRemittanceModal";
import { 
        FlagImages 
        }                                                   from "../../../../config/images"; // Import your flag images



const features = ["fast_delivery", "easy_secure_process", "obtain_points", "refer_friends_rewards", "convenient_anywhere"];


const SvcInternet = ({ tabLabel, sectionID }) => {
    const { t } = useTranslation('homeServicesRemittance');
    const Source_Name           = "HOME PAGE - SERVICE - REMITTANCE";
    const navigator                                     = useNavigate();
    const [showModal,       setShowModal]               = useState(false);
    const [isIdVerified,    setIsIdVerified]            = useState(false);

    const { user }                                      = useContext(UserContext);
    const { data, isLoading }                           = useGetAllRemittancesQuery({ source: Source_Name });
    const [UserStore_GetSubscriptions]                  = useGetSubscriptionsMutation();
    const [retrieveDocumentListByUserId]                = useRetrieveDocumentListByUserIdMutation();
    const [subscriptionData, setSubscriptionData]       = useState(null);
    const { data: transactionData }                     = useGetTransactionsByUserQuery({ id: user?._id, source: Source_Name });
    const transactionCount                              = transactionData?.data?.[0]?.transactions?.length || 0;

    

    useEffect(() => {
        if (user?._id) {
            const data = {  userIds: [user._id], };
              
            UserStore_GetSubscriptions({ data, source: Source_Name })
            .then(response => {
                setSubscriptionData(response.data);
                console.log("response", response.data);
            });
        }
    }, [user, UserStore_GetSubscriptions]);

    /* Section 09.0 - useEffect - user?._id, retrieveDocumentListByUserId   */
    useEffect(() => {
        if (user?._id) {
            retrieveDocumentListByUserId(user?._id).then(res => {
                if (res?.data?.status && res?.data?.documentList) {
                    const isVerified = res.data.documentList.some(doc => doc.docStatus === "Verified");
                    setIsIdVerified(isVerified);
                } else {
                    toast.error("Failed to retrieve documentList.");
                }
            }).catch(err => {
                toast.error("Error fetching documents.");
            });
        }
    }, [user?._id, retrieveDocumentListByUserId]);


    const getFlagForPlan = (plan) => {
        switch (plan.countryName) {
            case 'Bahamas':                     return FlagImages.FlagCountryBahamas;
            case 'Canada':                      return FlagImages.FlagCountryCanada;
            case 'Dominican Republic':          return FlagImages.FlagCountryDominicanRepublic;
            case 'Haiti':                       return FlagImages.FlagCountryHaiti;
            case 'Turks And Caicos':            return FlagImages.FlagCountryTurksAndCaicos;
            case 'United-States of America':    return FlagImages.FlagCountryUnitedStates;
            default:                            return null;
        }
    };

    const settings = {
        className:      "slider center variable-width",
        centerMode:     true,
        infinite:       true,
        centerPadding:  "20%",
        slidesToShow:   1,
        dots:           true,
        speed:          500,
        autoplay:       true,         // Enable auto-scrolling
        autoplaySpeed:  2200,         
        responsive: [
            {   breakpoint: 1400,   settings: {  centerPadding: "10%"  }   },
            {   breakpoint: 1200,   settings: {  centerPadding: "5%"   }   },
            {   breakpoint: 600,    settings: {  centerPadding: "0%"   }   }
        ]
    };

    if (isLoading) { 
        return <p>Loading</p>; 
    }

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);



    return (
        <>
            <TabContentContainer id={sectionID}>
                <TabTitle className="text-center">{tabLabel}</TabTitle>
                <BoldContent>
                    <p>{t('content.intro')}</p>
                </BoldContent>
                <FeatureList>
                    {features.map((feature, i) => {
                        return (
                            <FeatureListItem key={i}>
                                <SVGImages.CheckCircleIcon />
                                <span>{t(`content.features.${feature}`)}</span>
                            </FeatureListItem>

                        );
                    })}
                </FeatureList>
                <RemittancePlanBox>
                    {data?.data?.length > 0 && (
                        <CardSlider sliderConfig={settings}>
                            {data?.data
                                ?.filter(plan => plan.countryName !== 'All') // Filter out 'All'
                                .map(plan => {
                                    const flag = getFlagForPlan(plan); // Get the flag for this plan

                                    return (
                                        <div key={plan._id} className="center-content">
                                            {/* Display the flag associated with the plan */}
                                            {flag && (
                                                <Image 
                                                    src={flag} 
                                                    alt={`${plan.countryName} flag`} 
                                                    fluid 
                                                    className="fixed-image" // Apply the fixed size class
                                                />
                                            )}
                                            <p>{plan.countryName}</p> {/* Country name is centered under the flag */}
                                        </div>
                                    );
                                })}
                        </CardSlider>
                    )}
                </RemittancePlanBox>





                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-save">
                            {user?._id ?
                                (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.remittances) && user.subscriptions.remittances.length > 0)
                                    ? t('buttons.view_my_remittance')
                                    : t('buttons.request_access_remittance'))
                                : t('buttons.signin_or_login')
                            }
                        </Tooltip>
                    }
                >
                    <>
                        <div className="d-flex justify-content-center">
                            <OrderButton
                                variant="secondary"
                                className="me-2"  // Adds margin to the right of this button
                                onClick={() => {
                                    console.log("subscriptionData:", subscriptionData);
                                    if (!user?._id) {
                                        navigator("/signin?req=accessRemittance");
                                    } else if (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.remittances) && user.subscriptions.remittances.length > 0 && user.subscriptions.remittances[0].serviceStatus !== 'requested')) {
                                        navigator("/subscriptions?tab=remittance"); 
                                    } else if (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.remittances) && user.subscriptions.remittances.length === 0)) {
                                        handleShowModal(); // Show modal for requesting a package
                                    } else {
                                        navigator("/settings?tab=documents");
                                    }
                                }}
                            >
                                {
                                    user?._id ? (
                                        subscriptionData?.data?.some(user =>
                                            Array.isArray(user.subscriptions?.remittances) &&
                                            user.subscriptions.remittances.length > 0 &&
                                            user.subscriptions.remittances[0].serviceStatus === 'active'
                                        )
                                            ? t('subscription.send_now')
                                            : (
                                                subscriptionData?.data?.some(user =>
                                                    Array.isArray(user.subscriptions?.remittances) &&
                                                    user.subscriptions.remittances.length === 0
                                                )
                                                    ? t('subscription.request_access')
                                                    : !isIdVerified
                                                        ? t('subscription.update_documents')
                                                        : t('subscription.view_my_ids')
                                            )
                                    ) : t('subscription.signin_to_request')
                                }
                            </OrderButton>

                            {user?._id && transactionCount > 0 && (
                                <OrderButton
                                    variant="secondary" className="ms-2"
                                    onClick={() => { navigator("/subscriptions?tab=transactions"); }}
                                >
                                    {`${t('subscription.my_transactions')} (${transactionCount})`}
                                </OrderButton>
                            )}
                        </div>

                        {/* Conditionally display the pending request message in red  */}
                        {subscriptionData?.data?.map((user, userIndex) => {
                            const pendingRequests = user.subscriptions?.remittances?.filter(remittance => remittance.serviceStatus === 'requested');

                            return pendingRequests?.length > 0 && (
                                pendingRequests.map((remittance, remittanceIndex) => (
                                    <p key={`${userIndex}-${remittanceIndex}`} style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                                        {t('content.pending_requests', { serviceCode: remittance.serviceCode })}
                                    </p>
                                ))
                            );
                        })}


                    </>


                </OverlayTrigger>
                {!user?._id && <RestrictionText>{t('content.login_to_order_remittance')}</RestrictionText>}
            </TabContentContainer>

            <SvcRemittanceModal 
                showModal={showModal} 
                handleCloseModal={handleCloseModal} 
                user={user} 
                allRemittance={data} 
            />
        </>
    );
};

export default SvcInternet;
