/* File: /src/services/svcMarketingApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const svcMarketingApi = createApi({
    reducerPath: "svcMarketingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/svcMarketings",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["Marketings"],
    endpoints: builder => ({
        getAllMarketings:   builder.query({    query: ({ source }) =>       ({ url: "/",      method: "GET", params: { source }, }),                        providesTags: ["Marketings"] }),
        getMarketingById:   builder.query({    query: ({ id, source }) =>  ({ url: `/${id}`,  method: "GET", params: { source }, }),                        providesTags: ["Marketings"] }),
    
        uploadMarketing:    builder.mutation({ query: ({ data, source }) => ({ url: "/",      method: "POST", body: { ...data, source } }),                invalidatesTags: ["Marketings"] }),
    
        updateMarketing:    builder.mutation({ query: ({ payload, source }) => ({ url: `/${payload.id}`, method: "PATCH", body: { ...payload.data, source } }),  invalidatesTags: ["Marketings"] }),
    
        deleteMarketing:    builder.mutation({ query: ({ id, source }) =>  ({ url: `/${id}`,  method: "DELETE", params: { source } }),                     invalidatesTags: ["Marketings"] }),
    
        healthCheck:        builder.query({    query: ({ source }) =>       ({ url: `/health`,          method: "GET", params: { source }, })        }),
    })
    
});

export const { 
    useHealthCheckQuery,
    useDeleteMarketingMutation,
    useGetAllMarketingsQuery,
    useGetMarketingByIdQuery,
    useUpdateMarketingMutation,
    useUploadMarketingMutation
} = svcMarketingApi;
