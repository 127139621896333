/* REAL - DASH - SERVICES - INTERNET */
/* File: /src/pages/Dashboard/DashboardPages/Services-Internet/HomeInternet.js  */
import                                      "../ManageStyle.css";
import { UserContext }                      from "../../../../App";

import { useContext }                       from "react";
import  React, 
        { useState, 
          useEffect
        }                                   from "react";
import { 
        Button, 
        Table, 
        Modal,
        }                                   from "react-bootstrap";
import { toast }                            from "react-toastify";
import { BiSolidPencil }                    from "react-icons/bi";
import { 
        MdAddChart,
        MdDelete 
        }                                   from "react-icons/md";
import {
        useInternetAddPackageMutation, 
        useInternetUpdatePackageMutation,
        useInternetDeletePackageMutation, 
        useInternetListPackagesQuery, 
        useInternetListByIdQuery, 
        
        }                                   from "../../../../services/svcInternetApi";
import { 
        useGetSubscriptionsByTypeMutation,
        }                                   from "../../../../services/userUserStoreApi";
import {
        useListServicesQuery,
        useUpdateServiceMutation,
        }                                   from "../../../../services/svcServiceStoreApi";

import { 
        useGetUserInfoMutation
       }                                    from "../../../../services/userUserApi";
        
import DeleteModal                          from "../../../../components/Modal/DeleteModal";


const ManageInternet = () => {
    const Source_Name           = "MANAGE - SERVICES - INTERNET";
    /* Internet Model */
    const [selectedId,              setSelectedId]            = useState("");
    const [serviceCode,             setServiceCode]           = useState("");
    const [providerName,            setProviderName]          = useState("");

    const [downloadSpeed,           setDownloadSpeed]         = useState("");
    const [uploadSpeed,             setUploadSpeed]           = useState("");
    const [price,                   setPrice]                 = useState("");
    const [extraInfo,               setExtraInfo]             = useState([]);
    const [pkgPublished,            setPkgPublished]          = useState(false);

    /* Modals */
    const [showModal,               setShowModal]             = useState(false);
    const [showReqModal,            setShowReqModal]          = useState(false);
    const [deleteModal,             setDeleteModal]           = useState(false);
    const [modalTitle,              setModalTitle]            = useState('');

    /* Selections */
    const [selectedItem,            setSelectedItem]          = useState(null);

    const [selectedUserInfos,       setSelectedUserInfos]     = useState([]);

    const [serviceEntry,            setServiceEntry]          = useState(null);
    const [isNewPackage,            setIsNewPackage]          = useState(false);
    const [temp,                    setTemp]                  = useState("");
    const [userData,                setUserData]              = useState([]);
    const [subscriptionCounts,      setSubscriptionCounts]    = useState({ subscriptions: {}, requests: {} });
    
    const { user }                                      = useContext(UserContext);
    const [User_GetInfo]                                = useGetUserInfoMutation();
    const [Internet_AddPackage]                         = useInternetAddPackageMutation();
    const [Internet_DeletePackage]                      = useInternetDeletePackageMutation();
    const [Internet_UpdatePackage]                      = useInternetUpdatePackageMutation();
    const [Service_Update]                              = useUpdateServiceMutation();
    const [getSubscriptionsByType]                      = useGetSubscriptionsByTypeMutation(); // checked
    const { data: Internet_List }                       = useInternetListPackagesQuery({ source: Source_Name });
    const { data: Services_List}                        = useListServicesQuery({ source: Source_Name });
    const { data: Internet_Data }                       = useInternetListByIdQuery({ id: selectedId, source: Source_Name });

    const STG_Service_TypeLabel = "internet"



    /* Section 01.00 - initializeValues  */
    const initializeValues = () => {
        setServiceCode("");
        setDownloadSpeed("");
        setUploadSpeed("");
        setPrice("");
        setExtraInfo([]);
        setTemp("");
        setPkgPublished(false);
    }

    /* Section 02.00 - handle_Internet_AddPackage  */
    const handle_Internet_AddPackage = () => {
        const data = {
            serviceCode,
            providerName,
            downloadSpeed,
            uploadSpeed,
            price,
            extraInfo,
            published: pkgPublished,
        };
        Internet_AddPackage({ data, source: "MANAGE - SERVICES - INTERNET" }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                initializeValues();
            } else {
                toast.error(res?.data?.message);
            }
        });
        
    };

    /* Section 03.00 - handle_Internet_DeletePackage  */
    const handle_Internet_DeletePackage = id => {
        Internet_DeletePackage({ id, source: "MANAGE - SERVICES - INTERNET" }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setDeleteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
        
    };

    /* Section 04.00 - handle_Internet_UpdatePackage  */
    const handle_Internet_UpdatePackage = () => {
        Internet_UpdatePackage({
            id: selectedId,
            data: { 
                serviceCode:    serviceCode,
                providerName:   providerName, 
                downloadSpeed:  downloadSpeed, 
                uploadSpeed:    uploadSpeed, 
                price:          price, 
                extraInfo:      extraInfo,
                published:      pkgPublished 
            },
            source: "MANAGE - SERVICES - INTERNET" // Passing the source outside of data
        }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setShowModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
        
    };

    /* Section 05.00 - handleExtra  */
    const handleExtra = () => {
        setExtraInfo([...extraInfo, temp]);
        setTemp("");
    };

    /* Section 06.00 - handle_NewPackage  - Function to handle opening the modal */
    const handle_NewPackage = () => {
        initializeValues();
        setIsNewPackage(true);
        setShowModal(true);
        
    };

    /* Section 07.0 - handle_PkgPublished_Change */
    const handle_PkgPublished_Change = () => {
        setPkgPublished(prev => !prev);
    };

    /* Section 08.00 - handle_Publish_ServiceEntry */
    const handle_Publish_ServiceEntry = async () => {
        try {
            // Log the services list data
            console.log("SERVICES LIST RAW DATA:", Services_List);
    
            if (serviceEntry) {  // Use serviceEntry instead of finding it again
                const updatedService = {
                    id: serviceEntry._id,
                    data: { 
                        published: !serviceEntry.published  // Toggle the published status
                    }
                };
                console.log("updatedService:", updatedService);
    
                // const result = await Service_Update(updatedService).unwrap();
                const result = await Service_Update({ payload: updatedService, source: "MANAGE - SERVICES - INTERNET" }).unwrap();

                if (result?.status) {
                    toast.success(`Service "${STG_Service_TypeLabel}" has been ${!serviceEntry.published ? 'published' : 'unpublished'} successfully.`);
                    setServiceEntry(prevState => ({ ...prevState, published: !prevState.published }));  // Update the state after success
                } else {
                    toast.error(result?.message || 'Failed to update the service.');
                }
            } else {
                toast.error(`Service "${STG_Service_TypeLabel}" not found.`);
            }
        } catch (error) {
            console.error('Error in handle_Publish_ServiceEntry:', error);
            toast.error('An error occurred while updating the service.');
        }
    };


    /* Section 09.00 - handle_Modal_Delete  */
    const handle_Modal_Delete = (item) => {
        setSelectedItem(item); // set the entire item, not just id
        setDeleteModal(true);
    };

    /* Section 10.00 - Function to handle clicking on the "Req" column */
    /* Function to handle clicking on the 'Act', 'Ina', 'Req', and 'Sus' cells */
    const handle_Modal_Edit = async (status, serviceCode) => {
        try {
            // Aggregate users based on the selected status and specific service code
            const userServices = userData.flatMap(user =>
                user.services
                    .filter(service => 
                        (serviceCode === 'all' ? service.serviceStatus === status : status === 'all' ? service.serviceCode === serviceCode : service.serviceStatus === status && service.serviceCode === serviceCode)
                    )
                    .map(service => ({
                        userId: user.userId,
                        providerName: service.providerName,
                        providerAccNb: service.providerAccNb,
                        firstActivation: service.firstActivation,
                        serviceCode: service.serviceCode,
                    }))
            );
            
            

            // Fetch user info based on user IDs and include the service code
            const userInfos = await Promise.all(
                userServices.map(async (userService) => {
                    try {
                        const res = await User_GetInfo({ id: userService.userId, source: "MANAGE - SERVICES - INTERNET" }).unwrap();

                        return {
                            userId: userService.userId.slice(-7),
                            email: res?.result?.email || "N/A",
                            firstName: res?.result?.firstName || "N/A",
                            lastName: res?.result?.lastName || "N/A",
                            providerAccNb: userService.providerAccNb,
                            serviceCode: userService.serviceCode, // Include the service code
                        };
                    } catch (error) {
                        console.error("Error fetching user info:", error);
                        return {
                            userId: userService.userId.slice(-7),
                            email: "N/A",
                            firstName: "N/A",
                            lastName: "N/A",
                            providerAccNb: userService.providerAccNb,
                            serviceCode: userService.serviceCode, // Include the service code even in case of error
                        };
                    }
                })
            );

            // Set the modal title based on the status
            let modalTitle;
            switch (status) {
                case "active":
                    modalTitle = "Active Users";
                    break;
                case "inactive":
                    modalTitle = "Inactive Users";
                    break;
                case "requested":
                    modalTitle = "Requested Users";
                    break;
                case "suspended":
                    modalTitle = "Suspended Users";
                    break;
                default:
                    modalTitle = "Users";
                    break;
            }

            setModalTitle(modalTitle);
            setSelectedUserInfos(userInfos); // Store the user info data
            setShowReqModal(true);
        } catch (error) {
            console.error('Error in handle_Modal_Edit:', error);
        }
    };

    


    /* Section 11.00 - useEffect - Internet_Data */
    useEffect(() => {
        if (Internet_Data) {
            setServiceCode(Internet_Data?.result?.serviceCode);
            setDownloadSpeed(Internet_Data?.result?.downloadSpeed);
            setUploadSpeed(Internet_Data?.result?.uploadSpeed);
            setPrice(Internet_Data?.result?.price);
            setExtraInfo(Internet_Data?.result?.extraInfo);
            setPkgPublished(Internet_Data?.result?.published);
        }
    }, [Internet_Data]);

    /* Section 12.00 - useEffect - getSubscriptionsByType  */
    useEffect(() => {
        const fetchSubscriptionCounts = async () => {
            try {
                // const response = await getSubscriptionsByType({ type: STG_Service_TypeLabel }).unwrap();
                const response = await getSubscriptionsByType({data: { type: STG_Service_TypeLabel }, source: "MANAGE - SERVICES - INTERNET"  }).unwrap();
                console.log("FETCH=", response);

                /* EXAMPLE RESPONSE.DATA
                    {
                        "userId": "66bec93708a1453f2db51ad3",
                        "services": [
                        {
                            "serviceCode": "INT-120",
                            "serviceStatus": "active",
                            "providerName": "Bravo Telecom",
                            "providerAccNb": "ACC53329",
                            "firstActivation": "2024-08-17T03:11:45.727Z"
                        }
                        ]
                    },
                */
                const counts = response.data.reduce((acc, user) => {
                    user.services.forEach(service => {
                        if (service.serviceStatus               === "inactive") {
                            acc.inactive[service.serviceCode]   = (acc.inactive[service.serviceCode] || 0) + 1;

                        } else if (service.serviceStatus        === "requested") {
                            acc.requests[service.serviceCode]   = (acc.requests[service.serviceCode] || 0) + 1;

                        } else if (service.serviceStatus        === "active") {
                            acc.active[service.serviceCode]     = (acc.active[service.serviceCode] || 0) + 1;

                        } else if (service.serviceStatus        === "suspended") {  // Handle suspended service.serviceStatus
                            acc.suspended[service.serviceCode] = (acc.suspended[service.serviceCode] || 0) + 1;
                        }
                    });
                    return acc;
                }, { active: {}, inactive: {}, requests: {}, suspended: {} });
    
                console.log('Computed counts:', counts); // For debugging purposes
                setSubscriptionCounts(counts);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching subscription counts:', error);
            }
        };
    
        fetchSubscriptionCounts();
    }, [getSubscriptionsByType]);
    
    /* Section 13.00 - useEffect - Services_List  */
    useEffect(() => {
        if (Services_List && Array.isArray(Services_List.data)) {
            const service = Services_List.data.find(service => service.serviceName === "internet");
            setServiceEntry(service);  // Store the service in the state variable
            console.log("service FIND:", service);
    
            // Set the providerName if there is only one
            if (service && service.providers.length === 1) {
                setProviderName(service.providers[0].providerName);
            }
        }
    }, [Services_List]);





    
    return (
        <div className="manage-video-page">
            <div className="video-table-container">
                <h2 className="video-page-title">INTERNET PACKAGES</h2>
                
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-2 mb-3">
                    <Button 
                        variant="primary" 
                        style={{ marginRight: '10px' }} 
                        disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={() => handle_NewPackage()}
                    >      
                        <MdAddChart />      
                    </Button>

                    <Button 
                        className="btn btn-primary me-2" 
                        variant="success" 
                        style={{ marginRight: '10px' }} 
                        disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={handle_Publish_ServiceEntry}
                    >       
                        {serviceEntry?.published ? 'UnPublish Internet' : 'Make Available'}
                    </Button>

                    <div>
                        <Button 
                            className="action-icon btn btn-primary me-2" 
                            variant="warning" 
                            style={{ marginRight: '10px' }} 
                            disabled={!(user?.role === "power" || user?.role === "admin" || user?.role === "support")}
                            onClick={() => {    
                                setIsNewPackage(false);   
                                setShowModal(true);
                            }}
                        >
                            <BiSolidPencil />
                        </Button>

                        <Button 
                            className="action-icon delete btn btn-danger me-2" 
                            variant="danger" 
                            style={{ marginRight: '10px' }} 
                            disabled={user?.role !== "admin" || !selectedItem}
                            onClick={() => handle_Modal_Delete(selectedItem)}
                        >
                            <MdDelete />
                        </Button>
                    </div>
                </div>



                <div className="table-data">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>No.</th>
                                <th>Provider</th>
                                <th>Service<br /> Code</th>
                                <th>Dwnl<br />Mbps</th>
                                <th>Upld<br />Mbps</th>
                                <th>Price <br />/Mnt</th>
                                <th>Package<br /> Information</th>
                                <th>Pkg<br />Published</th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("active", "all")}
                                >
                                    Act<br />
                                    {(Array.isArray(Internet_List?.data) ? Internet_List.data.reduce((sum, item) => sum + (subscriptionCounts.active?.[item.serviceCode] || 0), 0) : 0)}
                                </th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("inactive", "all")}
                                >
                                    Ina<br />
                                    {(Array.isArray(Internet_List?.data) ? Internet_List.data.reduce((sum, item) => sum + (subscriptionCounts.inactive?.[item.serviceCode] || 0), 0) : 0)}
                                </th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("requested", "all")}
                                >
                                    Req<br />
                                    {(Array.isArray(Internet_List?.data) ? Internet_List.data.reduce((sum, item) => sum + (subscriptionCounts.requests?.[item.serviceCode] || 0), 0) : 0)}
                                </th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("suspended", "all")}
                                >
                                    Sus<br />
                                    {(Array.isArray(Internet_List?.data) ? Internet_List.data.reduce((sum, item) => sum + (subscriptionCounts.suspended?.[item.serviceCode] || 0), 0) : 0)}
                                </th>
                                <th 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handle_Modal_Edit("all", "all")}
                                >
                                    Tot<br />
                                    {(Array.isArray(Internet_List?.data) ? Internet_List.data.reduce((sum, item) =>
                                        sum + 
                                        (subscriptionCounts.active?.[item.serviceCode] || 0) +
                                        (subscriptionCounts.inactive?.[item.serviceCode] || 0) +
                                        (subscriptionCounts.requests?.[item.serviceCode] || 0) +
                                        (subscriptionCounts.suspended?.[item.serviceCode] || 0),
                                        0
                                    ) : 0)}
                                </th>

                            </tr>
                        </thead>

                        <tbody>
                            {Internet_List?.data?.map((item, i) => (
                                <tr key={i++}
                                    onClick={() => { setSelectedItem(item); setSelectedId(item._id); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td>
                                        <input 
                                            type="radio" 
                                            name="selectedService" 
                                            value={item._id} 
                                            checked={selectedId === item._id} 
                                            readOnly
                                        />
                                    </td>
                                    <td>{i + 1}</td>
                                    <td>{item.providerName}</td>
                                    <td>{item.serviceCode}</td>
                                    <td>{item.downloadSpeed}</td>
                                    <td>{item.uploadSpeed}</td>
                                    <td>${item?.price}</td>

                                    {/* PACKAGE INFORMATION */}
                                    <td>
                                        {item?.extraInfo?.map((e, i) => (
                                            <>
                                                {i + 1}. {e} <br />
                                            </>
                                        ))}
                                    </td>
                                    <td>{item.published ? 'Yes' : 'No'}</td>

                                    {/* ACTIVE */}
                                    <td
                                        style={{ cursor: subscriptionCounts.active?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.active?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("active", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.active?.[item.serviceCode] || 0}
                                    </td>

                                    {/* INACTIVE */}
                                    <td
                                        style={{ cursor: subscriptionCounts.inactive?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.inactive?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("inactive", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.inactive?.[item.serviceCode] || 0}
                                    </td>

                                    {/* REQUESTS */}
                                    <td
                                        style={{ cursor: subscriptionCounts.requests?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.requests?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("requested", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.requests?.[item.serviceCode] || 0}
                                    </td>

                                    {/* SUSPENDED */}
                                    <td
                                        style={{ cursor: subscriptionCounts.suspended?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.suspended?.[item.serviceCode] > 0) {
                                                handle_Modal_Edit("suspended", item.serviceCode);
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.suspended?.[item.serviceCode] || 0}
                                    </td>

                                    {/* TOTAL */}
                                    <td
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handle_Modal_Edit("all", item.serviceCode)}
                                    >
                                        {(
                                            (subscriptionCounts.active?.[item.serviceCode] || 0) +
                                            (subscriptionCounts.inactive?.[item.serviceCode] || 0) +
                                            (subscriptionCounts.requests?.[item.serviceCode] || 0) +
                                            (subscriptionCounts.suspended?.[item.serviceCode] || 0)
                                        )}
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </div>


            <Modal show={showReqModal} onHide={() => setShowReqModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {selectedUserInfos.map((userInfo, index) => (
                        <li key={index}>
                            {userInfo.userId}, {userInfo.providerAccNb}, 
                            <span style={{ color: 'blue' }}> {userInfo.firstName}</span> {userInfo.lastName}, 
                            <span style={{ color: 'green' }}> {userInfo.email}</span>
                        </li>
                        
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReqModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{isNewPackage ? "Register New Internet Package" : "Update Internet Package"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form">
                        <div className="video-input-box-modal">

                            {/* Service Code */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Service Code</label>
                                <input  type="text"     id="serviceCode"    placeholder="Service Code"      value={serviceCode}
                                        onChange={e => setServiceCode(e.target.value)}
                                />
                            </div>

                            {/* Provider */}
                            <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-4">
                                <label htmlFor="providerName">Provider</label>
                                <select
                                    id="providerName"
                                    className="shadow-none"
                                    value={providerName}
                                    onChange={e => setProviderName(e.target.value)}
                                    disabled={serviceEntry?.providers?.length === 1} // Disable if only one providerName
                                >
                                    <option value="">Select Provider</option>
                                    {serviceEntry?.providers?.map((prov, index) => (
                                        <option key={index} value={prov.providerName}>
                                            {prov.providerName}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            {/* Monthly Price */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Monthly Price($)</label>
                                <input  type="text"     id="price"          placeholder="Price"             value={price}
                                        onChange={e => setPrice(e.target.value)}
                                />
                            </div>

                            {/* Download Speed */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Download Speed</label>
                                <input  type="text"     id="downloadSpeed"  placeholder="Download Speed"    value={downloadSpeed}
                                        onChange={e => setDownloadSpeed(e.target.value)}
                                />
                            </div>

                            {/* Upload Speed */}
                            <div className="input-wrapper">
                                <label  htmlFor="title">Upload Speed</label>
                                <input  type="text"     id="uploadSpeed"    placeholder="Upload Speed"      value={uploadSpeed}
                                        onChange={e => setUploadSpeed(e.target.value)}
                                />
                            </div>

                            {/* Extra Info */}
                            <div className="input-wrapper">
                                
                                <label htmlFor="title">Extra Info</label>
                                <div className="d-flex justify-content-start align-items-center">
                                    <input  type="text"  id="extraInfo"    placeholder="Extra Info"         value={temp}
                                            onChange={e => setTemp(e.target.value)}
                                    />
                                    <button onClick={handleExtra} className="extra-btn">    Add     </button>
                                </div>



                                <div>
                                    {extraInfo?.length > 0 && (
                                        <>
                                            <h5>Extra Information</h5>
                                            {extraInfo?.map((e, i) => (
                                                <p key={i} className="mb-0">
                                                    {i + 1}. {e}
                                                </p>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Published Package */}
                            <div className="form-check form-switch ps-0" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked" style={{ marginRight: "10px" }}>Publish Package: </label>
                                <input
                                    className="form-check-input mx-2"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={pkgPublished}
                                    onChange={handle_PkgPublished_Change}
                                />
                                <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked">{pkgPublished ? 'On' : 'Off'}</label>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isNewPackage ? ( <Button onClick={handle_Internet_AddPackage}    variant="primary">   Add Package </Button>
                    ) : (             <Button onClick={handle_Internet_UpdatePackage}      variant="primary">   Update      </Button>
                    )}

                    <Button variant="secondary" onClick={() => setShowModal(false)}>    Close   </Button>
                </Modal.Footer>
            </Modal>


            <DeleteModal
                question            ={`Will delete Internet Package '${selectedItem?.serviceCode}'?`}
                heading             ={"Package Deletion"}
                deleteModal         ={deleteModal}
                setDeleteModal      ={setDeleteModal}
                handleDeleteItem    ={handle_Internet_DeletePackage}
                id                  ={selectedItem?._id}
            />
        </div>
    );
};

export default ManageInternet;
