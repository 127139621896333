// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend) // Load translation using http backend
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    lng: "en", // default language
    fallbackLng: "en", // Fallback language
    ns: [
        'subsRemittance',       'subsTransactions',       'subsPartsOrders',        'subsInternet',           'subsPartsQuotes',       
        'subsMenu',             'subsHeader',
        
        'settingsBilling',      'settingsReferrals',      'settingsSecurity',       'settingsContactInfo',    'settingsNotifications', 
        'settingsMenu',         'settingsDocuments', 

        'homeServicesInternet', 'homeServicesRemittance', 'homeServicesMarketing',  'homeServicesPartsAuto',  'homeFooterSection', 
        'homePartnershipSection',
        
        'manageUserDocuments',
        
        'homeTopBar',           'homeProfileDropDown',    'homeBannerMain',
        'homeSectionAbout',     'homeSectionPrivacy',     'homeSectionMission',     'homeSectionServices',    'homeSectionTestimonials', 
        'homeSectionContact',
        
        'authSignIn',           'authSignUp',             'authVerifyEmail',        'authForgotPassword',     'authNewPassword', 
        'authResetPassword', 
        
        '404Page'], // Namespaces you're using
    defaultNS: 'subsRemittance', // Default namespace if not specified in the component
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' // Path to your translation files
    },
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
