/* REAL - MY SUBSCRIPTIONS - INTERNET */
/* File: /src/pages/Subscriptions/Internet/index.jsx */
import { useTranslation 
        }                                                   from 'react-i18next';
import React from 'react';  // Import React to use JSX
import { useNavigate }                                      from "react-router-dom";
import { 
         TabContentContainer, 
         TabSubTitle, 
         TableContainer 
       }                                                    from "../../Tabs/tabContentStyle";
import { staggerVariants, 
         titleVariants }                                    from "../../../utils/FmVariants";
import TableComponent                                       from "../../../components/UI/TableComponent";
import { useContext, 
         useState, 
         useEffect 
       }                                                    from "react";
import { Button,
         Tooltip, OverlayTrigger 
       }                                                    from "react-bootstrap";
import { UserContext }                                      from "../../../App";
import { useGetSubscriptionsMutation }                      from "../../../services/userUserStoreApi";
import { useInternetListPackagesQuery }                     from "../../../services/svcInternetApi";
import SvcInternetModal                                     from "../../Modal/SvcInternetModal";

const SubsInternet = () => {
    const { t } = useTranslation('subsInternet');
    const Source_Name           = "MY SUBSCRIPTIONS - INTERNET";
    const navigator                                     = useNavigate();
    const [showModal,           setShowModal]           = useState(false);
    const { user }                                      = useContext(UserContext);
    const { data: Internets_List,  
            isLoading: isLoadingInternet }              = useInternetListPackagesQuery({ source: Source_Name });
    const [UserStore_GetSubscriptions]                  = useGetSubscriptionsMutation();
    const [UserSubscriptions,   setUserSubscriptions]   = useState(null);

    useEffect(() => {
        if (user?._id) {
            const data = {
                userIds: [user._id],  // List of user IDs
              };
              
            UserStore_GetSubscriptions({ data, source: Source_Name })
                .then(res => {
                    if (res?.data?.status) {
                        setUserSubscriptions(res.data);
                    } else {
                        console.error(res?.data?.message);
                    }
                })
                .catch(err => console.error("API call error:", err));
        }
    }, [user, UserStore_GetSubscriptions]);

    if (isLoadingInternet) {
        return <p>{t('descriptions.loading')}</p>;
    }

    const userPackages = UserSubscriptions?.data?.[0]?.subscriptions?.internet?.length > 0 
        ? UserSubscriptions.data[0].subscriptions.internet 
        : null;

    console.log("user_Subscriptions", UserSubscriptions);
    console.log("userPackages", userPackages);

    const packageDetails = userPackages?.map((internetPackage) => {
        return Internets_List?.data?.find(pkg => pkg.serviceCode === internetPackage.serviceCode);
    }).filter(Boolean);

    if (!packageDetails?.length) {
        return <p>{t('descriptions.no_package_available')}</p>;
    }
    
    console.log("packageDetails", packageDetails);

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {userPackages && packageDetails.length > 0 ? (
                        <TabSubTitle>{t('titles.internet_service_details')}</TabSubTitle>
                    ) : (
                        <TabSubTitle>{t('titles.join_happy_members')} <br /> <br /> {t('titles.browsing_soon')}</TabSubTitle>
                    )}
                    {userPackages && packageDetails.length > 0 && (
                        <OverlayTrigger 
                            placement="top" 
                            overlay={
                                <Tooltip id="tooltip-save">
                                    {user?._id ? 
                                        (UserSubscriptions?.data?.some(user => Array.isArray(user.subscriptions?.internet) && user.subscriptions.internet.length > 0) 
                                            ? t('descriptions.view_my_package') 
                                            : t('descriptions.request_package'))  
                                        : t('descriptions.sign_in_to_request')
                                    }
                                </Tooltip>
                            }
                        >
                            <Button variant="primary" className="ms-2" onClick={() => navigator("/settings?tab=billing")}>
                                {t('buttons.my_billing')}
                            </Button>
                        </OverlayTrigger>
                        
                    )}
                </div>
                
                {userPackages && packageDetails.length > 0 ? (
                    <>
                        {userPackages.map((internetPackage, index) => {
                            const details = packageDetails[index];
                            return (
                                <TableContainer key={internetPackage.serviceCode} style={{ marginBottom: '20px' }}>
                                    <TableComponent variants={titleVariants} isDataTable={+false}>
                                        <tbody>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.internet_provider')}</strong></td>   
                                            <td>{internetPackage.providerName}</td>                   
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.account_number')}</strong></td>      
                                            <td>{internetPackage.providerAccNb}</td>                  
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.account_status')}</strong></td>      
                                            <td>{internetPackage.serviceStatus}</td>                  
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.activated_since')}</strong></td>     
                                            <td>{new Date(internetPackage.firstActivation).toLocaleDateString()}</td>  
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.price')}</strong></td>               
                                            <td>{details.price} $</td>                                
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.type')}</strong></td>                
                                            <td>Unlimited</td>                                        
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.download_speed')}</strong></td>      
                                            <td>{details.downloadSpeed}</td>                          
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.upload_speed')}</strong></td>        
                                            <td>{details.uploadSpeed}</td>                            
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.extra_info')}</strong></td>          
                                            <td>{details.extraInfo.join(', ')}</td>                   
                                        </tr>
                                        <tr>    
                                            <td><strong style={{ color: 'blue' }}>{t('titles.promo_code')}</strong></td>          
                                            <td>{internetPackage.promoCode || 'None'}</td>            
                                        </tr>


                                        </tbody>
                                    </TableComponent>
                                </TableContainer>
                            );
                        })}
                    </>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <OverlayTrigger 
                            placement="top" 
                            overlay={
                                <Tooltip id="tooltip-save">
                                    {t('tooltips.checkout_amazing_packages')}
                                </Tooltip>
                            }
                        >
                            <Button variant="primary" className="ms-2" onClick={handleShowModal}>
                                {t('buttons.request_internet_package')}
                            </Button>
                        </OverlayTrigger>
                    </div>
                )}
            </TabContentContainer>
            
            <SvcInternetModal 
                showModal={showModal} 
                handleCloseModal={handleCloseModal} 
                user={user} 
                data={Internets_List} 
            />

        </>
  );
};

export default SubsInternet;
