/* File: /src/services/orderTransactionApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const orderTransactionApi = createApi({
    reducerPath: "orderTransactionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/transactions",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["transactions"],
    endpoints: builder => ({
        getAllTransactions:      builder.query   ({ query: ({ source }) =>       ({ url: "/",                  method: "GET", params: { source }, }),                       providesTags: ["transactions"] }),
        // getTransactionsByUser:   builder.query   ({ query: id =>       ({ url: `/user/${id}`,        method: "GET" }),                       providesTags: ["transactions"] }),
        getTransactionsByUser:   builder.query   ({ query: ({ id, source }) => ({ url: `/user/${id}`, method: "GET", params: { source } }), providesTags: ["transactions"] }),


        createTransaction:       builder.mutation({ query: data =>     ({ url: "/",                  method: "POST", body: data }),          invalidatesTags: ["transactions"] }),
        getNewReqNb:             builder.mutation({ query: ({id, source }) =>  ({ url: "/getNewReqNb",  method: "POST", body: {id, source} }),          invalidatesTags: ["transactions"] }),
        
        updateTransaction:       builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,     method: "PATCH", body: payload.data }), invalidatesTags: ["transactions"] }),
        deleteTransaction:       builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,     method: "DELETE" }),                    invalidatesTags: ["transactions"] }),

        healthCheck:             builder.query   ({ query: ({ source }) =>       ({ url: `/health`,            method: "GET", params: { source }, }) }),

    })
});

export const { 
    useHealthCheckQuery,
    useGetAllTransactionsQuery, 
    useGetTransactionsByUserQuery, 
    useCreateTransactionMutation,
    useUpdateTransactionMutation,
    useDeleteTransactionMutation,
    useGetNewReqNbMutation,
} = orderTransactionApi;
