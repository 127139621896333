/* REAL - AUTH - VERFIY ACCOUNT */
/* File: /src/pages/Auth/VerifyEmail/VerifyEmail.jsx */
import { 
    useTranslation 
    }                       from 'react-i18next';
import axios                                from "axios";
import React, 
{ useState }                                from "react";
import {
        // ResetPassword,
        // FormButton,
        // FormControlField,
        // FormLabelText,
        InnerContent,
        PageTitle,
        ResetPasswordPage,
        // ResetPasswordForm,
        TitleContainer,
        // CancelButtonPage,
        CancelButton,
        FormControlField,
        FormLabelText,
        ResetPasswordForm
        }                                   from "../ForgotPassword/forgotPasswordStyle";
import { 
        Images, 
        SVGImages 
        }                                   from "../../../config/images";
import { 
        staggerVariants, 
        tileVariants, 
        titleVariants 
        }                                   from "../../../utils/FmVariants";
import { 
        Col, 
        Container, 
        Form, 
        Image, 
        Row 
        }                                   from "react-bootstrap";
import { 
        useNavigate, 
    //     useParams,
        useSearchParams, 
        }                                   from "react-router-dom";
import { 
        useReSendEmailVerifyMutation, 
        useVerifyEmailMutation 
        }                                   from "../../../services/userUserApi";
import {
        useUpdateReferralStatusMutation,
        }                                   from "../../../services/userUserStoreApi";
import { 
         toast 
        }                                   from "react-toastify";

const VerifyEmail = () => {
    const { t } = useTranslation('authVerifyEmail');
    const Source_Name           = "SIGNUP - VERIFY ACCOUNT";
    const [code,        setCode]        = useState("");
    const [error,       setError]       = useState("");
    const [resetBtn,    setResetBtn]    = useState(false);
    const [show,        setShow]        = useState(false);
    // const { email }                     = useParams();
    const [searchParams]                = useSearchParams();
    const referrerId                    = searchParams.get("referrerId");
    const refService                    = searchParams.get("refService");
    const refStatus                     = searchParams.get("refStatus");
    const refEmail                      = searchParams.get("refEmail");
    const email                         = searchParams.get("email");
    const cleanedEmail                  = email.replace(/\s/g, '+');

    const navigate                      = useNavigate();
    const [verifyEmail]                 = useVerifyEmailMutation();
    const [ReSendEmailVerify]           = useReSendEmailVerifyMutation();
    const [UpdateReferralStatus]        = useUpdateReferralStatusMutation();
    const STG_TokenName                 = "token"; 
    

    const handleVerifyCode = async () => {
        if (!code) {
            setError(t('errors.enter_verification_code'));
        } else {
            console.log("code", code);
            let IpGeoLocation;
            const ipgeolocOptions = {
                method:  'GET',
                url:     'https://ip-geo-location.p.rapidapi.com/ip/check',
                params:  {   format: 'json', language: 'en'  },
                headers: {
                            'x-rapidapi-key': '3183ae9d2bmshd63837079167294p121a90jsn3387d784707e',
                            'x-rapidapi-host': 'ip-geo-location.p.rapidapi.com'
                }
            };
            try {   IpGeoLocation = await axios.request(ipgeolocOptions);   } 
            catch (error) {   console.error(error);   }
        
            
            const location = `${IpGeoLocation.data.city.name}, ${IpGeoLocation.data.area.name}`;
            
            const data = {
                email: cleanedEmail,
                code: code,
                location: location,
            };
            verifyEmail({ data, source: Source_Name }).then(res => {   
                console.log('Verified email', res);
                console.log("refEmail===", refEmail);
                if (res?.data?.status) {
                    setShow(true);
                    setError("");
                    setResetBtn(false);
                    const dataReferral = {
                        refUserId: referrerId,
                        refEmail: refEmail,
                        refService: refService,
                        refStatus: refStatus,
                    };
                    UpdateReferralStatus({ data: dataReferral, source: Source_Name }).then(res => {
                    });

                    localStorage.setItem(STG_TokenName, res.data.token);
                    // setUser(res.data.userData);
                    console.log("... Authenticator Code verified.");        
                    toast.success(`Welcome home ${res.data.userData.firstName}` );
                    const targetPage = (res.data.userData.role === "user") ? "/" : "/dashboard";
                    console.log("... Navigating to default page for user");  
                    navigate(targetPage);

                } else {
                    if (res?.data?.message?.toLowerCase().includes("expired")) {
                        setResetBtn(true);
                        setError(t('messages.verification_code_expired'));
                        setCode("");
                    } else {
                        setError(res?.data?.message);
                    }
                }
            });
        }
    };

    const handleResetCode = () => {
        ReSendEmailVerify({ email: cleanedEmail, source: Source_Name }).then(res => {
            if (res?.data?.status) {
                setShow(false);
                setResetBtn(false);
            } else {
                setError(res?.data?.message);
            }
        });
    };


    console.log(error, navigate, handleResetCode, show);
    return (
        <ResetPasswordPage id="forgotPassword">
            <InnerContent variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <Container>


                    {show ? (
                        <>
                            <Row>
                                <Col md={12} className="text-center mb-3">
                                    <Image src={Images.ata2goLogo} width={130} />
                                </Col>
                                <Col md={12}>
                                    <TitleContainer>
                                        <PageTitle>{t('titles.account_verified')}</PageTitle>
                                        <button className="signInBtn" onClick={() => navigate("/signin")}>
                                            <span>{t('buttons.go_to_signin')}</span>
                                        </button>
                                    </TitleContainer>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col md={12} className="text-center mb-3">
                                    <Image src={Images.ata2goLogo} width={130} />
                                </Col>
                                <Col md={12}>
                                    <TitleContainer>
                                        <PageTitle>{t('titles.verify_account')}</PageTitle>
                                        <p>{t('messages.verification_sent_to')}</p>
                                        <p>{cleanedEmail}</p>
                                    </TitleContainer>
                                </Col>
                            </Row>
                            {/* form */}
                            <ResetPasswordForm>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                            <FormLabelText variants={tileVariants}>{t('form.enter_verification_code')}</FormLabelText>
                                            <FormControlField
                                                variants={tileVariants}
                                                type="text"
                                                id="code"
                                                placeholder={t('form.enter_verification_code')}
                                                value={code}
                                                onChange={e => setCode(e.target.value)}
                                                disabled={resetBtn ? true : false}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    {error && <p className="error-message">{error}</p>}

                                    {resetBtn ? (
                                        <Col md={12} className="text-end">
                                            <button type="button" className="signInBtn" onClick={handleResetCode}>
                                                <span>{t('buttons.resend_code')}</span>
                                            </button>
                                        </Col>
                                    ) : (
                                        <Col md={12} className="text-end">
                                            <button type="button" className="signInBtn" onClick={handleVerifyCode}>
                                                <span>{t('buttons.verify')}</span>
                                            </button>
                                        </Col>
                                    )}

                                </Row>
                            </ResetPasswordForm>
                            <CancelButton to={"/signin"} variants={titleVariants}>
                                <SVGImages.ReturnIcon />
                                <span>{t('buttons.cancel')}</span>
                            </CancelButton>
                        </>
                    )}
                </Container>
            </InnerContent>
        </ResetPasswordPage>
    );
};

export default VerifyEmail;
