/* File: /src/services/userUserApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const userUserApi = createApi({
    reducerPath: "userUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/auth",

        prepareHeaders: (headers, { endpoint }) => {
            const token = getTokenFromLocalStorage();
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
                if (endpoint === "autoLogin") {
                    headers.set("AuthorizationSource", "Local");
                }
            }
            return headers;
        }
        
    }),
    tagTypes: ["User"],
    endpoints: builder => ({ 
        autoLogin:            builder.query({ query: ({source}) => ({ url: `/autoLogin`, method: "GET", params: { source } }), providesTags: ["User"] }),

        getAllUsers:          builder.query({    query: ({ source }) =>       ({ url: "/getAllUsers",              method: "GET", params: { source }, }),                       providesTags: ["User"] }),
        getLastLogin:         builder.query({    query: ({ id, source }) =>   ({ url: `/getLastLogin/${id}`,       method: "GET", params: { source }, }), providesTags: ["User"] }),

        getUserById:          builder.query({    query: id =>       ({ url: `/${id}`,                    method: "GET" }),                       providesTags: ["User"] }),
       // new getUserById: builder.query({ query: ({ id, source }) => ({ url: `/${id}`, method: "GET", params: { source } }), providesTags: ["User"] }),
            
        ReSendEmailVerify:    builder.mutation({ query: data =>     ({ url: `/ReSendEmailVerify`,        method: "PATCH", body: data }),         invalidatesTags: ["User"] }),
        ReSendPassVerify:     builder.mutation({ query: data =>     ({ url: `/ReSendPassVerify`,         method: "PATCH", body: data }),         invalidatesTags: ["User"] }),
        updatePassword:       builder.mutation({ query: payload =>  ({ url: `/updatePassword/${payload.id}`,  method: "PATCH", body: payload.data }), invalidatesTags: ["User"] }),
        updateUsers:          builder.mutation({ query: payload =>  ({ url: `/updateUsers`,              method: "PATCH", body: payload }),      invalidatesTags: ["User"] }),

        updateUserInfo:       builder.mutation({ query: payload =>  ({ url: `/updateUserInfo`,           method: "PATCH", body: payload }),      invalidatesTags: ["User"] }),
        twoFaByAdmin:         builder.mutation({ query: payload =>  ({ url: `/twoFaByAdmin`,             method: "PATCH", body: payload }),      invalidatesTags: ["User"] }),

        deleteUser:           builder.mutation({ query: id =>       ({ url: `/${id}`,                    method: "DELETE" }),                    invalidatesTags: ["User"] }),
        usersDelete:          builder.mutation({ query: payload =>  ({ url: `/usersDelete`,              method: "DELETE", body: payload }),     invalidatesTags: ["User"] }),
        
        deleteUsers:          builder.mutation({ query: payload =>  ({ url: `/deleteUsers`,                             method: "POST", body: payload }),       invalidatesTags: ["User"] }),
        sendCode:             builder.mutation({ query: ({ data, source }) =>     ({ url: `/sendVerificationCode`,      method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        sendSupportMail:      builder.mutation({ query: payload =>  ({ url: `/sendSupportMail`,                         method: "POST", body: payload }),          invalidatesTags: ["User"] }),
        sendTwoFactor:        builder.mutation({ query: ({ data, source }) =>     ({ url: `/sendTwoFactor`,             method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        sendTwoFaPhoneCode:   builder.mutation({ query: ({ data, source }) =>     ({ url: `/sendTwoFaPhoneCode`,        method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        signin:               builder.mutation({ query: ({ data, source }) =>     ({ url: `/signIn`,                    method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"], }),
        signup:               builder.mutation({ query: ({ data, source }) =>     ({ url: "/signUp",                    method: "POST", body: { ...data, source } }),           invalidatesTags: ["User"] }),
        deactivateTwoFa:      builder.mutation({ query: ({ data, source }) =>     ({ url: "/deactivateTwoFa",           method: "POST", body: { ...data, source } }),           invalidatesTags: ["User"] }),
        

        create:               builder.mutation({ query: ({ data, source }) =>     ({ url: "/create",                    method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        twoFaAuthGenerate:    builder.mutation({ query: ({ data, source }) =>     ({ url: "/twoFaAuthGenerate",         method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        twoFaAuthVerify:      builder.mutation({ query: ({ data, source }) =>     ({ url: "/twoFaAuthVerify",           method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        verifyCode:           builder.mutation({ query: ({ data, source }) =>     ({ url: `/verifyCode`,                method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        verifyEmail:          builder.mutation({ query: ({ data, source }) =>     ({ url: `/verifyEmail`,               method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        verifyTwoFaCode:      builder.mutation({ query: ({ data, source }) =>     ({ url: "/verifyTwoFaCode",           method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        verifyTwoFaPhoneCode: builder.mutation({ query: ({ data, source }) =>     ({ url: "/verifyTwoFaPhoneCode",      method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        verifyTwoFaMethod:    builder.mutation({ query: ({ data, source }) =>     ({ url: `/verifyTwoFaMethod`,         method: "POST", body: { ...data, source }, }),          invalidatesTags: ["User"] }),
        
        getUserInfo:          builder.mutation({ query: ({ id, source }) => ({ url: "/getUserInfo", method: "POST", body: { id, source } }) }),
        updateMemberID:       builder.mutation({ query: ({ id, source }) => ({ url: "/updateMemberID", method: "POST", body: { id, source } }) }),



        healthCheck:            builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET" })        }),
    })
});

export const {
    useHealthCheckQuery,
    useAutoLoginQuery,
    useGetAllUsersQuery,
    useGetLastLoginQuery,
    useGetUserByIdQuery,
    useGetUserInfoMutation,
    useUpdateMemberIDMutation,

    useReSendEmailVerifyMutation,
    useReSendPassVerifyMutation,
    useTwoFaByAdminMutation,
    useUpdatePasswordMutation,
    useUpdateUserMutation,
    useUpdateUsersMutation,
    useUpdateUserInfoMutation,
    


    useDeleteUserMutation,
    useUsersDeleteMutation,
    
    useDeleteUsersMutation,
    useSendCodeMutation,
    useSendSupportMailMutation,
    useSendTwoFactorMutation,
    useSendTwoFaPhoneCodeMutation,
    useSigninMutation,
    useSignupMutation,
    useDeactivateTwoFaMutation,
    useCreateMutation,
    useTwoFaAuthGenerateMutation,
    useTwoFaAuthVerifyMutation,
    useVerifyCodeMutation,
    useVerifyEmailMutation,
    useVerifyTwoFaCodeMutation,
    useVerifyTwoFaPhoneCodeMutation,
    useVerifyTwoFaMethodMutation
} = userUserApi;


/*
Mutation/Query	File Name


*/