/* File: /src/services/api.js */

export const baseUrl = window.location.origin.includes("localhost")
  ? "http://localhost:5000/api/v1"
  : "https://ata2go-backend.vercel.app/api/v1";

console.error("baseUrl=", baseUrl);

// export  const baseUrl = "http://localhost:5000/api/v1";
// export const baseUrl = "https://ata2go-backend.vercel.app/api/v1"

/*

curl -X GET http://localhost:5000/api/v1/affiliate/health

curl -X GET http://localhost:5000/api/v1/billings/health

curl -X GET http://localhost:5000/api/v1/cars/health
curl -X GET http://localhost:5000/api/v1/carParts/health
curl -X GET http://localhost:5000/api/v1/carRequests/health
curl -X GET http://localhost:5000/api/v1/categories/health
curl -X GET http://localhost:5000/api/v1/courses/health
curl -X GET http://localhost:5000/api/v1/internets/health
curl -X GET http://localhost:5000/api/v1/notifications/health
curl -X GET http://localhost:5000/api/v1/rating/health
curl -X GET http://localhost:5000/api/v1/auth/health
curl -X GET http://localhost:5000/api/v1/userstore/health
curl -X GET http://localhost:5000/api/v1/videos/health
curl -X GET http://localhost:5000/api/v1/rating/health


*/


