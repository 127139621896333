/* REAL - MY SUBSCRIPTIONS - REMITTANCE  */
/* File: /src/pages/Subscriptions/Remittance/index.jsx */
import '../../../App.css'; 
import axios                                            from "axios";
import { useTranslation 
        }                                               from 'react-i18next';
import React, 
        { 
            useEffect, 
            useState,
           // useContext,
        }                                               from "react";
import { toast }                            from "react-toastify";

import Select                                           from 'react-select';
import { Button, 
         Form, 
         Modal,
         Tooltip, 
         OverlayTrigger, 
       }                                                from "react-bootstrap";
import { IoIosSave }                                    from "react-icons/io"; 
import { MdOutlineFilterAltOff }                        from "react-icons/md";
import { MdDelete }                                     from "react-icons/md";
import { useNavigate }                                  from "react-router-dom";
import { TabContentContainer }                          from "../../Tabs/tabContentStyle";

import { 
         useCreateTransactionMutation,
         useGetTransactionsByUserQuery,
         useGetNewReqNbMutation, 
        }                                               from "../../../services/orderTransactionApi";
import { useGetBeneficiaryQuery, 
         useSaveBeneficiaryMutation,
         useDeleteBeneficiaryMutation,
         useGetSubscriptionsMutation, 
       }                                                from "../../../services/userUserStoreApi";
import {  
        useRetrieveDocumentListByUserIdMutation, 
       }                                                from "../../../services/userDocumentApi";
import { staggerVariants }                              from "../../../utils/FmVariants";
import { SVGImages }                                    from "../../../config/images";


const SubsPartsOrders = ({ user }) => {
    const navigate                                              = useNavigate();
    const { t } = useTranslation('subsRemittance');

    const Source_Name           = "MY SUBSCRIPTIONS - REMITTANCE";

    /* Beneficiary Information: */
    const [selectedBeneficiary,         setSelectedBeneficiary]     = useState(null);
    const [firstName,                   setFirstName]               = useState("");
    const [lastName,                    setLastName]                = useState("");
    const [address,                     setAddress]                 = useState("");
    const [city,                        setCity]                    = useState("");
    const [country,                     setCountry]                 = useState("");
    const [phone,                       setPhone]                   = useState("");
    const [messageNote,                 setMessageNote]             = useState("");
    const [sendAmount,                  setSendAmount]              = useState("");
    const [receiveAmount,               setReceiveAmount]           = useState("");
    const [feesAmount,                  setFeesAmount]              = useState("");
    const [totalAmount,                 setTotalAmount]             = useState("");
    const [currency,                    setCurrency]                = useState("");
    const [savedBeneficiaryUsed,        setSavedBeneficiaryUsed]    = useState(false);
    const [showQuoteModal,              setShowQuoteModal]          = useState(false);
    const [availableCurrencies,         setAvailableCurrencies]     = useState([]);
    const [isIdVerified,                setIsIdVerified]            = useState(false);
    // eslint-disable-next-line
    const [furthestExpiry,              setFurthestExpiry]          = useState(null);
    const [requestNumber,               setRequestNumber]           = useState('');
    const [emailReceipt,                setEmailReceipt]            = useState(false);
    const [loadingSpin,                 setLoadingSpin]             = useState(false);
    const [serviceStatus,               setServiceStatus]           = useState("");

    // State variables for each document type
    const [driverLicenseStatus,         setDriverLicenseStatus]     = useState("");
    const [healthCardStatus,            setHealthCardStatus]        = useState("");
    const [passportStatus,              setPassportStatus]          = useState("");
    const [residencePermitStatus,       setResidencePermitStatus]   = useState("");
    const [nationalIdStatus,            setNationalIdStatus]        = useState("");

    // State variables for each document type's expiry date
    const [driverLicenseExpiry,         setDriverLicenseExpiry]     = useState(null);
    const [healthCardExpiry,            setHealthCardExpiry]        = useState(null);
    const [passportExpiry,              setPassportExpiry]          = useState(null);
    const [residencePermitExpiry,       setResidencePermitExpiry]   = useState(null);
    const [nationalIdExpiry,            setNationalIdExpiry]        = useState(null);




    // Map the countries to their respective currencies
    const countryCurrencyMap = {
        "Bahamas":              { flag: SVGImages.FlagBahamas,              currencies: ["USD"] },
        "Canada":               { flag: SVGImages.FlagCanada,               currencies: ["CAD"] },
        "Dominican Republic":   { flag: SVGImages.FlagDominicanRepublic,    currencies: ["DOP", "USD"] },
        "Haiti":                { flag: SVGImages.FlagHaiti,                currencies: ["HTG", "USD"] },
        "Turks and Caicos":     { flag: SVGImages.FlagTurksAndCaicos,       currencies: ["USD"] },
        "United States":        { flag: SVGImages.FlagUnitedStates,         currencies: ["USD"] },
    };

    // Create an array of options for the dropdown
    const countryOptions = Object.keys(countryCurrencyMap).map(country => ({
        value: country,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={countryCurrencyMap[country].flag} alt={`${country} flag`} style={{ width: '20px', marginRight: '10px' }} />
                {country}
            </div>
        )
    }));

    /* Data Handling: */
    const [retrieveDocumentListByUserId]        = useRetrieveDocumentListByUserIdMutation();
    const [createTransaction_OrderTransaction]  = useCreateTransactionMutation();
    const [getNewReqNb_OrderTransaction]        = useGetNewReqNbMutation();
    const [userStore_Save]                      = useSaveBeneficiaryMutation();
    const [UserStore_GetSubscriptions]          = useGetSubscriptionsMutation();
    const [deleteBeneficiary]                   = useDeleteBeneficiaryMutation();
    const { data: userBeneficiaryData }         = useGetBeneficiaryQuery(user?._id);
    const { data: transactionData }             = useGetTransactionsByUserQuery({ id: user?._id, source: Source_Name });

    const transactionCount                      = transactionData?.data?.[0]?.transactions?.length || 0;

    const [isAuthorized,                setIsAuthorized]            = useState(false);
    const [subscriptionData, setSubscriptionData]       = useState(null);
    

    useEffect(() => {
        if (user?._id) {
            const data = {
                userIds: [user._id],  // List of user IDs
              };
              
            UserStore_GetSubscriptions({ data, source: Source_Name }).then(response => {
                setSubscriptionData(response.data);
                console.log("response", response.data);
            });
        }
    }, [user, UserStore_GetSubscriptions]);

    /* Section 00.0 - createAuditAction   */
    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });

    /* Section 01.0 - handle_SaveBeneficiary   */
    const handle_SaveBeneficiary = async () => {
        if (!firstName || !lastName || !address || !city || !country || !phone ) {
            toast.error("RequireAll fields.");
            return;
        } 
        const data_Beneficiary = {
            firstName:      firstName,
            lastName:       lastName,
            address:        address,
            city:           city,
            country:        country,
            phone:          phone,
            currency:       currency,
            userId:         user?._id
        };
    
        const res_SaveBeneficiary = await userStore_Save(data_Beneficiary);
        if (res_SaveBeneficiary.data?.status) {
            toast.success(res_SaveBeneficiary?.data?.message);
        } else {
            toast.error(res_SaveBeneficiary?.data?.message);
        }
    };

    /* Section 02.0 - handle_UseBeneficiary  */
    const handle_UseBeneficiary = (theBeneficiary) => {
        if (theBeneficiary) {
            console.log("SELECTED ==> ", theBeneficiary);                         
            setFirstName(theBeneficiary.firstName);
            setLastName(theBeneficiary.lastName);
            setAddress(theBeneficiary.address);
            setCity(theBeneficiary.city || "");
            setCountry(theBeneficiary.country || "");
            setPhone(theBeneficiary.phone || "");
    
            // Update available currencies based on the selected country
            const selectedCountry = theBeneficiary.country || "";
            const currencies = countryCurrencyMap[selectedCountry]?.currencies || [];
            setAvailableCurrencies(currencies);
    
            // Set the currency to the one saved in the beneficiary, or default to the first available currency
            setCurrency(theBeneficiary.currency || currencies[0] || "");
    
            // Recalculate the receiveAmount if sendAmount is >= 10
            if (sendAmount >= 10 && theBeneficiary.currency) {
                fetchExchangeRate('CAD', theBeneficiary.currency, sendAmount).then(convertedAmount => {
                    if (convertedAmount) {
                        setReceiveAmount(convertedAmount.toFixed(2));  // Update with the converted amount
                    } else {
                        setReceiveAmount('');  // Clear the field if conversion fails
                    }
                });
            } else {
                setReceiveAmount('');  // Clear the field if sendAmount is less than 10 or no currency is selected
            }
    
            setSavedBeneficiaryUsed(true); // Set the state to true
        }
    };
    
    /* Section 03.0 - handle_ClearBeneficiary   */
    const handle_ClearBeneficiary = () => {
        setSelectedBeneficiary(null);
        setFirstName("");
        setLastName("");
        setAddress("");
        setCity("");
        setCountry("");
        setPhone("");
        setCurrency("");
        setSavedBeneficiaryUsed(false); // Reset the state
    };

    /* Section 04.0 - handle_DeleteBeneficiary  */
    const handle_DeleteBeneficiary = async () => {
        if (selectedBeneficiary) {
            const confirmDelete = window.confirm("Are you sure you want to delete this beneficiary?");
            if (confirmDelete) {
                try {
                    const response = await deleteBeneficiary(selectedBeneficiary._id).unwrap();
                    if (response.status) {
                        toast.success(response.message);
                        if (savedBeneficiaryUsed) { 
                            setSelectedBeneficiary(null);
                            setFirstName("");
                            setLastName("");
                            setAddress("");
                            setCity("");
                            setCountry("");
                            setPhone("");
                            setCurrency("");
                            setSavedBeneficiaryUsed(false); // Reset the state
                            // Optionally update the local state or refetch data
                            // You can use refetch or another query hook to update the beneficiary list
                        }
                    } else {
                        toast.error(response.message);
                    }
                } catch (error) {
                    toast.error("Error deleting beneficiary");
                    console.error("Error deleting beneficiary:", error);
                }
            }
        }
    };
    
    /* Section 05.0 - handle_CreateTransaction   */
    const handle_CreateTransaction = async () => {
        if (!firstName || !lastName || !address || !city || !country || !phone || !sendAmount || !currency) {
            toast.error("Please fill in all the fields.");
        } else {
            setLoadingSpin(true);  
            try {
                const result = await getNewReqNb_OrderTransaction({ id: user?._id, source: Source_Name }).unwrap();
                if (result?.data) { setRequestNumber(result?.data); }
                setShowQuoteModal(true);
            } catch (error) {
                toast.error("Failed to get request number. Please try again.");
            }
        }
    };

    /* Section 06.0 - useEffect - userBeneficiaryData   */
    useEffect(() => {
        console.log("Fetched user beneficiaries:", userBeneficiaryData);
    }, [userBeneficiaryData]);

    /* Section 07.0 - handle_PlaceOrder   */
    const handle_PlaceOrder = () => { 
        setLoadingSpin(true);  
        console.log("firstName==", firstName);
        createTransaction_OrderTransaction({
            userEmail: user?.email,
            userId: user?._id,
            transactionDetails: {
                firstName,
                lastName,
                address,
                city,
                country,
                phone,
                requestNumber,
                sendAmount,
                receiveAmount,
                feesAmount,
                currency,
                totalAmount,
                messageNote,
                timestamp: new Date()
            },
            auditActions: createAuditAction("User Requested Transaction")
        }).then(res => {
            console.log("Create transaction response:", res);
            if (res?.data?.status) {
                toast.success(res?.data?.message, {position: "top-center"} );
                setFirstName("");
                setLastName("");
                setAddress("");
                setCity("");
                setCountry("");
                setPhone("");
                setRequestNumber("");
                setSendAmount("");
                setFeesAmount("");
                setTotalAmount("");
                setCurrency("");
                setMessageNote("");
                setReceiveAmount("");
                setSelectedBeneficiary(null);
                setSavedBeneficiaryUsed(false);
                setShowQuoteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
            setLoadingSpin(false);

        }).catch(error => {
            setLoadingSpin(false);
            console.error("Error creating transaction:", error);
            toast.error("Failed to create transaction.");
        });
    };

    /* Section 08.0 - fetchExchangeRate   */
    const fetchExchangeRate = async (oriCurrency, destCurrency, amount) => {
        const options = {
            method: 'GET',
            url: 'https://currency-converter18.p.rapidapi.com/api/v1/convert',
            params: {
              from: `${oriCurrency}`,
              to: `${destCurrency}`,
              amount: `${amount}`
            },
            headers: {
              'x-rapidapi-key': '3183ae9d2bmshd63837079167294p121a90jsn3387d784707e',
              'x-rapidapi-host': 'currency-converter18.p.rapidapi.com'
            }
        };
          
        try {
            const response = await axios.request(options);
            return response.data.result.convertedAmount;
        } catch (error) {
            console.error(error);
        }
    };

    /* Section 09.0 - useEffect - user?._id, retrieveDocumentListByUserId   */
    /* Valid Values:  Processing, Rejected, Submitted, Verified,   */
    useEffect(() => {
        if (user?._id) {
            retrieveDocumentListByUserId(user?._id).then(res => {
                if (res?.data?.status && res?.data?.documentList) {
                    const documents = res.data.documentList;
                    documents.forEach(doc => {
                        switch(doc.docType) {
                            case 'driver-license':
                                setDriverLicenseStatus(doc.docStatus);
                                setDriverLicenseExpiry(doc.docExpiry ? new Date(doc.docExpiry) : null);
                                break;
                            case 'health-card':
                                setHealthCardStatus(doc.docStatus);
                                setHealthCardExpiry(doc.docExpiry ? new Date(doc.docExpiry) : null);
                                break;
                            case 'passport':
                                setPassportStatus(doc.docStatus);
                                setPassportExpiry(doc.docExpiry ? new Date(doc.docExpiry) : null);
                                break;
                            case 'residence-permit':
                                setResidencePermitStatus(doc.docStatus);
                                setResidencePermitExpiry(doc.docExpiry ? new Date(doc.docExpiry) : null);
                                break;
                            case 'national-id':
                                setNationalIdStatus(doc.docStatus);
                                setNationalIdExpiry(doc.docExpiry ? new Date(doc.docExpiry) : null);
                                break;
                            default:
                                break;
                        }
                    });

                    // Verify overall ID status
                    const isVerified = documents.some(doc => doc.docStatus === "Verified");
                    setIsIdVerified(isVerified);

                    // Calculate furthest document expiry for verified documents only
                    const verifiedDocs = documents.filter(doc => doc.docStatus === "Verified" && doc.docExpiry); // Filter verified documents with expiry
                    const expiryDates = verifiedDocs.map(doc => new Date(doc.docExpiry)); // Map to expiry dates
                    const maxExpiry = expiryDates.length > 0 ? new Date(Math.max(...expiryDates)) : null;
                    setFurthestExpiry(maxExpiry);
                } else {
                    toast.error("Failed to retrieve documentList.");
                }
            }).catch(err => {
                console.error("Error fetching documents:", err);
                toast.error("Error fetching documents.");
            });
        }
    }, [user?._id, retrieveDocumentListByUserId]);
    
    useEffect(() => {
        if (subscriptionData?.data?.some(user => 
            Array.isArray(user.subscriptions?.remittances) && user.subscriptions.remittances.length > 0)) 
        {
            const remittance = subscriptionData.data.find(user => 
                Array.isArray(user.subscriptions?.remittances) && user.subscriptions.remittances.length > 0
            ).subscriptions.remittances[0];
            setServiceStatus(remittance.serviceStatus);
            setIsAuthorized(remittance.serviceStatus === 'active');
        } else {
            setServiceStatus("NonExistent");
        }
    }, [subscriptionData]);
    


    
    return (
        <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, sendAmount: 0.1 }}>

            <div className="video-input-box">
                <div className="d-flex justify-content-between align-items-center">
                    {/*  ===============================Beneficiary Information:====================================  */}
                    <div className="mt-4">
                        <h4 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('request_title')}</h4> 
                    </div>
                    <div className="d-flex">
                        {/* BUTTON - DOCUMENTS */}
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('documents_button')}</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/settings?tab=documents")}>
                                {t('documents_button')}
                            </Button>
                        </OverlayTrigger>

                        {/* BUTTON - BILLING */}
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('billing_button')}</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/settings?tab=billing")}> 
                                {t('billing_button')}
                            </Button>
                        </OverlayTrigger>

                        {/* BUTTON - SEND REQUEST */}
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('send_request_button')}</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={handle_CreateTransaction}>
                                {t('send_request_button')}
                            </Button>
                        </OverlayTrigger>

                        {/* BUTTON - TRANSACTION */}
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('transactions_button_capsule')}</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/subscriptions?tab=transactions")}>
                                {t('transactions_button', { count: transactionCount })}
                            </Button>
                        </OverlayTrigger>
                    </div>

                </div>
                <hr className="mt-1" />

                <div className="row">
                    <br /> 
                    {/*  ===============================Your Identification & Access====================================  */}
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('identification_access')}</h5>
                    {!isIdVerified ? (
                        <p style={{ color: 'red', marginTop: '10px', paddingLeft: '60px' }}>
                            {t('update_id_prompt')}
                            <span 
                                onClick={() => navigate("/settings?tab=documents")} 
                                style={{ fontStyle: 'italic', color: 'blue', cursor: 'pointer', textDecoration: 'underline', marginLeft: '5px' }}
                            >
                                {t('documents_link')}
                            </span>
                        </p>
                        
                    ) : (
                        null
                    )}

                    {/* Conditionally Display Each Document Type */}
                    <div style={{ marginTop: '10px', paddingLeft: '60px' }}>
                        {driverLicenseStatus && (
                            <p style={{ color: driverLicenseStatus === 'Verified' ? 'green' : driverLicenseStatus === 'Rejected' ? 'red' : 'orange' }}>
                                {t('driver_license_status')} {driverLicenseStatus}
                                {driverLicenseExpiry && ` - Expires on ${driverLicenseExpiry.toISOString().split('T')[0]}`}
                            </p>
                        )}
                        {healthCardStatus && (
                            <p style={{ color: healthCardStatus === 'Verified' ? 'green' : healthCardStatus === 'Rejected' ? 'red' : 'orange' }}>
                                {t('health_Card_status')} {healthCardStatus}
                                {healthCardExpiry && ` - Expires on ${healthCardExpiry.toISOString().split('T')[0]}`}
                            </p>
                        )}
                        {passportStatus && (
                            <p style={{ color: passportStatus === 'Verified' ? 'green' : passportStatus === 'Rejected' ? 'red' : 'orange' }}>
                                {t('passport_status')} {passportStatus}
                                {passportExpiry && ` - Expires on ${passportExpiry.toISOString().split('T')[0]}`}
                            </p>
                        )}
                        {residencePermitStatus && (
                            <p style={{ color: residencePermitStatus === 'Verified' ? 'green' : residencePermitStatus === 'Rejected' ? 'red' : 'orange' }}>
                                {t('residence_permit_status')} {residencePermitStatus}
                                {residencePermitExpiry && ` - Expires on ${residencePermitExpiry.toISOString().split('T')[0]}`}
                            </p>
                        )}
                        {nationalIdStatus && (
                            <p style={{ color: nationalIdStatus === 'Verified' ? 'green' : nationalIdStatus === 'Rejected' ? 'red' : 'orange' }}>
                                {t('national_id_status')} {nationalIdStatus}
                                {nationalIdExpiry && ` - Expires on ${nationalIdExpiry.toISOString().split('T')[0]}`}
                            </p>
                        )}
                    </div>



                    {isAuthorized ? (
                        <p style={{ color: 'green',  paddingLeft: '60px' }}>{t('access_status_active')}</p>

                    ) : (
                        <p style={{ color: 'red',  paddingLeft: '60px' }}> Access status is: {serviceStatus}   </p>
                    )}

                    <br /> 
                    <br /> 
                    <br /> 
                    {/*  ===============================Transfer company:====================================  */}
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('transfer_company')}</h5> 
                    <p style={{ color: 'green', marginTop: '5px', paddingLeft: '60px' }}>CAM Service de Courriers pour les Caraïbes LTEE</p>
                    <br /> 
                    <br /> 
                    <br /> 
                    {/*  ===============================Your saved beneficiaries:====================================  */}
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('saved_beneficiaries', { count: userBeneficiaryData?.data?.length || 0 })}</h5>
                    <br /> 
                    <div className="d-flex justify-content-between align-items-center">
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Save My Beneficiary</Tooltip>}       >
                            <Button variant="primary" className="ms-2"              onClick={handle_SaveBeneficiary}>        <IoIosSave />             </Button>
                        </OverlayTrigger>

                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-reset">Clear Beneficiary</Tooltip>}        >
                            <Button variant="secondary" className="ms-2"            onClick={handle_ClearBeneficiary}>     <MdOutlineFilterAltOff />   </Button>
                        </OverlayTrigger>

                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-reset">Delete Beneficiary</Tooltip>}        >
                            <Button variant="danger" className="action-icon delete" onClick={handle_DeleteBeneficiary}>     <MdDelete />               </Button>
                        </OverlayTrigger>

                        {loadingSpin ? (
                            <div>Loading...</div>
                        ) : null }





                        {/*  SELECT BENEFICIARY  */}
                        <Form.Select 
                            className="ms-2" 
                            value={selectedBeneficiary?._id || ""} 
                            onChange={(e) => {
                                const selectedId = e.target.value;
                                
                                if (selectedId) {
                                    const beneficiary = userBeneficiaryData?.data?.find(b => b._id === selectedId);
                                    setSelectedBeneficiary(beneficiary);
                                    handle_UseBeneficiary(beneficiary); // Automatically fills fields with the selected beneficiary
                                } else {
                                    handle_ClearBeneficiary(); // Automatically clears fields when the default option is selected
                                }
                            }} 
                            aria-label="Select Beneficiary" 
                            aria-describedby="select-saved-Beneficiary"
                        >
                            <option value="">Select Beneficiary</option>
                            {userBeneficiaryData?.data?.map(beneficiary => (
                                <option key={beneficiary._id} value={beneficiary._id}>
                                    {`${beneficiary.firstName} ${beneficiary.lastName} - ${beneficiary.address}, ${beneficiary.city}, ${beneficiary.country} - ${beneficiary.phone}`}
                                </option>
                            ))}
                        </Form.Select>
                    </div>

                    <div className="mt-4"></div> {/* This adds space between sections */} 

                    <br /> 
                    {/*  ===============================Request details:====================================  */}
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('receiver_details')}</h5>
                    <div className="mt-2"></div> {/* This adds space between sections */} 
                    {/*  FIRST NAME  */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        <Form.Label htmlFor="firstName">{t('first_name_label')}</Form.Label>
                        <Form.Control 
                            id="firstName" type="text" value={firstName} onChange={e => setFirstName(e.target.value)} aria-describedby="firstName"
                            disabled={!isIdVerified || !isAuthorized}
                            style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }}
                        />
                    </div>

                    {/*  LAST NAME  */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        <Form.Label htmlFor="lastName">{t('last_name_label')}</Form.Label>
                        <Form.Control 
                            id="lastName" type="text" value={lastName} onChange={e => setLastName(e.target.value)} aria-describedby="lastName"
                            disabled={!isIdVerified || !isAuthorized}
                            style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }} 
                        />
                    </div>
            
                            
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        {/*  COUNTRY  */}
                        <Form.Group controlId="country" className="mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label>{t('destination_country_label')}</Form.Label>
                            <Select
                                options={countryOptions}
                                onChange={async (selectedOption) => {
                                    const selectedCountry = selectedOption.value;
                                    setCountry(selectedCountry);
                                    const currencies = countryCurrencyMap[selectedCountry].currencies || [];
                                    setAvailableCurrencies(currencies);
                        
                                    // Automatically set the single currency or reset if multiple options
                                    const selectedCurrency = currencies.length === 1 ? currencies[0] : "";
                                    setCurrency(selectedCurrency);
                        
                                    // Adjust 'Receives' value if sendAmount is >= 10
                                    if (selectedCurrency && sendAmount >= 10) {
                                        const convertedAmount = await fetchExchangeRate('CAD', selectedCurrency, sendAmount);
                                        if (convertedAmount) {
                                            setReceiveAmount(convertedAmount.toFixed(2));
                                        } else {
                                            setReceiveAmount(''); 
                                        }
                                    } else {
                                        setReceiveAmount('');  
                                    }
                        
                                    // Calculate total as sendAmount + feesAmount
                                    const total = (parseFloat(sendAmount) + parseFloat(feesAmount)).toFixed(2);
                                    setTotalAmount(isNaN(total) ? '---' : total);
                                }}
                                value={countryOptions.find(option => option.value === country)}
                                isdisabled={!isIdVerified || !isAuthorized} // Disable Select if ID is not verified
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        backgroundColor: !isIdVerified ? '#e9ecef' : '', // Grey out if disabled
                                        cursor: !isIdVerified ? 'not-allowed' : 'pointer', // Change cursor if disabled
                                    })
                                }}
                            />
                        </Form.Group>


                        {/*  CURRENCY  */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="currency">{t('currency')}</Form.Label>
                            <Form.Select 
                                id="currency" 
                                className="mb-3 col-12 col-md-6 col-lg-6" 
                                value={currency} 
                                onChange={async e => {
                                    const selectedCurrency = e.target.value;
                                    setCurrency(selectedCurrency);

                                    // Recalculate the receiveAmount if sendAmount is >= 10
                                    if (sendAmount >= 10) {
                                        const convertedAmount = await fetchExchangeRate('CAD', selectedCurrency, sendAmount);
                                        if (convertedAmount) {
                                            setReceiveAmount(convertedAmount.toFixed(2));  // Update with the converted amount
                                        } else {
                                            setReceiveAmount('');  // Clear the field if conversion fails
                                        }
                                    } else {
                                        setReceiveAmount('');  // Clear the field if sendAmount is less than 10
                                    }

                                    // Calculate total as sendAmount + feesAmount
                                    const total = (parseFloat(sendAmount) + parseFloat(feesAmount)).toFixed(2);
                                    setTotalAmount(total);  // Update the totalAmount state
                                }} 
                                aria-describedby="currency" 
                                disabled={!country || availableCurrencies.length === 1 || !isIdVerified}  // Disable if conditions aren't met or ID not verified
                                style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : 'pointer' }}  // Grey out and adjust cursor when disabled
                            >
                                {availableCurrencies.length === 1 ? (
                                    <option key={availableCurrencies[0]} value={availableCurrencies[0]}>
                                        {availableCurrencies[0]}
                                    </option>
                                ) : (
                                    <>
                                        <option value="">Select</option>
                                        {availableCurrencies.map(currency => (
                                            <option key={currency} value={currency}>{currency}</option>
                                        ))}
                                    </>
                                )}
                            </Form.Select>

                        </div>

                    </div>


                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        {/*  ADDRESS  */}
                        <div className="input-wrapper mb-3 ">
                            <Form.Label htmlFor="address">{t('address_label')}</Form.Label>
                            <Form.Control id="address" type="text" value={address} onChange={e => setAddress(e.target.value)} aria-describedby="address"
                            disabled={!isIdVerified || !isAuthorized}
                            style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }}
                        />
                        </div>

                        {/*  CITY  */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="city">{t('city_label')}</Form.Label>
                            <Form.Control id="city" type="text" value={city} onChange={e => setCity(e.target.value)} aria-describedby="city"
                            disabled={!isIdVerified || !isAuthorized}
                            style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }}
                        />
                        </div>

                    </div>

                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        {/*  PHONE  */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="phone">{t('phone_label')}</Form.Label>
                            <Form.Control id="phone" type="text" value={phone} 
                                onChange={e => {
                                    let value = e.target.value;

                                    // Allow only numbers, periods, commas, plus signs, and hyphens
                                    value = value.replace(/[^\d.,+-]/g, '');

                                    // Replace commas with periods to standardize the decimal format
                                    value = value.replace(/,/g, '.');

                                    setPhone(value);
                                }}  
                                aria-describedby="phone"
                            disabled={!isIdVerified || !isAuthorized} // Disable if ID is not verified
                            style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }} />
                        </div>
                    </div>

                    {/*  MESSAGE  */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        <Form.Label htmlFor="messageNote">{t('message_for_recipient_label')}</Form.Label>
                        <Form.Control id="messageNote" type="text" value={messageNote} onChange={e => setMessageNote(e.target.value)} aria-describedby="messageNote"
                        disabled={!isIdVerified || !isAuthorized}
                        style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }} />
                    </div>

                    


        
                    <div className="mt-4"></div> {/* This adds space between sections */} 
                    {/*  ===============================Payment details:====================================  */}
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('payment_details')}</h5>
                    <div className="mt-2"></div> {/* This adds space between sections */} 

                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        {/*  SEND AMOUNT */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="sendAmount"> 
                                {t('sends_currency', { sender: user?.firstName, currency: 'CAD' })}
                            </Form.Label>
                            <Form.Control 
                                id="sendAmount" 
                                type="text" 
                                value={sendAmount} 
                                onChange={async e => {
                                    let value = e.target.value;

                                    // Allow only numbers, periods, and commas
                                    value = value.replace(/[^\d.,]/g, '');

                                    // Replace commas with periods to standardize the decimal format
                                    value = value.replace(/,/g, '.');

                                    setSendAmount(value);
                                
                                    // Calculate fees
                                    const fees = (parseFloat(value) * 0.06).toFixed(2);  // 6% fees
                                    setFeesAmount(fees);
                                
                                    // Only fetch the exchange rate if the value is >= 10
                                    if (parseFloat(value) >= 10) {
                                        const convertedAmount = await fetchExchangeRate('CAD', currency, parseFloat(value));
                                        if (convertedAmount) {
                                            setReceiveAmount(convertedAmount.toFixed(2));  // Update with the converted amount
                                        } else {
                                            setReceiveAmount('');  // Clear the field if conversion fails
                                        }
                                    } else {
                                        setReceiveAmount('');  // Clear the field if the amount is less than 10
                                    }

                                    // Calculate total as sendAmount + feesAmount
                                    const total = (parseFloat(value) + parseFloat(fees)).toFixed(2);
                                    setTotalAmount(isNaN(total) ? '---' : total);  // Update the totalAmount state, showing '---' if NaN

                                }} 
                                aria-describedby="sendAmount" 
                                disabled={!isIdVerified || !isAuthorized}  // Disable if the ID is not verified
                                style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }}  // Grey out if disabled
                            />
                        </div>

                        {/*  RECEIVE AMOUNT */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="receiveAmount">{firstName} {t('receives_label')} {currency}</Form.Label>
                            <Form.Control 
                                id="receiveAmount" 
                                type="text" 
                                value={receiveAmount} 
                                onChange={e => {
                                    let value = e.target.value;

                                    // Allow only numbers, periods, and commas
                                    value = value.replace(/[^\d.,]/g, '');

                                    // Replace commas with periods to standardize the decimal format
                                    value = value.replace(/,/g, '.');

                                    setReceiveAmount(value);
                                }} 
                                aria-describedby="receiveAmount"
                                disabled={!isIdVerified || !isAuthorized}
                                style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }}
                            />
                        </div>

                    </div>

                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        {/*  FEES */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="feesAmount">{t('transfer_fees_label')}</Form.Label>
                            <Form.Control 
                                id="feesAmount" 
                                type="text" 
                                value={`${feesAmount} CAD`}
                                onChange={e => setFeesAmount(e.target.value)} 
                                aria-describedby="feesAmount" 
                                readOnly  // Make this field read-only since it is calculated automatically
                                style={{ backgroundColor: '#e9ecef', cursor: 'not-allowed' }} // Grey out as it's read-only
                            />
                        </div>

                        {/*  TOTAL AMOUNT */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                            <Form.Label htmlFor="totalAmount">{t('total_to_pay_label', {currency: 'CAD'}  )}</Form.Label>
                            
                            <Form.Control 
                                id="totalAmount" 
                                type="text" 
                                value={totalAmount} 
                                onChange={e => setTotalAmount(e.target.value)} 
                                aria-describedby="totalAmount" 
                                disabled={!isIdVerified || !isAuthorized}  // Disable if the ID is not verified
                                style={{ backgroundColor: !isIdVerified ? '#e9ecef' : '', cursor: !isIdVerified ? 'not-allowed' : '' }}  // Grey out if disabled
                            />
                        </div>
                    </div>

                    {(firstName && lastName && address && city && country && phone && sendAmount && currency) && (
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">Send Request</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={handle_CreateTransaction}>
                                Send Request
                            </Button>
                        </OverlayTrigger>
                    )}


                    <div className="mt-4"></div> {/* This adds space between sections */} 
                    {/*  ===============================Terms of Service:====================================  */}
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('terms_of_service_title')} </h5>
                    <p style={{ color: 'green', marginTop: '10px', paddingLeft: '60px' }}>
                        {t('terms_of_service_1')}
                        <br />
                        {t('terms_of_service_2')}
                        <br />
                        {t('terms_of_service_3')}
                        <br />
                        {t('terms_of_service_4')}
                    </p>

                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                    </div>
                </div>
            </div>

            {/* Review Sending Request */}
            <Modal show={showQuoteModal} onHide={() => setShowQuoteModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Review Sending Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-3">
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Request#:</strong></td>
                                    <td>{requestNumber}</td> {/* Display request number */}
                                </tr>

                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Send to:<br />Phone:</strong></td>
                                    <td>{firstName} {lastName}<br />{phone}</td>
                                </tr>

                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Address:<br />City:<br />Country:</strong></td>
                                    <td>{address}<br />{city}<br />{country}</td>
                                </tr>

                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Sending:</strong></td>
                                    <td>{sendAmount} CAD</td>
                                </tr>

                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Receive:</strong></td>
                                    <td>{receiveAmount} {currency}</td>
                                </tr>

                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Total:</strong></td>
                                    <td>{totalAmount}</td>
                                </tr>

                                <tr>
                                    <td><strong style={{ color: 'blue' }}>Message:</strong></td>
                                    <td>{messageNote}</td>
                                </tr>

                            </tbody>
                        </table>
                        By accepting the transaction you will: <br /> <br />
                        &nbsp;&nbsp;(1) &nbsp; Be able to Download the Invoice immediately<br />
                        &nbsp;&nbsp;(2) &nbsp; Agree to pay <strong>{totalAmount}$</strong> before proceeding <br />

                        {/* Add checkbox for email receipt */}
                        <Form.Group controlId="emailReceipt" className="mt-2">
                            <Form.Check 
                                type="checkbox" 
                                label={
                                    <>
                                        Email my request at <strong>{user?.email}</strong>
                                    </>
                                } 
                                checked={emailReceipt} 
                                onChange={(e) => setEmailReceipt(e.target.checked)} 
                            />
                        </Form.Group>


                        <strong style={{ color: 'red' }}>Send the payment by Interact to initiate the transfer:</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={() => {
                            setShowQuoteModal(false);
                            toast.warning("Cancelled request", {
                                className: "toast-center",
                                bodyClassName: "toast-body-center",
                                position: "bottom-center",  // You still need to set this, but we'll override it with CSS
                            });
                        }}>Back
                    </Button>

                    <Button variant="primary" onClick={() => {
                            toast.success("Processing your request...", {
                                className: "toast-center",
                                bodyClassName: "toast-body-center",
                                position: "top-center",  // This will be overridden by CSS for middle of screen
                            });
                            handle_PlaceOrder();  // Call the existing function to place the order
                            
                        }}>Confirm Order
                        </Button>

                </Modal.Footer>
            </Modal>

        </TabContentContainer>
    );
};

export default SubsPartsOrders;
