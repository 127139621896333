/* File: /src/services/userUserStoreApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const userUserStoreApi = createApi({
    reducerPath: "userUserStoreApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/userstore",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["userStore"],
    endpoints: builder => ({
        healthCheck:         builder.query({ query: ({ source }) =>        ({ url: `/health`,                  method: "GET", params: { source }, })        }),
        getVehicle:          builder.query({ query: id =>        ({ url: `/getVehicle/${id}`,        method: "GET" }),                   providesTags:    ["userStore"] }),
        getVideoPlayer:      builder.query({ query: id =>        ({ url: `/getVideoPlayer/${id}`,    method: "GET" }),                   providesTags:    ["userStore"] }),
        getInterests:        builder.query({ query: id =>        ({ url: `/getInterests/${id}`,      method: "GET" }),                   providesTags:    ["userStore"] }),
        //getReferrals:      builder.query({ query: id =>        ({ url: `/getReferrals/${id}`,      method: "GET" }),                   providesTags:    ["userStore"] }),
        getReferrals:        builder.query({ query: ({ id, source }) => ({ url: `/getReferrals/${id}`, method: "GET", params: { source }, }), providesTags: ["userStore"] }),

        // getSubscriptions:    builder.query({ query: id =>        ({ url: `/getSubscriptions/${id}`,  method: "GET" }),                   providesTags:    ["userStore"] }),

        saveVehicle:            builder.mutation({ query: ({ data, source }) => ({ url: "/saveVehicle",             method: "POST", body: { ...data, source } }), invalidatesTags: ["userStore"] }),
        saveVideoPlayer:        builder.mutation({ query: ({ data, source }) => ({ url: "/saveVideoPlayer",         method: "POST", body: { ...data, source } }), invalidatesTags: ["userStore"] }),
        getSubscriptionsByType: builder.mutation({ query: ({ data, source }) => ({ url: `/getSubscriptionsByType`,  method: "POST", body: { ...data, source } }), invalidatesTags: ["userStore"] }),
        createReferral:         builder.mutation({ query: ({ data, source }) => ({ url: `/createReferral`,          method: "POST", body: { ...data, source } }), invalidatesTags: ["userStore"] }),
        updateReferralStatus:   builder.mutation({ query: ({ data, source }) => ({ url: `/updateReferralStatus`,    method: "POST", body: { ...data, source } }), invalidatesTags: ["userStore"] }),
        getSubscriptions:       builder.mutation({ query: ({ data, source }) => ({ url: `/getSubscriptions`,        method: "POST", body: { ...data, source } }), invalidatesTags: ["userStore"] }),

        updateInterests:        builder.mutation({ query: payload =>   ({ url: `/updateInterests`,         method: "PATCH", body: payload }),  invalidatesTags: ["userStore"] }),
        updateSubscriptions:    builder.mutation({ query: payload =>   ({ url: `/updateSubscriptions`,     method: "PATCH", body: payload }),  invalidatesTags: ["userStore"] }),

        deleteVehicle:          builder.mutation({ query: id =>        ({ url: `/deleteVehicle/${id}`,     method: "DELETE" }),                invalidatesTags: ["userStore"] }),

        // Beneficiary Endpoints
        saveBeneficiary:        builder.mutation({ query: data =>        ({ url: "/saveBeneficiary",           method: "POST",   body: data }),    invalidatesTags: ["userStore"] }),
        getBeneficiary:         builder.query   ({ query: ({ source }) =>          ({ url: "/getBeneficiary",            method: "GET", params: { source }, }),                   providesTags:    ["userStore"] }),
        deleteBeneficiary:      builder.mutation({ query: id =>          ({ url: `/deleteBeneficiary/${id}`,   method: "DELETE" }),                invalidatesTags: ["userStore"] }),

    })
});

export const { 
    useHealthCheckQuery,
    useSaveVehicleMutation, 
    useSaveVideoPlayerMutation, 
    useGetVehicleQuery, 
    useGetVideoPlayerQuery,
    useGetInterestsQuery,
    useGetReferralsQuery,
    useUpdateInterestsMutation,
    // useGetSubscriptionsQuery,
    useGetSubscriptionsMutation,
    useUpdateSubscriptionsMutation,
    useDeleteVehicleMutation ,
    useGetSubscriptionsByTypeMutation,
    useCreateReferralMutation,
    useUpdateReferralStatusMutation,
    useSaveBeneficiaryMutation,
    useGetBeneficiaryQuery,
    useDeleteBeneficiaryMutation,
} = userUserStoreApi;
