/* REAL - SETTINGS - MENU */
/* File:  /src/pages/Settings/ProfileTabComponent/SettingsTabs.jsx */
import { useTranslation 
        }                       from 'react-i18next';
import { 
        AnimatePresence, 
        motion 
        }                       from "framer-motion";
import { 
        useEffect, 
        useState 
        }                       from "react";
import { 
        useLocation,
        NavLink 
        }                       from "react-router-dom";
import Billing                  from '../Billing';
import ContactInfo              from '../ContactInfo';
import Notifications            from '../Notifications';
import Documents                from '../Documents';
import Referrals                from '../Referrals';
import Security                 from '../Security';
import { 
        TabComponentWrapper, 
        TabContentBox, 
        TabNavItem, 
        TabNavList, 
        TabNavHeader 
        }                       from "./profileTabComponentStyle";

// Mapping of tab IDs to query parameters

const SettingsTabs = ({ tabs, user, refetch, setRefetch }) => {
    const { t } = useTranslation('settingsMenu');
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('Security'); // default tab

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        }
    }, [location.search]);


    useEffect(() => {
        // Ensure re-render happens when `user` changes
        console.log('User prop changed:', user);
    }, [user]);


    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <TabComponentWrapper id="PersonalPreferences">
            <TabNavHeader>
                <TabNavList $selectedid={activeTab} $tabcount={tabs.length}>
                    {['contact-info', 'security', 'notifications', 'documents', 'referrals', 'billing'].map((tabKey) => (
                        <TabNavItem
                            key={tabKey}
                            className={tabKey === activeTab ? "TabNavItem selected" : "TabNavItem"}
                            onClick={() => handleTabChange(tabKey)}
                        >
                            <NavLink to={`/settings?tab=${tabKey}`}>
                                <span>{t(`tabs.${tabKey}.label`)}</span> {/* Use t function for translation */}
                            </NavLink>
                        </TabNavItem>
                    ))}
                    <div className="slider">
                        <div className="indicator"></div>
                    </div>
                </TabNavList>
            </TabNavHeader>

            <TabContentBox>
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {activeTab === 'contact-info'   && <ContactInfo     user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'security'       && <Security        user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'notifications'  && <Notifications   user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'documents'      && <Documents       user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'referrals'      && <Referrals       user={user} refetch={refetch} setRefetch={setRefetch} />}
                        {activeTab === 'billing'        && <Billing         user={user} refetch={refetch} setRefetch={setRefetch} />}
                    </motion.div>
                </AnimatePresence>
            </TabContentBox>
        </TabComponentWrapper>
    );
};

export default SettingsTabs;
