/* eslint-disable no-unused-vars */
/* REAL - MANAGE BILLING */
import { UserContext }                      from "../../../../App";
import                                           "./ManageBilling.css";
import { format }                           from 'date-fns';
import moment                               from "moment";


/* REACT IMPORT =======================  */
import React, 
       { useContext, 
         useState, 
         useEffect, 
         useCallback, 
        }                                   from "react";
import DatePicker                           from 'react-datepicker';
import                                           'react-datepicker/dist/react-datepicker.css';

import { toast }                            from "react-toastify";
import { Button, Col, Form, Modal, Row }    from "react-bootstrap";
import BootstrapTable                       from "react-bootstrap-table-next";
import filterFactory, 
       { textFilter }                       from "react-bootstrap-table2-filter";
import paginationFactory                    from "react-bootstrap-table2-paginator";
import { useNavigate }                      from "react-router-dom";
import { IoCloseSharp }                     from "react-icons/io5";
import Select                               from "react-select";

/* API's IMPORT =======================  */
import { useSignupMutation }                from "../../../../services/userUserApi";
import { useGetAllBillingsQuery, 
         useCreateInvoiceMutation, 
         useUpdateInvoiceMutation 
       }                                    from "../../../../services/orderBillingApi";
import { useGetAllUsersQuery }              from "../../../../services/userUserApi";
import { useGetAllCoursesQuery }            from "../../../../services/svcLearningApi";
import { useInternetListPackagesQuery }          from "../../../../services/svcInternetApi";
import { 
        useGetAllRemittancesQuery,
        }                                   from "../../../../services/svcRemittanceApi";
import { 
        useGetAllTranscriptsQuery,
        }                                   from "../../../../services/svcTranscriptApi";
import { 
        useGetAllMarketingsQuery,
        }                                   from "../../../../services/svcMarketingApi";
import { 
        useGetAllVehiclePartsQuery, 
        }                                   from "../../../../services/svcVehiclePartsApi";
import { 
    useGetSubscriptionsMutation,
       }                                    from "../../../../services/userUserStoreApi";

/* OTHER IMPORT =======================  */
import {
        FormButton,
        FormCheckField,
        FormControlField,
        FormLabelText,
        ShowHidePassField,
        InvoiceForm,
       }                                    from "../../../Auth/SignUp/signUpStyle";
import { staggerVariants, tileVariants }    from "../../../../utils/FmVariants";
import { SVGImages }                        from "../../../../config/images";


const ManageBilling = ({ show, handleClose }) => {
    const Source_Name           = "MANAGE BILLING";
    const STG_Billing_NbDigit = 7;

    const { data: billingList }                 = useGetAllBillingsQuery({       source: Source_Name });
    const { data: userLists }                   = useGetAllUsersQuery({          source: Source_Name });
    const { data: learningLists }               = useGetAllCoursesQuery({        source: Source_Name });
    const { data: internetLists }               = useInternetListPackagesQuery({ source: Source_Name });
    const { data: remittanceLists }             = useGetAllRemittancesQuery({    source: Source_Name });
    const { data: transcriptLists }             = useGetAllTranscriptsQuery({    source: Source_Name });
    const { data: marketingLists }              = useGetAllMarketingsQuery({     source: Source_Name });
    const { data: vehiclePartLists }            = useGetAllVehiclePartsQuery({   source: Source_Name });


    const [selectedUsers,           setSelectedUsers]           = useState([]);
    const [billList,                setBillList]                = useState([]);
    const [userList,                setUserList]                = useState([]);
    const [learningList,            setLearningList]            = useState([]);
    const [internetList,            setInternetList]            = useState([]);
    const [vehiclePartList,         setVehiclePartList]         = useState([]);
    const [remittanceList,          setRemittancetList]         = useState([]);
    const [transcriptList,          setTranscriptList]          = useState([]);
    const [marketingList,           setMarketingList]           = useState([]);



    const [createInvoice]                       = useCreateInvoiceMutation();
    const [updateInvoice]                       = useUpdateInvoiceMutation();

    const [createDate,      setCreateDate]      = useState(new Date());
    const [dueDate,         setDueDate]         = useState(new Date());
    const [invoiceModal,    setInvoiceModal]    = useState(false);
    const [isEdit,          setIsEdit]          = useState(false);
    const [selectedId,      setSelectedId]      = useState("");
    const [error,           setError]           = useState("");
    const [verifCaptcha,    setverifCaptcha]    = useState(false);
    const [phone,           setPhone]           = useState("");
    const [subscribe,       setSubscribe]       = useState(false);
    const [error1,          setError1]          = useState(false);
    const [errorPayment,    setErrorPayment]    = useState(false);
    



    const columns = [
        {   dataField: "_id",                 text: "ID",                   sort: true,
                                              formatter: cell => {    return <span>{cell?.toString().slice(cell.length - 7, cell.length)}</span>; },
        },
        {   dataField: "invoiceNumber",       text: "InvNumber",            sort: true,        },
        {   dataField: "fullname",            text: "Member Full Name",     sort: true,            filter: textFilter(),        },
        {   dataField: "fullAddress",         text: "Billing Address",      sort: true,            filter: textFilter(),        },
        {   dataField: "date",                text: "Create-Date",          sort: true,
                                              formatter: cell => {    return <span>{moment(cell).format("YYYY-MM-DD")}</span>;    },
        },
        {   dataField: "dueDate",             text: "Due-Date",             sort: true,
                                              formatter: cell => {    return <span>{moment(cell).format("YYYY-MM-DD")}</span>;    },
        },
        {   dataField: "services",            text: "Services",             sort: true,            filter: textFilter(),        },
        {   dataField: "status",              text: "Status",               sort: true,            filter: textFilter(),        },
        {   dataField: "totalPrice",          text: "Amount",               sort: true,
                                              formatter: cell => {    return <span>{cell?.toFixed(2)}</span>; },
        },
        {   dataField: "fee",                 text: "fee",              sort: true,
                                              formatter: cell => {    return <span>{cell?.toFixed(2)}</span>; },
        },
        {   dataField: "balance",             text: "Bal.",                 sort: true,
                                              formatter: cell => {    return <span>{cell?.toFixed(2)}</span>; },
        },
    ];



    // Calculate the initial due date as 21 days after the current date
    const initialCreateDate = new Date();
    const initialDueDate = new Date(initialCreateDate);
    initialDueDate.setDate(initialDueDate.getDate() + 21);
    const [formData,        setFormData]        = useState({
                                                    invoiceNumber: "INV",
                                                    member: "",
                                                    createDate: format(initialCreateDate, 'yyyy-MM-dd'),  // Format as needed
                                                    dueDate: format(initialDueDate, 'yyyy-MM-dd'),        // Set initial due date 21 days after create date
                                                    paymentReceivedAmount: "",
                                                    paymentReceivedDate: "",
                                                    fee: "",
                                                });
    const [subMainList, setSubMainList] = useState([
                                                {   value: "internet",          label: "Internet",  },
                                                {   value: "learning",          label: "Learning",  },                                        
                                                {   value: "transcripts",       label: "Transcripts",  },
                                                {   value: 'marketings',        label: 'Marketings', },
                                                {   value: 'vehicleParts',      label: 'VehicleParts', },
                                                {   value: 'onlineConference',  label: 'OnlineConference', },
                                                {   value: "remittances",       label: "Remittances",  },
                                                
                                            ]);
    const [serviceList,             setServiceList]             = useState([]);
    const [totalPrice,              setTotalPrice]              = useState(0);
    const [lastBillBalance,         setLastBillBalance]         = useState(0);
    const [remainingTotal,          setRemainingTotal]          = useState(0);

    const [formArray, setFormArray] = useState([]);


    /* Section 02.0 - const useFetchSubscriptions */
    const useFetchSubscriptions = (selectedUsers) => {
        const [subscriptions, setSubscriptions] = useState({});
        const [fetchSubscriptions, { isLoading, error }] = useGetSubscriptionsMutation();

        const fetchAllSubscriptions = useCallback(async () => { // Use useCallback to memoize the function
            if (selectedUsers.length > 0) {
                try {
                    const data = {
                        userIds: selectedUsers, 
                    };
                    const response = await fetchSubscriptions({ data, source: Source_Name }).unwrap();
                    if (response && response.data) {
                        const subs = response.data.reduce((acc, sub) => {
                            acc[sub.userId] = sub.subscriptions;
                            return acc;
                        }, {});
                        setSubscriptions(subs);
                    } else {
                        console.error("Invalid response structure:", response);
                    }
                } catch (error) {
                    console.error("Error fetching subscriptions:", error);
                }
            } else {
                setSubscriptions({});  // Clear subscriptions if no users are selected
            }
        }, [selectedUsers, fetchSubscriptions]); // Add dependencies here

        useEffect(() => {
            console.log("Fetching subscriptions for selected users:", selectedUsers);
            fetchAllSubscriptions();
        }, [selectedUsers, fetchAllSubscriptions]); // Include fetchAllSubscriptions in dependency array

        return { subscriptions, isLoading, error, refetch: fetchAllSubscriptions }; // Return refetch
    };
    // Usage with refetch
    const { subscriptions, isLoading: subsLoading, error: subsError, refetch: fetchSubscriptions } = useFetchSubscriptions(selectedUsers);
    

    // Function to safely format dates
    const safeFormat = (date, formatStr) => {
        return date ? format(new Date(date), formatStr) : '';
    };

    const handleInputChange = (field, event) => {
        if (field === "member") {
            const tempObject = userLists?.data.find(item => item._id === event.value);
            setSelectedUsers([tempObject._id]);
            const newValue = {
                value: tempObject._id,
                label: tempObject.firstName + " " + tempObject.lastName,
            };
            setFormData({
                ...formData,
                member: newValue,
            });
        } else if (field === "dueDate") {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
            });
        } else if (field === "fee") {
            const feeValue = event.target.value;
            
            // Allow only numbers and a single dot for decimals
            if (/^\d*\.?\d*$/.test(feeValue)) {
                const numericFeeValue = Number(feeValue) || 0; // Convert to number only after validating
                const newTotal = calculateTotalPrice(numericFeeValue, formData.paymentReceivedAmount);
                setFormData({
                    ...formData,
                    fee: feeValue,
                });
                setTotalPrice(newTotal);
            }
        } else if (field === "paymentReceivedAmount") {
            setFormData({
                ...formData,
                paymentReceivedAmount: event.target.value,
            });
        }
    };

    const getBalance = data => {
        if (isEdit) {
            return (remainingTotal - data).toFixed(2);
        } else {
            return (totalPrice - data).toFixed(2);
        }
    };

    /* Section: handleSubmitData */
    const handleSubmitData = () => {
        if (!errorPayment) {
            if (formData.member && formData.member.value && formData.dueDate) {
                setError(`Got MEMBER information! ${formData.member.label} && ${formData.dueDate}`);
                const serviceValues = formArray
                    .map(item => (item.service && item.service.value ? item.service.value : null))
                    .filter(value => value);
                if (serviceValues.length === formArray.length) {
                    setError("");
                    setError(`Processing information!`);
                    const tempArr = formArray.map(item => {
                        return {
                            itemType: item.serviceMain.value,
                            item: item.service.value,
                        };
                    });
                    const data = {
                        invoiceNumber:          formData.invoiceNumber,
                        user:                   formData.member.value,
                        totalPrice:             totalPrice,
                        paymentReceivedAmount:  Number(formData.paymentReceivedAmount),
                        paymentReceivedDate:    new Date(formData.paymentReceivedDate).toISOString(),
                        date:                   new Date(formData.createDate).toISOString(),
                        dueDate:                new Date(formData.dueDate).toISOString(),
                        items:                  tempArr,
                        fee:                    Number(formData.fee),
                        balance:                getBalance(Number(formData.paymentReceivedAmount)),
                        status:                 getStatus(Number(formData.paymentReceivedAmount)),
                    };
                    console.log("data===", data);
                    if (isEdit) {
                        updateInvoice({
                            id: selectedId,
                            data: data,
                        }).then(res => {
                            if (res?.data?.status) {
                                toast.success(res?.data?.message);
                                setError("");
                                setInvoiceModal(false);
                            } else {
                                toast.error(res?.data?.message);
                            }
                        });
                    } else {
                        createInvoice(data).then(res => {
                            if (res?.data?.status) {
                                toast.success(res?.data?.message);
                                setError("");
                                setInvoiceModal(false);
                            } else {
                                toast.error(res?.data?.message);
                            }
                        });
                    }
                } else {
                    setError(`Please fill out the required information! SERVICE${serviceValues.length} === ${formArray.length}`);
                }
            } else {
                setError(`Please fill out the required information! MEMBER ${formData.member.label} && ${formData.dueDate}`);
            }
        } else {
            if (isEdit) {
                setError(` You cannot pay more than your remaining amount of ${remainingTotal}.`);
            } else {
                setError(` You cannot pay more than your final amount of ${totalPrice}.`);
            }
        }
    };

    const handleRowSelect = (row, isSelect, rowIndex, e) => {
        if (isSelect) {
            setIsEdit(true);
            setSelectedId(row?._id);

            const tempUser = userLists?.data.find(item => item._id === row.user);
            const tempMember = {
                value: tempUser._id,
                label: tempUser.firstName + " " + tempUser.lastName,
            };

            const newData = {
                invoiceNumber: row.invoiceNumber,
                member: tempMember,
                createDate: row.date,
                dueDate: row.dueDate.split("T")[0],
                fee: row.fee,
                lastBillBalance: row.balance,
                paymentReceivedAmount: '',
                paymentReceivedDate: row.date,
            };
            setFormData(newData);
            setRemainingTotal(row.balance);

            const newRecord = [];
            row.serviceList.forEach(service => {
                const submain = subMainList.find(subMain => subMain.value === service.itemType);
                console.log("subMainList",subMainList);
                console.log("service.itemType",service.itemType);
                let tempObject;
                let newValue;
                if (       submain.value === "internet") {
                    tempObject = internetLists?.data.find(item => item._id === service.item);
                    newValue = {
                        value: tempObject._id,
                        label: tempObject.serviceCode,
                    };
                } else if (submain.value === "learning") {
                    tempObject = learningLists?.data.find(item => item._id === service.item);
                    newValue = {
                        value: tempObject._id,
                        label: tempObject.serviceCode,
                    };
                } else if (submain.value === "remittances") {
                    tempObject = remittanceLists?.data.find(item => item._id === service.item);
                    newValue = {
                        value: tempObject._id,
                        label: tempObject.serviceCode,
                    };
                } else if (submain.value === "transcripts") {
                    tempObject = transcriptLists?.data.find(item => item._id === service.item);
                    newValue = {
                        value: tempObject._id,
                        label: tempObject.serviceCode,
                    };
                } else if (submain.value === "marketings") {
                    tempObject = marketingLists?.data.find(item => item._id === service.item);
                    newValue = {
                        value: tempObject._id,
                        label: tempObject.serviceCode,
                    };
                } else if (submain.value === "vehicleParts") {
                    tempObject = vehiclePartLists?.data.find(item => item._id === service.item);
                    newValue = {
                        value: tempObject._id,
                        label: tempObject.serviceCode,
                    };
                }

                const newServices = {
                    serviceMain:    submain,
                    service:        newValue,
                    amount:         Number(tempObject.price),
                    tps:            Number(getTaxTPS(tempObject.price)),
                    tvq:            Number(getTaxTVQ(tempObject.price)),
                    total:          Number(getTotalAmount(tempObject.price)),
                };

                newRecord.push(newServices);
            });

            setFormArray(newRecord);
            const allTotal = newRecord.reduce((acc, item) => acc + item.total, 0);
            setTotalPrice(Number(allTotal).toFixed(2));
            
            // Fetch subscriptions for the selected user
            setSelectedUsers([row.user]);  // Assuming row.user holds the user ID
            setInvoiceModal(true);
        }
    };

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        onSelect: handleRowSelect,
    };

    const calculateTotalPrice = useCallback((fee, paymentReceivedAmount) => {
        const serviceTotal = formArray.reduce((acc, item) => acc + Number(item.total), 0);
        const totalFee = Number(fee || 0); // Convert fee to a number, default to 0 if undefined
        const totalPayment = Number(paymentReceivedAmount || 0); // Convert payment to a number, default to 0 if undefined
        return (serviceTotal + totalFee - totalPayment).toFixed(2); // Subtract the payment amount from the total
    }, [formArray]);
    
    useEffect(() => {
        setTotalPrice(calculateTotalPrice(formData.fee, formData.paymentReceivedAmount));
    }, [formArray, formData.fee, formData.paymentReceivedAmount, calculateTotalPrice]);
    
    
    
    const getTotal = (price, qnty) => {
        return price * qnty;
    };

    const fullname = (user) => {
        if (user && user.length > 0) {
            return `${user[0]?.firstName || ''} ${user[0]?.lastName || ''}`;
        }
        return '';
    };
    

    const fullAddress = (user) => {
        if (user && user.length > 0) {
            const firstUser = user[0];
            return `${firstUser.address || ''}, ${firstUser.city || ''}`;
        }
        return '';
    };

    const getTax = useCallback((amount) => {
        const tpsRate = 0.05; // TPS rate (5%)
        const tvqRate = 0.09975; // TVQ rate (9.975%)
    
        // Calculate taxes
        const tps = amount * tpsRate;
        const tvq = amount * tvqRate;
    
        return tps + tvq;
    }, []);
    

    const getTaxTPS = amount => {
        const tpsRate = 0.05; // TPS rate (5%)
        const tps = amount * tpsRate;
        return parseFloat(tps).toFixed(2);
    };

    const getTaxTVQ = amount => {
        const tvqRate = 0.09975; // TVQ rate (9.975%)
        const tvq = amount * tvqRate;
        return parseFloat(tvq).toFixed(2);
    };

    const getTotalAmount = useCallback((amount) => {
        const tax = getTax(amount);
        const amounts = parseFloat(amount);
        return parseFloat(amounts + tax).toFixed(2);
    }, [getTax]);
    

    const getCommaText = uniqueItems => {
        if (uniqueItems.length > 1) {
            const result = uniqueItems.join(", ");
            return result;
        } else {
            const result = uniqueItems[0];
            return result;
        }
    };

    const getLastInvoiceNumber = (invoices) => {
        if (invoices.length === 0) {
            const defaultInvoiceNumber = `INV${'0'.repeat(STG_Billing_NbDigit)}`;
            return { invoiceNumber: defaultInvoiceNumber };
        } // Default to INV0000000 if no invoices exist

        const lastInvoice = invoices.reduce((prev, current) => {
            const prevNumber = isNaN(parseInt(prev.invoiceNumber.replace("INV", ""), 10)) ? 0 : parseInt(prev.invoiceNumber.replace("INV", ""), 10);
            const currentNumber = parseInt(current.invoiceNumber.replace("INV", ""), 10);
            return currentNumber > prevNumber ? current : prev;
        });
        return lastInvoice;
    };
    
    const generateNextInvoiceNumber = (lastInvoice) => {
        let numericPart = parseInt(lastInvoice.invoiceNumber.replace("INV", ""), 10);
        if (isNaN(numericPart)) {
            numericPart = 0; // Default to 0 if parsing failed
        }
        const nextNumericPart = numericPart + 1;
        const formattedNumber = nextNumericPart.toString().padStart(STG_Billing_NbDigit, "0");
        return `INV${formattedNumber}`;
    };
    
    const formatDate = isoString => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        return `${year}-${month}-${day}`; // Format for input type date
    };

    const handleAddMore = () => {
        setFormArray([
            ...formArray,
            {
                serviceMain: "",
                service: "",
                amount: "",
                tps: "",
                tvq: "",
                total: "",
            },
        ]);
    };

    /* Section: handleFormArrayChange */
    const handleFormArrayChange = useCallback((index, field, value) => {
        const newFormArray = [...formArray];
    
        if (field === "serviceMain") {
            let tempServiceList = [];
    
            // Assuming the value corresponds to the key in the subscriptions object
            if (subscriptions[selectedUsers[0]] && subscriptions[selectedUsers[0]][value]) {
                console.log("Subscription Services [value]:", subscriptions[selectedUsers[0]][value]); // Debugging line
                console.log("Subscription Services:", subscriptions[selectedUsers[0]]); // Debugging line

                tempServiceList = subscriptions[selectedUsers[0]][value].map(item => ({
                    value: item.serviceCode,
                    label: item.serviceCode,
                }));
            }
    
            setServiceList(tempServiceList);
            console.log("serviceList:", serviceList);  // Debugging line
    
            newFormArray[index][field] = { value, label: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() };
            newFormArray[index]["service"] = ""; // Reset service when the main subscription is changed
            newFormArray[index]["amount"] = "";
            newFormArray[index]["tps"] = "";
            newFormArray[index]["tvq"] = "";
            newFormArray[index]["total"] = "";
        } else if (field === "service") {
            const selectedService = serviceList.find(item => item.value === value);
            console.log("service=========:");
            console.log("selectedService:", selectedService);
        
            if (selectedService) {
                const serviceCategory = newFormArray[index]["serviceMain"].value;
                console.log("serviceCategory:", serviceCategory);
                const serviceDataMap = {
                    internet: internetLists?.data,
                    learning: learningLists?.data,
                    remittances: remittanceLists?.data,
                    transcripts: transcriptLists?.data,
                    marketings: marketingLists?.data,
                    vehicleParts: vehiclePartLists?.data,
                };
    
                console.log("serviceDataMap:", serviceDataMap);
                const tempObject = serviceDataMap[serviceCategory]?.find(item => item.serviceCode === value);
                console.log("tempObject:", tempObject);
                
                if (tempObject) {
                    newFormArray[index][field] = { value: tempObject._id, label: tempObject.serviceCode };
                    newFormArray[index]["amount"] = Number(tempObject.price);
                    newFormArray[index]["tps"] = Number(getTaxTPS(tempObject.price));
                    newFormArray[index]["tvq"] = Number(getTaxTVQ(tempObject.price));
                    newFormArray[index]["total"] = Number(getTotalAmount(tempObject.price));
                } else {
                    console.error(`No matching service found for service code: ${value} in category: ${serviceCategory}`);
                }
            }
        }
    
        const allTotal = newFormArray.reduce((acc, item) => acc + item.total, 0);
        // setTotalPrice(Number(allTotal).toFixed(2));
        setTotalPrice(calculateTotalPrice(formData.fee));

        setFormArray(newFormArray);
    }, [formArray, subscriptions, selectedUsers, serviceList, getTotalAmount, internetLists?.data, learningLists?.data, marketingLists?.data, remittanceLists?.data, transcriptLists?.data, vehiclePartLists?.data, formData.fee, calculateTotalPrice]); ;
    


    const removeFormField = index => {
        setFormArray(formArray.filter((_, i) => i !== index));
    };

    const getStatus = data => {
        if (isEdit) {
            const bal = (remainingTotal - data).toFixed(2);
            if (bal > 0) {
                return "Partial";
            } else {
                return "Paid";
            }
        } else {
            const bal = (totalPrice - data).toFixed(2);
            if (bal === totalPrice) {
                return "Submitted";
            } else if (bal > 0) {
                return "Partial";
            } else {
                return "Paid";
            }
        }
    };

    /* Section: openInvoicePopup */
    const openInvoicePopup = () => {
        const lastInvoiceNumber = getLastInvoiceNumber(billingList?.data || []);
        const nextInvoiceNumber = generateNextInvoiceNumber(lastInvoiceNumber);
        setFormData({
            invoiceNumber: nextInvoiceNumber,
            member: "",
            createDate: new Date().toISOString(), // Format as ISO string
            dueDate: format(new Date(new Date().setDate(new Date().getDate() + 21)), 'yyyy-MM-dd'), // Set due date 21 days after createDate
            fee: "",
            paymentReceivedAmount: "",
            paymentReceivedDate: new Date().toISOString(), // Format as ISO string
        });
        setTotalPrice(0);
        setFormArray([
            {
                serviceMain: "",
                service: "",
                amount: "",
                tps: "",
                tvq: "",
                total: "",
            },
        ]);
        setInvoiceModal(true);
        setIsEdit(false);
    };

    const getPayment = data => {
        if (data.balance === 0.00) {
            return data.totalPrice
        } else {
            return data.totalPrice - data.balance
        }
    };

    useEffect(() => {
        if (billingList) {
            const newBillingList = [];

            billingList.data.forEach(billing => {
                const service = [];
                billing.items.forEach(item => {
                    service.push(item?.itemType);
                });
                const uniqueItems = [...new Set(service)];
                newBillingList.push({
                    _id:            billing._id,
                    invoiceNumber:  billing.invoiceNumber,
                    date:           billing.date,
                    totalPrice:     billing.totalPrice,
                    fullAddress:    fullAddress(billing?.users),
                    fullname:       fullname(billing?.users),
                    services:       getCommaText(uniqueItems),
                    status:         billing.status,
                    payment:        getPayment(billing),
                    balance:        billing.balance,
                    fee:            billing.fee,
                    dueDate:        billing.dueDate,
                    user:           billing.user,
                    serviceList:    billing.items,
                });
            });
            setBillList(newBillingList);
        }

        if (userLists && userLists?.data?.length) {
            const tempArr = userLists?.data.map(item => {             return {    value: item._id,    label: item.firstName + " " + item.lastName,    };    });
            setUserList(tempArr);
        }

        if (learningLists && learningLists?.data?.length) {
            const tempArr = learningLists?.data.map(item =>       {     return {    value: item._id,    label: item.serviceCode,    };  });
            setLearningList(tempArr);
        }

        if (internetLists && internetLists?.data?.length)       {
            const tempArr = internetLists?.data.map(item =>     {     return {    value: item._id,    label: item.serviceCode,    };  });
            setInternetList(tempArr);
        }

        if (remittanceLists && remittanceLists?.data?.length)   {
            const tempArr = remittanceLists?.data.map(item =>   {     return {    value: item._id,    label: item.serviceCode,    };  });
            setRemittancetList(tempArr);
        }

        if (transcriptLists && transcriptLists?.data?.length)   {
            const tempArr = transcriptLists?.data.map(item =>   {     return {    value: item._id,    label: item.serviceCode,    };  });
            setTranscriptList(tempArr);
        }

        if (marketingLists && marketingLists?.data?.length)     {
            const tempArr = marketingLists?.data.map(item =>    {     return {    value: item._id,    label: item.serviceCode,    };  });
            setMarketingList(tempArr);
        }

        if (vehiclePartLists && vehiclePartLists?.data?.length) {
            const tempArr = vehiclePartLists?.data.map(item =>  {     return {    value: item._id,    label: item.serviceCode,    };  });
            setVehiclePartList(tempArr);
        }


        if (formArray.length > 0) {
            const allTotal = formArray.reduce((acc, item) => acc + item.total, 0);
            setTotalPrice(Number(allTotal).toFixed(2));
        }
    }, [billingList, userLists, learningLists, internetLists, vehiclePartLists, marketingLists, transcriptLists, remittanceLists, formArray]);


    /* Section: useEffect - To filter and restrict the SubMainList to only those which the selected member is subscribed to */
    useEffect(() => {
        if (subscriptions[selectedUsers[0]]) { // Assuming a single user is selected
            const filteredSubMainList = Object.entries(subscriptions[selectedUsers[0]])
                .filter(([key, value]) => value.length > 0) // Filter out empty arrays
                .map(([key, value]) => ({
                    value: key,
                    label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(), // Capitalize first letter and lowercase the rest
                }));
            
            setSubMainList(filteredSubMainList);
        }
    }, [subscriptions, selectedUsers]);
    

    useEffect(() => {
        if (subMainList.length === 1) {
            // Automatically set the only available subscription option for all items in formArray
            const onlyOption = subMainList[0];
            const newFormArray = formArray.map((item, index) => {
                const updatedItem = {
                    ...item,
                    serviceMain: onlyOption,
                };
                
                // Trigger the handleFormArrayChange to update the service list
                handleFormArrayChange(index, "serviceMain", onlyOption.value);
                
                return updatedItem;
            });
            setFormArray(newFormArray);
        }
    }, [subMainList, formArray, handleFormArrayChange]);  // Added handleFormArrayChange to dependencies
    

    useEffect(() => {
        formArray.forEach((item, index) => {
            if (serviceList.length === 1 && item.service !== serviceList[0]) {
                // Automatically set the only available service option and trigger the onChange handler
                const onlyServiceOption = serviceList[0];
                const newFormArray = [...formArray];
                newFormArray[index].service = onlyServiceOption;
                
                setFormArray(newFormArray);
                handleFormArrayChange(index, "service", onlyServiceOption.value); // Trigger onChange
            }
        });
    }, [serviceList, formArray, handleFormArrayChange]);  // Added handleFormArrayChange to dependencies

    
    const handleCreateDateChange = date => {
        setCreateDate(date);
    };

    const handleDueDateChange = date => {
        setDueDate(date);
    };

    const handle_CloseInvoiceModal = () => {
        // Reset formData and formArray to their initial states
        setFormData({
            invoiceNumber: "",
            member: "",  // Clear member selection
            createDate: new Date().toISOString(),
            dueDate: format(new Date(new Date().setDate(new Date().getDate() + 21)), 'yyyy-MM-dd'),
            fee: "",
            paymentReceivedAmount: "",
            paymentReceivedDate: new Date().toISOString(),
        });
    
        setFormArray([
            {
                serviceMain: "",  // Reset subscription selection
                service: "",      // Reset service selection
                amount: "",
                tps: "",
                tvq: "",
                total: "",
            },
        ]);
    
        setSelectedUsers([]);  // Clear selected users
        setInvoiceModal(false);  // Close the modal
    };
    

    return (
        <div>
            {/* table start  */}
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-2 mb-3">
                <h4>Manage Billing</h4>
                <Button onClick={() => openInvoicePopup()} className="btn btn-primary">     New Invoice         </Button>
            </div>
            {/* table */}
            {billList?.length > 0 && (
                <div className="table-responsive">
                    <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={billList}
                        columns={columns}
                        defaultSortDirection="asc"
                        striped
                        hover
                        condensed
                        responsive
                        scrollable
                        pagination={paginationFactory()}
                        selectRow={selectRow}
                        noDataIndication="No data found!"
                        filter={filterFactory()}
                        filterPosition="top"
                    />
                </div>
            )}
            {/* <ModalBody show={show} handleClose={handleClose} /> */}

            {/* Create/Update Invoice */}
            <Modal show={invoiceModal} onHide={handle_CloseInvoiceModal} backdrop="static" keyboard={false}>
                <div className="create-user">
                    <IoCloseSharp onClick={() => setInvoiceModal(false)} className="close-btn-icon" />
                    <h3 className="text-center mb-3">{isEdit ? "Edit Service Invoice" : "Create Service Invoice"}</h3>
                    <InvoiceForm
                        variants={staggerVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.1 }}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="InvoiceNumber"  className=" form-label">    Invoice Number*      </label>
                                <input    type="text"           placeholder="Invoice Number" readOnly    value={formData.invoiceNumber}
                                            disabled className="form-control"/>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="Member"         className=" form-label">    Member*              </label>
                                <Select     id="Member"         className="mb-2"            options={userList}          value={formData.member}                     
                                    onChange={e => handleInputChange("member", e)}
                                    isDisabled={isEdit}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="createDate" className="form-label">Create Date*</label>
                                <DatePicker
                                    selected={new Date(formData.createDate)}
                                    onChange={date => {
                                        const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                                        setFormData({...formData, createDate: format(adjustedDate, 'yyyyrow-MM-dd')});
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    isDisabled={isEdit}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="dueDate" className="form-label">Due Date*</label>
                                <DatePicker
                                    selected={new Date(formData.dueDate)}
                                    onChange={date => {
                                        const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                                        setFormData({...formData, dueDate: format(adjustedDate, 'yyyy-MM-dd')});
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    minDate={new Date(formData.createDate)}
                                />
                            </div>
                        </div>

                        {formArray.map((item, index) => (
                            <div key={index} className="form-array-border">
                                <div className="service-remove">
                                    <IoCloseSharp onClick={() => removeFormField(index)} className="close-btn" />
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <label htmlFor={`serviceMain-${index}`} className=" form-label">    Subscription   </label>
                                        <Select     id={`serviceMain-${index}`} className="mb-2 select-dropdown"             
                                            options={subMainList}
                                            value={item.serviceMain}
                                            onChange={e => handleFormArrayChange(index, "serviceMain", e?.value)}
                                            isDisabled={!formData.member || !formData.member.value || subMainList.length === 1} // Disable if only one option or Member is not selected

                                            styles={{
                                                menu: (provided) => ({
                                                  ...provided,
                                                  maxHeight: '200px',  // Set a max height for the dropdown
                                                  overflowY: 'auto',   // Ensure it scrolls when content overflows
                                                }),
                                                menuList: (provided) => ({
                                                  ...provided,
                                                  maxHeight: '75px',  // Set the max height for the options list
                                                  overflowY: 'auto',   // Ensure it scrolls when content overflows
                                                }),
                                              }}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <label htmlFor={`service-${index}`} className=" form-label">        Service         </label>
                                        <Select     id={`service-${index}`} className="mb-2 select-dropdown"                
                                            options={serviceList}
                                            value={item.service}
                                            onChange={e => handleFormArrayChange(index, "service", e?.value)}
                                            isDisabled={!formData.member || !formData.member.value || !item.serviceMain || serviceList.length === 1} // Disable if Member or Subscription is not selected or if only one service is available

                                            styles={{
                                                menu: (provided) => ({
                                                  ...provided,
                                                  maxHeight: '200px',  // Set a max height for the dropdown
                                                  overflowY: 'auto',   // Ensure it scrolls when content overflows
                                                }),
                                                menuList: (provided) => ({
                                                  ...provided,
                                                  maxHeight: '75px',  // Set the max height for the options list
                                                  overflowY: 'auto',   // Ensure it scrolls when content overflows
                                                }),
                                              }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3">
                                        <label htmlFor={`amount-${index}`}  className=" form-label">        Amount      </label>
                                        <input type="text" value={item.amount} readOnly         placeholder="amount" />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <label htmlFor={`tps-${index}`}     className=" form-label">        TPS         </label>
                                        <input type="text" value={item.tps} readOnly            disabled    placeholder="tps"   className="form-control"/>
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <label htmlFor={`tvq-${index}`}     className=" form-label">        TVQ         </label>
                                        <input type="text" value={item.tvq} readOnly            disabled    placeholder="tvq"   className="form-control"/>
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <label htmlFor={`total-${index}`}   className=" form-label">        Total       </label>
                                        <input type="text" value={item.total} readOnly          placeholder="total" />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <Button type="button" className="mt-3 mb-3" onClick={handleAddMore}>
                            + Add More Service
                        </Button>

                        {isEdit ? (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="lastBillBalance"               className=" form-label">        Balance LastBill    </label>
                                        <input type="text"      value={lastBillBalance}      readOnly    placeholder="Balance LastBill" />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="paymentReceivedAmount"         className=" form-label">        Payments Amount      </label>
                                        <input type="text"      value={formData.paymentReceivedAmount}     placeholder="Payments Received" 
                                            onChange={e => handleInputChange("paymentReceivedAmount", e)}
                                        />
                                    </div>

                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="paymentReceivedDate"            className="form-label">         Payment Date        </label>
                                        <DatePicker
                                            selected={formData.paymentReceivedDate ? new Date(formData.paymentReceivedDate) : null}
                                            onChange={date => {
                                                const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                                                setFormData({...formData, paymentReceivedDate: safeFormat(adjustedDate, 'yyyy-MM-dd')});
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="finaltotal"             className=" form-label">        Total Amount        </label>
                                        <input type="text"      value={totalPrice}      readOnly    placeholder="Total Amount" />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="remainingtotal"         className=" form-label">        Remaining Balance   </label>
                                        <input type="text"      value={remainingTotal}  readOnly    placeholder="Remaining Total" />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="fee"           className=" form-label">        Fee             </label>
                                        <input  type="number"   value={formData.fee}            placeholder="Fee"
                                            onChange={e => handleInputChange("fee", e)}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="paymentReceivedAmount"         className=" form-label">        Payments Amount      </label>
                                        <input type="text"      value={formData.paymentReceivedAmount}     placeholder="Payments Received" 
                                            onChange={e => handleInputChange("paymentReceivedAmount", e)}
                                        />
                                    </div>

                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="paymentReceivedDate"            className="form-label">         Payment (Date)       </label>
                                        <DatePicker
                                            selected={formData.paymentReceivedDate ? new Date(formData.paymentReceivedDate) : null}
                                            onChange={date => {
                                                const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                                                setFormData({...formData, paymentReceivedDate: safeFormat(adjustedDate, 'yyyy-MM-dd')});
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <label htmlFor="fee"           className=" form-label">        Fee             </label>
                                        <input  type="number"   value={formData.fee}            placeholder="Fee"
                                            onChange={e => handleInputChange("fee", e)}
                                        />
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <label htmlFor="finaltotal"             className=" form-label">        Total Amount    </label>
                                        <input type="text"      value={totalPrice}      readOnly    placeholder="Total Amount" />
                                    </div>
                                </div>
                            </>
                        )}

                        <Row>
                            <Col md={6}>
                                <FormButton variants={tileVariants} variant="secondary" className="mt-3 " onClick={handle_CloseInvoiceModal}>
                                    <span>Close</span>
                                </FormButton>
                            
                            </Col>
                            <Col md={6}>
                                <div className="d-flex flex-column align-items-center">
                                    {error && <p className="error-message">{error}</p>}
                                </div>

                                <FormButton variants={tileVariants} variant="primary" className="mt-3 btn-green" onClick={handleSubmitData}>
                                    <span>{isEdit ? 'Update' : 'Create'}</span>
                                </FormButton>
                            </Col>

                        </Row>
                    </InvoiceForm>
                </div>
            </Modal>
        </div>
    );
};

export default ManageBilling;
