/* REAL - MY SUBSCRIPTIONS - PARTS QUOTES   */
import { useTranslation 
        }                                   from 'react-i18next';
import React, { useState, useEffect }       from "react";
import { Button, 
         Form, 
         Table,
         Tooltip, 
         OverlayTrigger, 
         Modal
        }                                   from "react-bootstrap";
import moment                               from "moment";
import { MdDelete }                         from "react-icons/md";
import { FaRegEye, 
        }                                   from "react-icons/fa";
import { useNavigate }                      from "react-router-dom";
import { toast }                            from "react-toastify";
import   BillingVehiclePartsModal           from "../../Modal/BillingVehiclePartsModal";
import { useGetVehicleRequestsByUserIdQuery,
         useDeleteVehiclePartMutation,
         useDeleteOrderMutation,
        }                                   from "../../../services/orderVehiclePartsOrderApi";
import { 
         useCreateInvoiceMutation, 
        }                                   from "../../../services/orderBillingApi";
import   generatePDF, 
        { Resolution, Margin }              from "react-to-pdf";

const SubsPartsQuotes = ({ user }) => {
    const { t } = useTranslation('subsPartsQuotes');
    const navigate                                              = useNavigate();
    const { data, refetch }                                     = useGetVehicleRequestsByUserIdQuery(user?._id);
    const [Billing_CreateInvoice]                               = useCreateInvoiceMutation();
    const [Request_DeleteVehiclePart]                           = useDeleteVehiclePartMutation();
    const [Request_DeleteOrder]                                 = useDeleteOrderMutation();
    const [selectedRow,             setSelectedRow]             = useState({});
    const [show,                    setShow]                    = useState(false);
    const [showDetails,             setShowDetails]             = useState(false);
    const [formArray,               setFormArray]               = useState([]);
    const [isModified,              setIsModified]              = useState(false);
    const [showConfirmDeleteModal,  setShowConfirmDeleteModal]  = useState(false);
    const [showConfirmAcceptModal,  setShowConfirmAcceptModal]  = useState(false);
    const [showCancelQuoteModal,    setShowCancelQuoteModal]    = useState(false);
    const [cancelReason,            setCancelReason]            = useState("");

    const [selectedPartName,        setSelectedPartName]        = useState('');
    const [selectedPartId,          setSelectedPartId]          = useState(null);
    const [billDetail,              setBillDetail]              = useState({});
    const [total,                   setTotal]                   = useState(0); // Add state to hold the total

    const STG_BILLING_FILE_NAME                                 = "ata2go-billing";
    const STG_Date_Format = "YYYY-MM-DD";

    const options = {
        filename:           `${STG_BILLING_FILE_NAME}-${billDetail?.invoiceNumber}.pdf`,
        method:             "save",
        resolution:         Resolution.EXTREME,

        page: {
            margin:         Margin.SMALL,
            format:         "letter",
            orientation:    "portrait",
        },

        canvas: {
            mimeType:       "image/jpeg",
            qualityRatio:   1,
        },

        overrides: {
            pdf:            {  compress: true,  },
            canvas:         {   useCORS: true,  },
        },
    };

    const getTargetElement = () => document.getElementById("container");

    useEffect(() => {
        // Initialize formArray with the selectedRow data
        if (selectedRow?.carParts) {
            setFormArray(selectedRow.carParts);
            calculateTotal(selectedRow.carParts);
        }
    }, [selectedRow]);

    const calculateTotal = (partsArray) => {
        const newTotal = partsArray.reduce((acc, part) => acc + part.subTotal, 0);
        setTotal(newTotal);
    };

    const handleViewStatus = (d) => {
        refetch();
        setSelectedRow(d);
        setShowDetails(true);
    };

    const handleFormArrayChange = (id, field, value) => {
        const updatedFormArray = formArray.map(item =>
            item._id === id ? { ...item, [field]: value } : item
        );
        setFormArray(updatedFormArray);
        setIsModified(true);
    };

    const handle_UpdateRequest = (action) => {
        // handle the update request based on the action
    };

    const handleRemovePart = (id, partName) => {
        setSelectedPartId(id);
        setSelectedPartName(partName);
        setShowConfirmDeleteModal(true);
    };

    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });

    const confirmRemovePart = () => {
        toast.success(t('messages.deleting'));
        //const updatedFormArray = formArray.filter(item => item._id !== selectedPartId);
        const updatedFormArray = formArray.filter(item => item._id !== selectedPartId);
        setFormArray(updatedFormArray);
        calculateTotal(updatedFormArray); // Recalculate total after deleting an item

        Request_DeleteVehiclePart({
            orderId: selectedRow?._id,
            partId: selectedPartId,
            data: {
                auditActions: createAuditAction("update quote")
            },
        });
        setShowConfirmDeleteModal(false);
        if ( total === 0 ) { setSelectedRow({})};
    };

    const handle_DeleteOrder = async (id, cancelReason) => {
        try {
            toast.success(`${t('messages.deleting')} ${id}`);
            const res = await Request_DeleteOrder({ orderId: id, data: { cancelReason } });
            if (res?.data?.status) {
                toast.success(t('messages.quote_deleted_success'));
            } else {
                toast.error(t('messages.quote_deletion_failed'));
            }
        } catch (error) {
            toast.error(t('messages.quote_deletion_error'));
        }
        setShowCancelQuoteModal(false);
    };
    

    const getLastSevenDigits = id => id ? id.slice(-7) : "";

    const handle_AcceptInvoice = () => {
        const carParts = formArray.map(part => ({
            /* This portion we push to Vehicle Modal of Billing */ 
            year:       part.year,
            brand:      part.brand,
            model:      part.model,  
            hold:       part.hold,
            service:    part.partName,
            price:      part.price,
            quantity:   part.quantity,
            subTotal:   part.subTotal,
        }));

        const subTotal = carParts.reduce((acc, part) => acc + parseFloat(part.subTotal), 0);
        const taxTPS = subTotal * 0.05;
        const taxTVQ = subTotal * 0.09975;
        const totalPrice = subTotal + taxTPS + taxTVQ;

        setBillDetail({
            /* This portion we push to Vehicle Modal of Billing */ 
            invoiceNumber:  "VEH",
            date:           selectedRow?.createdAt,
            totalPrice:     totalPrice,
            userAddress:    user?.address,
            userCity:       user?.city,
            userPostCode:   user?.postCode,
            userState:      user?.state,
            firstName:      user?.firstName,
            lastName:       user?.lastName,
            items:          carParts,
            Total:          subTotal,
            taxTPS:         taxTPS,
            taxTVQ:         taxTVQ,
            
            companyName:    user?.companyName,

        });

        setShowConfirmAcceptModal(false);
        setSelectedRow({});
        setShow(true);
        handle_CreateInvoice();
    };

    const handle_CreateInvoice = async () => {
        const tempArr = [{
            itemType:   "VehicleParts",
            item:       selectedRow?._id,
        }];

        const items = formArray.map(item => ({      
            /* This portion we push to Billing in Database */     
            service:    item.partName,
            price:      item.price,
            quantity:   item.quantity,
            total:      item.subTotal
        }));

        const totalPrice      = items.reduce((acc, item) => acc + parseFloat(item.total), 0);
    
        const data = {
            /* This portion we push to Billing in Database */ 
            invoiceNumber:  "VEH",
            user:           user?._id,
            totalPrice:     totalPrice.toFixed(2),
            date:           new Date(),
            dueDate:        moment(new Date()).format(STG_Date_Format),
            items:          tempArr,
            payment:        0,
            balance:        totalPrice.toFixed(2),
            status:         "Submitted",
        };
    
        try {
            const res = await Billing_CreateInvoice(data);
            if (res?.data?.status) {
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
        } catch (error) {
            toast.error("Failed to create invoice");
        }
    }
    
    
    const downloadPdf = async () => {
        
        generatePDF(getTargetElement, options).then(() => {
            toast.success(`${t('messages.parts_invoice_ready')} #: ${billDetail?.invoiceNumber}`);
        });
    };
    

    return (
        <div className="manage-video-page">
            <div className="video-upload-container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mt-4">
                        <h4 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('titles.parts_requested_quotes')}</h4>
                    </div>
                    <div className="d-flex">
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('tooltips.order_parts')}</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/settings?tab=billing")}>  {t('buttons.billing')}    </Button>
                        </OverlayTrigger>
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('tooltips.order_parts')}</Tooltip>}     >
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/subscriptions?tab=parts-order")}>  {t('buttons.order_more_parts')}    </Button>
                        </OverlayTrigger>
                    </div>
                </div>

                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('labels.order_id')}</th>
                            <th>{t('labels.items')}</th>
                            <th>{t('labels.total_price')}</th>
                            <th>{t('labels.status')}</th>
                            <th>{t('labels.submitted_at')}</th>
                            <th>{t('labels.status_updated_at')}</th>
                            <th>{t('labels.actions')}</th>
                        </tr>
                    </thead>
          
                    <tbody>
                        {data?.data?.map((d, index) => {
                            console.log("data:", d);
                            const key = `${d?._id}-${index}`;
                                console.log("Key:", key); // Debugging statement to log the key value
                                return (
                                <tr key={key}>
                                    <td>{d?._id?.slice(-8, -1)}</td>
                                    <td>{d?.carParts?.length}</td>
                                    <td>{d?.total ? `$${d?.total}` : "Pending"}</td>
                                    <td>{d?.status}</td>
                                    <td>{moment(d?.createdAt).format("YYYY-MM-DD, HH:mm:ss")}</td>
                                    <td>{moment(d?.updatedAt).format("YYYY-MM-DD, HH:mm:ss")}</td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-center gx-4">
                                            
                                            <span className="action-icon update" onClick={() => handleViewStatus(d)}>
                                                <span className="ms-2"> <FaRegEye /> </span>
                                            </span>
                                           
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                {/* User Quote */}
                {selectedRow?.userEmail && showDetails && (
                    <>
                        <h4 className="mt-5" style={{ color: 'blue' }}>{t('titles.quote_details')} - [{selectedRow?._id?.slice(-7)}] - {selectedRow?.userEmail}</h4>
                        {Object.entries(formArray.reduce((acc, item) => {
                            const key = `${item.year} - ${item.brand} - ${item.model}${item.vin ? ` - ${item.vin}` : ''}`;
                            if (!acc[key]) { acc[key] = []; }
                            acc[key].push(item);
                            return acc;
                        }, {})).map(([key, items]) => (
                            <div key={key} style={{ marginBottom: "20px" }}>

                                <h5 className="mt-4">{key}</h5>
                                {items[0].additional && items[0].additional.length > 0 && (
                                    <p className="mb-4">{t('labels.additional')}: {items[0].additional}</p>
                                )}


                                {items.map((item, idx) => (
                                    <div key={item._id} className="form-array-border" style={{ marginBottom: "10px" }}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <label htmlFor={`subSystem-partName-${item._id}`} className="form-label">{t('labels.item_number')} #{String(idx + 1).padStart(2, '0')}</label>
                                                <input disabled className="mb-2" type="text" id={`subSystem-partName-${item._id}`} value={`${item.subSystem} - ${item.partName}`}
                                                    style={{ backgroundColor: "#f0f0f0", width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        
                                        {item.notes && (
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <label htmlFor={`notes-${item._id}`} className="form-label">{t('labels.ata_parts_notes')}</label>
                                                    <input className="mb-2" type="text" id={`notes-${item._id}`} value={item.notes}
                                                        style={{ width: "100%" }}
                                                        onChange={e => handleFormArrayChange(item._id, "notes", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        
                                        <div className="row">
                                        <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`quantity-${item._id}`} className="form-label">{t('labels.quantity')}</label>
                                                <input disabled className="mb-2" type="text" id={`quantity-${item._id}`} value={item.quantity}
                                                    style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                                />
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`price-${item._id}`} className="form-label">{t('labels.price')}</label>
                                                <input disabled className="mb-2" type="text" id={`price-${item._id}`} value={item.price}
                                                    style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`qst-${item._id}`} className="form-label">{t('labels.qst')}</label>
                                                <input disabled className="mb-2" type="text" id={`qst-${item._id}`} value={item.qst}
                                                    style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-1" style={{ paddingLeft: "0px" }}>
                                                <label htmlFor={`gst-${item._id}`} className="form-label">{t('labels.gst')}</label>
                                                <input disabled className="mb-2" type="text" id={`gst-${item._id}`} value={item.gst}
                                                    style={{ backgroundColor: "#f0f0f0", marginLeft: "8px", width: "100%" }}
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`hold-${item._id}`} className="form-label">{t('labels.hold')}</label>
                                                <input disabled className="mb-2" type="text" id={`hold-${item._id}`} value={item.hold}
                                                    style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                                />
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-1" style={{ paddingLeft: "0px" }}>
                                                <label htmlFor={`subTotal-${item._id}`} className="form-label">{t('labels.sub_total')}</label>
                                                <input disabled className="mb-2" type="text" id={`subTotal-${item._id}`} value={item.subTotal}
                                                    style={{ backgroundColor: "#f0f0f0", marginLeft: "8px", width: "100%" }}
                                                />
                                            </div>

                                            <div className="d-flex justify-content-center align-items-center">
                                                <Button className="btn danger-btn ms-2" onClick={() => handleRemovePart(item._id, item.partName)}> <MdDelete />  </Button>
                                            </div>

                                        </div>
                                    </div>
                                ))}

                            </div>
                        ))}
                        <p className="mb-4"><strong>{t('titles.total')}: {total.toFixed(2)}</strong></p> {/* Update total value */}
                        <div className="d-flex justify-content-center align-items-center">
                            <Button className="btn btn-danger me-2"     onClick={() => setSelectedRow({})}>{t('buttons.close')}</Button>
                            <Button className="btn btn-primary ms-2"    onClick={() => handle_UpdateRequest("priceSave")} disabled={!isModified} >{t('buttons.reply')}</Button>
                            <Button className="btn btn-secondary ms-2"  onClick={() => setShowConfirmAcceptModal(true)}>{t('buttons.accept_quote')}</Button>
                            <Button className="btn danger-btn ms-2"     onClick={() => setShowCancelQuoteModal(true)}>{t('buttons.cancel_quote')}</Button>
                        </div>
                    </>
                )}
                


                {/* Delete Confirmation Modal */}
                <Modal show={showConfirmDeleteModal} onHide={() => setShowConfirmDeleteModal(false)}>
                    <Modal.Header closeButton>  <Modal.Title>{t('modals.confirm_delete')}</Modal.Title>   </Modal.Header>
                    <Modal.Body>{t('messages.delete_part')}: <strong>{selectedPartName}?</strong></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmDeleteModal(false)}>{t('buttons.cancel')}</Button>
                        <Button variant="danger" onClick={ confirmRemovePart}>{t('buttons.delete')}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Accept Confirmation Modal */}
                <Modal show={showConfirmAcceptModal} onHide={() => setShowConfirmAcceptModal(false)}>
                    <Modal.Header closeButton>  <Modal.Title>{t('modals.accept_quote')}</Modal.Title>    </Modal.Header>
                    <Modal.Body>{t('descriptions.accepting_quote')}<br /> <br />
                        &nbsp;&nbsp;(1) &nbsp; {t('descriptions.receive_invoice')} <strong>{selectedRow?.userEmail}</strong><br />
                        &nbsp;&nbsp;(2) &nbsp; {t('descriptions.download_invoice')}<br />
                        &nbsp;&nbsp;(3) &nbsp; {t('descriptions.agree_payment')} <strong>{total}$</strong> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmAcceptModal(false)}>{t('buttons.cancel')}</Button>
                        <Button variant="primary" onClick={() => handle_AcceptInvoice("priceEmail")}>{t('buttons.accept_and_finalize')}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Cancel Quote Modal */}
                <Modal show={showCancelQuoteModal} onHide={() => setShowCancelQuoteModal(false)}>
                    <Modal.Header closeButton>  <Modal.Title>{t('modals.cancel_quote')}: {selectedRow?._id ? selectedRow._id.slice(-7) : ''}</Modal.Title>    </Modal.Header>
                    <Modal.Body>        
                        <Form>
                            <Form.Group controlId="cancelReason">
                                <Form.Label>{t('descriptions.cancel_reason')}</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    placeholder={t('placeholders.enter_cancel_reason')} 
                                    value={cancelReason} 
                                    onChange={(e) => setCancelReason(e.target.value)} 
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCancelQuoteModal(false)}>{t('buttons.keep_quote')}</Button>
                        <Button className="btn danger-btn ms-2" onClick={() => handle_DeleteOrder(selectedRow._id, cancelReason)}>{t('buttons.delete_this_quote')}</Button>
                    </Modal.Footer>
                </Modal>                 

                <BillingVehiclePartsModal
                    show={show}
                    handleClose={() => setShow(false)}
                    billDetail={billDetail}
                    userId={getLastSevenDigits(user?._id)}
                    downloadPdf={downloadPdf}
                />
      </div>
    </div>
  );
};

export default SubsPartsQuotes;
