/* REAL - DASH - LISTS - PARTS [ Parts-List] */
/* File:  /src/pages/Dashboard/DashboardPages/Services-PartsList/ManagePartsList.js */
import "./ManagePartsList.css";
import React, 
      { useState,
 //       useContext,
       }                            from "react";
import { Button, Form, Modal,
         Tooltip, OverlayTrigger 
       }                            from "react-bootstrap";
import Resizer                      from "react-image-file-resizer";
import { IoIosSave }                from "react-icons/io";
import { BiSolidPencil }            from "react-icons/bi";
import { MdRemoveCircle,
         MdDelete,
         MdFormatListBulletedAdd
       }                            from "react-icons/md";
import { AiTwotoneFileAdd }         from "react-icons/ai";
import { CgListTree }               from "react-icons/cg";
// import { FcViewDetails }            from "react-icons/fc";
import { MdOutlineAddCard }         from "react-icons/md";
import { toast }                    from "react-toastify";
import cloneDeep                    from "lodash/cloneDeep";
import Papa                         from "papaparse";
import {
    useAddVehiclePartMutation,
    useDeleteVehiclePartMutation,
    useGetAllVehiclePartsQuery,
    useUpdateVehiclePartMutation, 
    useUpdateVehiclePartsByIdMutation,
    useSetPublishStateMutation,
}                                   from "../../../../services/listVehiclePartsApi";
//import { UserContext } from "../../../../App";
import DeleteModal                  from "../../../../components/Modal/DeleteModal";

const ManagePartsList = () => {
    const Source_Name           = "MANAGE - LISTS - PARTS [ Parts-List]";
    const [show,                setShow]                = useState(false);
    const [system,              setSystem]              = useState("");
    const [subSystem,           setSubSystem]           = useState("");
    const [vehicleParts,            setVehicleParts]            = useState([]);
    const [tempName,            setTempName]            = useState("");
    const [tempImage,           setTempImage]           = useState("");
    const [selectedSystem,      setSelectedSystem]      = useState({system: "Select System" });
    const [selectedSubSystem,   setSelectedSubSystem]   = useState("Select Sub-System");
    const [selectedItem,        setSelectedItem]        = useState({partName: "Select Vehicle Part" });
    const [addingSystem,        setAddingSystem]        = useState("Select Adding System");
    const [selectedItemString,  setSelectedItemString]  = useState("");
    const [subSystems,          setSubSystems]          = useState([]);
    const [selected,            setSelected]            = useState("");
    const [partName,            setPartName]            = useState("");
    const [partImage,           setPartImage]           = useState("");
    const [csvData,             setCsvData]             = useState("");
    const [deleteModal,         setDeleteModal]         = useState(false);
    const [deleteModal1,        setDeleteModal1]        = useState(false);
    const [isPublished,         setIsPublished]         = useState(false); // New state for publish status
    const [publishAllModal,     setPublishAllModal]     = useState(false); // New state for the publish all modal
    const [globalIsPublished,   setGlobalIsPublished]   = useState(true); // New state for global publish status
    const [items,               setItems]               = useState([]);
    //const { user } = useContext(UserContext);
    const [updateVehiclePart]           = useUpdateVehiclePartMutation();
    const [SetVehicle_Publish]      = useSetPublishStateMutation();
    const [addVehiclePart]              = useAddVehiclePartMutation();
    const [deleteVehiclePart]           = useDeleteVehiclePartMutation();
    const [updateVehiclePartsById]      = useUpdateVehiclePartsByIdMutation();
    const { data }                  = useGetAllVehiclePartsQuery({ source: Source_Name });
    

    // const [deleteModal2, setDeleteModal2] = useState(false);


    const togglePublishStatus = () => {
        const newGlobalIsPublished = !globalIsPublished;
    
        // Update the publish status for all systems
        data?.data?.forEach(system => {
            const systemData = {
                system: system.system,
                published: newGlobalIsPublished // Update the published status
            };
            SetVehicle_Publish({ data: systemData });
        });
    };
    
    
    const handlePublishToggle = () => {
        const updatedSystem = {
            system: selectedSystem.system,
            published: !selectedSystem.published
        };
        SetVehicle_Publish({ data: updatedSystem });
        toast.success(`${updatedSystem.published ? "Published" : "UnPublished"} ${selectedSystem.system}`);
    };
    

    const handlePublishAll = async () => {
        const newPublishStatus = !isPublished;
        for (const system of data?.data || []) {
            // Retrieve the full existing data for the system
            const fullSystemData = {
                system: system.system,
                subSystems: system.subSystems,
                published: newPublishStatus // Update the published status
            };
            console.log("DATA",fullSystemData );
    
           // await updateVehiclePart({id: system._id,    data: fullSystemData    });
        }
        toast.success(`All systems have been ${newPublishStatus ? "Published" : "UnPublished"}`);
        setPublishAllModal(false);
    };
    
    
    const handleCsvInputChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = e => {
            const csvContent = e.target.result;
            setCsvData(csvContent);
        };

        reader.readAsText(file);
    };


    const handleConvertClick = () => {
        Papa.parse(csvData, {
            header: true,
            complete: result => {
                // setJsonData(result.data);
                result.data.pop();
                setVehicleParts(result.data);
                // console.log(result.data)
            },
            error: error => {
                console.error(error.message);
            }
        });
    };


    const handleAddImage = e => {
        Resizer.imageFileResizer(
            e.target.files[0],
            150,
            150,
            "JPEG",
            70,
            0,
            uri => {
                setTempImage(uri);
                setPartImage(uri);
            },
            "base64",
            150,
            150
        );
    };


    const handleAddPart = e => {
        e.preventDefault();
        setVehicleParts([...vehicleParts, { partName: tempName, partImage: tempImage }]);
        setTempName("");
        setTempImage("");
        setPartImage("");
        e.target.reset();
    };


    const handleDeletePart = part => {
        const subParts = vehicleParts?.filter(s => s !== part);
        setVehicleParts(subParts);
    };


    const handleAddSystem = () => {
        const data = {
            system: system,
            subSystem: subSystem,
            vehicleParts: vehicleParts,
            published: isPublished // Include the publish status
        };
        if (system) {
            if (vehicleParts.length > 0) {
                addVehiclePart(data).then(res => {
                    if (res?.data?.status) {
                        toast.success(res?.data?.message);
                        setSystem("");
                        setVehicleParts([]);
                        setSubSystem("");
                        setSelectedSystem({ system: "Select System" });
                        setSelectedSubSystem("Select Sub-System");
                    } else {
                        toast.error(res?.data?.message);
                    }
                });
            } else {
                toast.error("Please add at least one sub-category!");
            }
        } else {
            toast.error("Please add the Category Name!");
        }
    };


    const handleViewCategory = e => {
        const value = e.target.value;
        const selectedSystem = JSON.parse(value);
        setSelectedSystem(selectedSystem);
        setSelected(value);
        setIsPublished(selectedSystem?.published || false); // Set the publish status
        const filteredSystem = data?.data?.filter(d => d?.system === JSON.parse(value)?.system);
        const keysArray = filteredSystem[0]?.subSystems?.map(obj => Object.keys(obj)[0]);
        setSubSystems(keysArray);
        setSelectedSubSystem("Select Sub-System");
    };


    const handleShowItems = e => {
        const value = e.target.value;
        setSelectedSubSystem(value);
        if (value !== "Select Sub-System") {
            setItems(selectedSystem?.subSystems[subSystems.indexOf(value)][value]);
        }
        setSelectedItemString("");
        setSelectedItem({
            partName: "Select Vehicle Part"
        });
    };


    const handleSelectedItem = e => {
        const value = e.target.value;
        setSelectedItem(JSON.parse(value));
        setSelectedItemString(value);
        setPartName(JSON.parse(value)?.partName);
        setPartImage(JSON.parse(value)?.partImage);
    };


    const handleUpdateVehiclePart = task => {
        const tempItem = cloneDeep(selectedSystem); // Perform deep copy here
        let updateItem;
        if (selectedSubSystem !== "Select Sub-System") {
            updateItem = tempItem?.subSystems[subSystems.indexOf(selectedSubSystem)][selectedSubSystem];
        }
        if (task === "update") {
            for (let i of updateItem) {
                if (selectedItem?.partName === i?.partName) {
                    i.partName = partName;
                    i.partImage = partImage;
                }
            }
            const data = {
                system: selectedSystem.system,
                subSystem: selectedSubSystem,
                vehicleParts: updateItem
            };
            if (selectedSystem?.system) {
                if (updateItem.length > 0) {
                    updateVehiclePart({ id: selectedSystem?._id, data: data }).then(res => {
                        if (res?.data?.status) {
                            toast.success(res?.data?.message);
                            setSystem("");
                            setVehicleParts([]);
                            setSubSystem("");
                            setShow(false);
                            setSelectedSystem({ system: "Select System" });
                            setSelectedSubSystem("Select Sub-System");
                            setSelectedItem({ partName: "Select Vehicle Part" });
                        } else {
                            toast.error(res?.data?.message);
                        }
                    });
                } else {
                    toast.error("Please add at least one sub-category!");
                }
            } else {
                toast.error("Please add the Category Name!");
            }
        } else {
            const removedItem = updateItem?.filter(item => item?.partName !== selectedItem?.partName);
            const data = {
                system: selectedSystem.system,
                subSystem: selectedSubSystem,
                vehicleParts: removedItem
            };
            if (selectedSystem?.system) {
                if (updateItem.length > 0) {
                    updateVehiclePart({ id: selectedSystem?._id, data: data }).then(res => {
                        if (res?.data?.status) {
                            toast.success(res?.data?.message);
                            setSystem("");
                            setVehicleParts([]);
                            setSubSystem("");
                            setSelectedSystem({ system: "Select System" });
                            setSelectedSubSystem("Select Sub-System");
                            setSelectedItem({ partName: "Select Vehicle Part" });
                        } else {
                            toast.error(res?.data?.message);
                        }
                    });
                } else {
                    toast.error("Please add at least one sub-category!");
                }
            } else {
                toast.error("Please add the Category Name!");
            }
        }
    };


    const handleRemoveSystem = () => {
        deleteVehiclePart(selectedSystem?._id).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setSystem("");
                setVehicleParts([]);
                setSelectedSubSystem("Select Sub-System");
                setDeleteModal(false);
                setSelectedItem({ partName: "Select Vehicle Part" });
            } else {
                toast.error(res?.data?.message);
            }
        });
    };


    const handleRemoveSubSystem = () => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const removedSubSystems = [...selectedSystem?.subSystems];
        removedSubSystems.splice(subSystems.indexOf(selectedSubSystem), 1);

        updateVehiclePartsById({
            id: selectedSystem?._id,
            data: { subSystems: removedSubSystems }
        }).then(res => {
            if (res?.data?.status) {
                toast.success("Sub-system deleted successfully!");
                setSystem("");
                setVehicleParts([]);
                setSubSystem("");
                setSelectedSystem({ system: "Select System" });
                setSelectedSubSystem("Select Sub-System");
                setSelectedItem({ partName: "Select Vehicle Part" });

                setDeleteModal1(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };



    return (
        <div className="manage-video-page">
            <div className="video-upload-container">
                <div className="video-input-box">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2 className="video-page-title text-center">Manage Vehicle Parts</h2>

                            <label htmlFor="categoryName">  System</label>
                            <div className="input-wrapper mb-3  d-flex align-items-center">    {/*  SYSTEM  */}
                                <input type="text"  id="system"         placeholder="System Name"       value={system}        onChange={e => setSystem(e.target.value)} />
                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">View List</Tooltip>}       >
                                    <button className="extra-btn"> <MdFormatListBulletedAdd /> </button>
                                </OverlayTrigger>
                                
                            </div>


                            <div className="input-wrapper mb-3">    {/*  SUB-SYSTEM  */}
                                <label htmlFor="categoryName">  Sub-System</label>
                                <input type="text"  id="subSystem"      placeholder="Sub-System Name"   value={subSystem}     onChange={e => setSubSystem(e.target.value)} />
                            </div>

                            <div className="input-button mt-3">     {/*  SELECT ADDING SYSTEM  */}
                                <label htmlFor="text">          Select Adding System</label>
                                <Form.Select
                                    className="shadow-none"     name="selectedSystem"                   value={addingSystem}  onChange={e => setAddingSystem(e.target.value)}
                                    aria-label="Default select example"     
                                >
                                    <option value={"Select Adding System"}> Select Adding System</option>
                                    <option value={"from csv"}>             From CSV</option>
                                    <option value={"manual"}>               Manual </option>
                                </Form.Select>
                            </div>

                            <div className="input-wrapper mt-3">            
                                {/*  CHOICE  'FROM CSV'  */}
                                {addingSystem === "from csv" && (
                                    <>
                                        <label htmlFor="title">Add Vehicle-Parts</label>
                                        <input type="file" accept=".csv"    onChange={handleCsvInputChange} />
                                        <button className="extra-btn ms-0"  onClick={handleConvertClick}>    Load    </button>
                                    </>
                                )}
                                {/*  CHOICE  'MANUAL'  */}                                          
                                {addingSystem === "manual" && (             
                                    <>
                                        {" "}
                                        <label htmlFor="title">Add Vehicle-Parts</label>
                                        <form className="d-flex justify-content-start align-items-center" onSubmit={handleAddPart} >
                                            <input  type="text"  id="partName"       placeholder="Part Name"     value={tempName}            onChange={e => setTempName(e.target.value)} />
                                            <input  type="file"  id="subCategory"    placeholder="Part Image"    className="vehicle-part-image"  onChange={handleAddImage}  />
                                            <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Add File</Tooltip>}       >
                                                <button className="extra-btn"> <AiTwotoneFileAdd /> </button>
                                            </OverlayTrigger>
                                            
                                        </form>
                                    </>
                                )}

                                {/*  ADDED SUB-CATEGORIES  */} 
                                <div className="">
                                    <h5 className="mb-3 mt-3">Added Sub-categories</h5>
                                    <div className="row g-0">
                                        {vehicleParts?.length > 0 ? (
                                            <>
                                                {vehicleParts?.map((e, i) => (
                                                    <div key={i} className="col-12 col-md-4 col-lg-4 vehicle-part-style">
                                                        {e?.partImage && <img src={e?.partImage} height={"150px"} width={"100%"} alt="part" />}
                                                        <div className="p-2 pb-0">
                                                            <p className="vehicleParts-name">{e?.partName}</p>
                                                        </div>
                                                        <span onClick={() => handleDeletePart(e)} className="vehicle-parts-remove">
                                                            <MdRemoveCircle />
                                                        </span>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-danger">     <small>No Vehicle Parts Added!</small>          </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/*  BUTTONS 'Add System'   &  UnPublish All */}
                            <div className="input-button mt-3">
                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Add System</Tooltip>}  >
                                    <Button className="success-button me-2"  variant={"success"}   onClick={handleAddSystem}   >   <MdOutlineAddCard />  </Button>
                                </OverlayTrigger>

                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">{globalIsPublished ? "Un-Publish" : "Publish"}</Tooltip>}       >
                                    <Button   
                                        className={globalIsPublished ? "danger-btn" : "success-button"}
                                        variant={globalIsPublished ? "danger" : "success"}
                                        onClick={() => {
                                            if (window.confirm(`About to ${globalIsPublished ? "Un-Publish" : "Publish"} all systems.`)) {
                                                const newGlobalIsPublished = !globalIsPublished;
                                                setGlobalIsPublished(newGlobalIsPublished);
                                                togglePublishStatus();
                                                toast.success(`${globalIsPublished ? "UnPublished" : "Published"} Completed`);
                                            }
                                        }}
                                    >
                                                                            <CgListTree /> 
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            <div className="video-upload-container mt-4">
                <div className="video-input-box">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label htmlFor="text">View Parts Systems</label>
                            <div className="input-wrapper mb-3 d-flex align-items-center">
                                <Form.Select    name="selectedSystem"       value={selected}        aria-label="Default select example"     onChange={handleViewCategory}   >
                                    <option value={JSON.stringify({ system: "Select System" })}>Select System</option>
                                    {data?.data
                                        ?.slice()
                                        .sort((a, b) => a.system.localeCompare(b.system))
                                        .map(d => (     
                                            <option key={d?._id} value={JSON.stringify(d)}>     
                                                {d?.system}     
                                            </option>
                                        ))}
                                </Form.Select>

                                {selectedSystem?.system !== "Select System" && (
                                    <>
                                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">{selectedSystem.published ? "UnPublish" : "Publish"}</Tooltip>}  >
                                        <Button className="extra-btn me-2" variant={selectedSystem.published ? "danger" : "success"}       onClick={handlePublishToggle}   >
                                            <span style={{ color: selectedSystem.published ? "red" : "green" }}>
                                                {selectedSystem.published ? "UnPublish" : "Publish"}
                                            </span>
                                        </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Delete System</Tooltip>}  >
                                        <Button className="danger-btn me-2"     variant="danger"    onClick={() => setDeleteModal(true)}>
                                            <MdDelete />
                                        </Button>
                                    </OverlayTrigger>
                                    </>
                                )}
                            </div>

                            {selectedSystem?.system !== "Select System" && (
                                <>
                                    <label className="mt-3" htmlFor="text">View Sub-Systems</label>
                                    <div className="input-wrapper mb-3 d-flex align-items-center">
                                        <Form.Select    className="shadow-none mb-3"        name="selectedSystem"       value={selectedSubSystem}   onChange={handleShowItems}
                                                        aria-label="Default select example" 
                                        >
                                            <option value={"Select Sub-System"}>Select Sub-System</option>
                                            {subSystems?.map(d => (     <option key={d}     value={d}>                      {d}                 </option>       ))}
                                        </Form.Select>

                                        {selectedSubSystem      !== "Select Sub-System" && (
                                            <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Delete Sub-System</Tooltip>}  >
                                                <Button className="danger-btn me-2"     variant="danger"    onClick={() => setDeleteModal1(true)}>
                                                    <MdDelete />
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                    </div>

                                    {selectedSubSystem !== "Select Sub-System" && (
                                        <>
                                            <label className="mt-3" htmlFor="text">View Vehicle Parts</label>
                                            <div className="input-wrapper mb-3 d-flex align-items-center">
                                                <>
                                                <Form.Select    className="shadow-none mb-3"    name="selectedSystem"       value={selectedItemString}      onChange={handleSelectedItem}
                                                                aria-label="Default select example"
                                                >
                                                    <option value={JSON.stringify({ partName: "Select Vehicle Part" })}>Select Vehicle Parts</option>
                                                    {items?.map(d => (  <option key={d}     value={JSON.stringify(d)}>      {d?.partName}       </option>       ))}
                                                </Form.Select>
                                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Modify Part</Tooltip>}  >
                                                    <Button  className="btn btn-success btn-sm me-2"              onClick={() => setShow(true)} >
                                                        <BiSolidPencil />
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Delete Part</Tooltip>}  >
                                                    <Button  className="danger-btn btn-sm me-2"     variant="danger"   
                                                        onClick={() => {
                                                            if (window.confirm("Do you really want to delete this sub-system?")) {
                                                                handleUpdateVehiclePart("remove");
                                                            }
                                                        }}
                                                    >
                                                        <MdDelete />
                                                    </Button>
                                                </OverlayTrigger>
                                                </>
                                            </div>
                                        </>
                                    )}

                                    <div className="sub-cat-div mt-3">
                                        {selectedItem?.partName !== "Select Vehicle Part" ? (
                                            <>
                                                <div className="col-12 col-md-4 col-lg-4 vehicle-part-style">
                                                    {selectedItem?.partImage && (
                                                        <img src={selectedItem?.partImage} height={"150px"} width={"100%"} alt="part" />
                                                    )}
                                                    <div className="p-2 pb-0">
                                                        <p className="vehicleParts-name">{  selectedItem?.partName}     </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p className="text-danger">     <small>No Vehicle Parts selected!</small>   </p>
                                        )}
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>




            <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <div className="modal-form">
                        <div className="video-input-box-modal">
                            <div className="input-wrapper">
                                <label htmlFor="title">Update Vehicle-Part</label>
                                <form className="d-flex justify-content-start align-items-center">
                                    <input
                                        type="text"     id="partName"       placeholder="Part Name"     value={partName}
                                        onChange={e => setPartName(e.target.value)}
                                    />
                                    <input
                                        type="file"     id="subCategory"    placeholder="Part Image"    className="vehicle-part-image"
                                        onChange={handleAddImage}
                                    />{" "}
                                </form>
                            </div>

                            <div className="input-button mt-3 d-flex justify-content-evenly align-items-center">
                                <Button     className="btn btn-danger" onClick={() => setShow(false)}>
                                    Close
                                </Button>
                                <Button     variant="info"      onClick={() => {    handleUpdateVehiclePart("update");  }}  >
                                    <IoIosSave />
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={publishAllModal} onHide={() => setPublishAllModal(false)} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <h5>Are you sure you want to {isPublished ? "UnPublish" : "Publish"} all systems?</h5>
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="secondary" onClick={() => setPublishAllModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="ms-2" onClick={handlePublishAll}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>



            <DeleteModal
                question={`Do you really want to delete the System   [${selectedSystem?.system}]?`}
                heading={"Delete System"}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDeleteItem={handleRemoveSystem}
                id={"1"}
            ></DeleteModal>

            <DeleteModal
                question={`Do you really delete the Sub-System   [${selectedSubSystem}] ?`}
                heading={"Delete Sub-System"}
                deleteModal={deleteModal1}
                setDeleteModal={setDeleteModal1}
                handleDeleteItem={handleRemoveSubSystem}
                id={"1"}
            ></DeleteModal>
        </div>
    );
};

export default ManagePartsList;
