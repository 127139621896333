/* REAL - DASH - MANAGE - TRANSACTIONS    //Dashboard/Transactions */
/* File: /src/pages/Dashboard/DashboardPages/Services-Transactions/ManageTransactions.js  */
import './ManageTransactions.css';
/* import { Button, 
        Form, 
        Modal,
        Tooltip, OverlayTrigger,
        }                               from "react-bootstrap";
import { UserContext }                  from "../../../../App";
import { toast }                        from "react-toastify"; 
*/
import React, 
       { 
   //     useContext, 
        useState,
       }                                from "react";

import { 
        useGetAllTransactionsQuery, 
//        useUpdateTransactionMutation 
        }                               from "../../../../services/orderTransactionApi";
import moment                           from "moment";
import BootstrapTable                   from "react-bootstrap-table-next";
import filterFactory, 
       { textFilter }                   from "react-bootstrap-table2-filter";

const ManageTransactions = () => {
    const Source_Name           = "MANAGE - TRANSACTIONS";

    const { data: transactionList }         = useGetAllTransactionsQuery({ source: Source_Name });
/*   
    const [updateTransaction]               = useUpdateTransactionMutation();
    const { user }                          = useContext(UserContext); 
    const [isModified,          setIsModified]          = useState(false);
    const [formArray,           setFormArray]           = useState([]);
    const [originalFormArray,   setOriginalFormArray]   = useState([]);
*/   
    const [showDetails,         setShowDetails]         = useState(false);
    const [selectedRow,         setSelectedRow]         = useState({});
    const [currentPage,         setCurrentPage]         = useState(1);
    const [sizePerPage,         setSizePerPage]         = useState(5);


    const STG_DateTime_Format   = "YYYY-MM-DD, HH:mm:ss";

    const momentFormatter = (cell, format = STG_DateTime_Format) => {
        const formattedDate = moment(cell).format(format);
        const [datePart, timePart] = formattedDate.split(", ");
        return (
            <span>  {datePart}  <br />  {timePart}  </span>
        );
    };

    // Flatten the transactions array for each transaction in the transaction list
    const processedData = transactionList?.data?.flatMap(transaction =>
        transaction.transactions.map(trans => ({
            requestNumber:  trans.requestNumber,
            status:         trans.status,
            firstName:      trans.firstName,
            lastName:       trans.lastName,
            address:        trans.address,
            city:           trans.city,
            country:        trans.country,
            phone:          trans.phone,
            sendAmount:     trans.sendAmount,
            receiveAmount:  trans.receiveAmount,
            currency:       trans.currency,
            feesAmount:     trans.feesAmount,
            fxFeesAmount:   trans.fxFeesAmount,
            totalAmount:    trans.totalAmount,
            timestamp:      trans.timestamp,
            updatedAt:      transaction.updatedAt,
        }))
    );

    const adjustTimestampForTimezone = (timestamp) => {
        const date = new Date(timestamp);
        const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return adjustedDate;
    };
    
    console.log("Processed Transaction Data:", processedData);

    // Table Columns
    const columns = [
        { dataField: "requestNumber", text: "Req Nb",             sort: true },
        { dataField: "status",        text: "Status",             sort: true },
        { dataField: "firstName",     text: "First Name",         sort: true, filter: textFilter() },
        { dataField: "lastName",      text: "Last Name",          sort: true, filter: textFilter() },
        { dataField: "address",       text: "Address",            sort: true, filter: textFilter() },
        { dataField: "city",          text: "City",               sort: true, filter: textFilter() },
        { dataField: "country",       text: "Country",            sort: true, filter: textFilter() },
        { dataField: "phone",         text: "Phone",              sort: true, filter: textFilter() },
        { dataField: "sendAmount",    text: "Send Amount",        sort: true, formatter: (cell) => `${cell} $` },
        { dataField: "receiveAmount", text: "Receive Amount",     sort: true, formatter: (cell) => `${cell} $` },
        { dataField: "currency",      text: "Currency",           sort: true, filter: textFilter() },
        { dataField: "feesAmount",    text: "Fees Amount",        sort: true, formatter: (cell) => `${cell} $` },
        { dataField: "fxFeesAmount",  text: "FX Fees Amount",     sort: true, formatter: (cell) => `${cell} $` },
        { dataField: "totalAmount",   text: "Total Amount",       sort: true, formatter: (cell) => `${cell} $` },
        { dataField: "timestamp",     text: "timestamp",          sort: true, formatter: cell => {
            const adjustedTimestamp = adjustTimestampForTimezone(cell);
            return moment(adjustedTimestamp).format(STG_DateTime_Format); // Format the adjusted timestamp
        } },
        { dataField: "updatedAt",     text: "Updated",            sort: true, formatter: cell => momentFormatter(cell, STG_DateTime_Format) },
    ];

    const handle_RowSelect = (row, isSelect, rowIndex, e) => {
        if (isSelect) {
            setSelectedRow(row);
          //  setFormArray([row]);
          //  setOriginalFormArray(JSON.parse(JSON.stringify([row]))); // Save the original form array
            setShowDetails(true); // You can choose to show details if needed
        } else {
            setSelectedRow({});
         //   setFormArray([]);
         //    setOriginalFormArray([]);
            // setIsModified(false);
            setShowDetails(false);
        }
    };

    const selectRow = {
        mode: "radio", // Use radio if you want only one row to be selected at a time
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: handle_RowSelect,
    };

    const CustomPagination = ({
        currentPage = 1,
        sizePerPage = 5,
        totalSize = 0,
        onPageChange,
        onSizePerPageChange,
    }) => {
        const totalPages = Math.ceil(totalSize / sizePerPage);
        const handlePrevPage = () => onPageChange(currentPage - 1, sizePerPage);
        const handleNextPage = () => onPageChange(currentPage + 1, sizePerPage);
        const handleFirstPage = () => onPageChange(1, sizePerPage);
        const handleLastPage = () => onPageChange(totalPages, sizePerPage);
    
        return (
            <div className="d-flex justify-content-between align-items-center mt-3">
                <button onClick={handleFirstPage}   disabled={currentPage === 1}         >   &lt;&lt;   </button>
                <button onClick={handlePrevPage}    disabled={currentPage === 1}         >   &lt;       </button>
                <span> Page {currentPage} of {totalPages} </span>
                <button onClick={handleNextPage}    disabled={currentPage === totalPages}>   &gt;       </button>
                <button onClick={handleLastPage}    disabled={currentPage === totalPages}>   &gt;&gt;    </button>
                <span>
                    Items per page:
                    <select value={sizePerPage}     onChange={(e) => onSizePerPageChange(currentPage, parseInt(e.target.value))}    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                    </select>
                </span>
            </div>
        );
    };

    const onPageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setSizePerPage(sizePerPage);
    };

    const onSizePerPageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setSizePerPage(sizePerPage);
    };

    // Slice the flattened data to show only the current page's entries
    const paginatedData = processedData?.slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);

    // console.log("Transaction List:", transactionList);

    return (
        <div className="manage-transaction-page">
            <div className="transaction-container">

                {/*  TITLE AND BUTTONS ROW  */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0">Transactions ({processedData?.length || 0})</h4>  {/* Add the total count here */}
                </div>

                {/* TABLE */}
                {paginatedData?.length > 0 && (
                    <div className="table-responsive">
                        <BootstrapTable
                            keyField="_id"
                            data={paginatedData}
                            columns={columns}
                            defaultSortDirection="asc"
                            striped
                            hover
                            condensed
                            responsive
                            scrollable
                            selectRow={selectRow}
                            filter={filterFactory()}  // Enable filtering
                            filterPosition="top"
                        />
                        <CustomPagination
                            currentPage={currentPage}
                            sizePerPage={sizePerPage}
                            totalSize={processedData?.length}
                            onPageChange={onPageChange}
                            onSizePerPageChange={onSizePerPageChange}
                        />
                    </div>
                )}

                {/* TRANSACTION DETAILS */}
                {selectedRow?.firstName && showDetails && (
                    <>
                        <h4 className="mt-5">Transaction Details - [{selectedRow?._id?.slice(-7)}] - {selectedRow?.firstName} {selectedRow?.lastName}</h4>
                        <div className="transaction-details">
                            <p><strong>Timestamp:</strong> {selectedRow?.address}</p>
                            <p><strong>Address:</strong> {selectedRow?.address}</p>
                            <p><strong>City:</strong> {selectedRow?.city}</p>
                            <p><strong>Country:</strong> {selectedRow?.country}</p>
                            <p><strong>Phone:</strong> {selectedRow?.phone}</p>
                            <p><strong>Send Amount:</strong> {selectedRow?.sendAmount} {selectedRow?.currency}</p>
                            <p><strong>Receive Amount:</strong> {selectedRow?.receiveAmount} {selectedRow?.currency}</p>
                            <p><strong>Fees Amount:</strong> {selectedRow?.feesAmount} {selectedRow?.currency}</p>
                            <p><strong>FX Fees Amount:</strong> {selectedRow?.fxFeesAmount} {selectedRow?.currency}</p>
                            <p><strong>Total Amount:</strong> {selectedRow?.totalAmount} {selectedRow?.currency}</p>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default ManageTransactions;
