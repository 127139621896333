/* REAL - HOME PAGE - SERVICE - PARTS ORDER */
/* File: /src/pages/Home/ServicesSection/PartsAuto */
import { 
        useTranslation 
        }                                   from 'react-i18next';
import { useNavigate }                      from "react-router-dom";
import { 
        useContext, 
        useState,
        useEffect, 
        }                                   from "react";
import { UserContext }                      from "../../../../App";
import { CarPartsInnerContent,
         RestrictionText,
         PartsOrderButton }                 from "./carPartsStyle";
import { BoldContent, 
         TabContentContainer, 
         TabTitle, 
         TextContent }                      from "../../../Tabs/tabContentStyle";
import { 
        Tooltip, 
        OverlayTrigger 
        }                                   from "react-bootstrap";
import { useGetSubscriptionsMutation }      from "../../../../services/userUserStoreApi";
import SvcAutoPartsModal                    from "../../../Modal/SvcAutoPartsModal";

const SvcPartsAuto = ({ tabLabel, sectionID }) => {
    const { t } = useTranslation('homeServicesPartsAuto');
    const Source_Name           = "HOME PAGE - SERVICE - PARTS ORDER";
    const navigator                                     = useNavigate();
    const { user }                                      = useContext(UserContext);
    const [UserStore_GetSubscriptions]                  = useGetSubscriptionsMutation();
    const [subscriptionData, setSubscriptionData]       = useState(null);
    const [showModal,       setShowModal]               = useState(false);


    useEffect(() => {
        if (user?._id) {
            const data = {
                userIds: [user._id],  // List of user IDs
              };
              
            UserStore_GetSubscriptions({ data, source: Source_Name })
                .then(res => {
                    if (res?.data?.status) {
                        setSubscriptionData(res.data);
                    } else {
                        console.error(res?.data?.message);
                    }
                })
            
        }
    }, [user, UserStore_GetSubscriptions]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <TabContentContainer id={sectionID} className="pt-0">
                <CarPartsInnerContent>
                    <TabTitle className="text-center">{tabLabel}</TabTitle>
                    <TextContent>
                        <p>{t('content.intro')}</p>
                        <p>{t('content.navigation_challenge')}</p>
                    </TextContent>
                    <BoldContent>
                        <p>{t('content.where_we_come_in')}</p>
                    </BoldContent>
                    <TextContent>
                        <p>{t('content.supplier_access')}</p>
                        <p>{t('content.routine_replacements')}</p>
                        
                        <OverlayTrigger 
                            placement="top" 
                            overlay={
                                <Tooltip id="tooltip-save">
                                    {user?._id ? 
                                        (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.vehicleParts) && user.subscriptions.vehicleParts.length > 0) 
                                            ? t('buttons.view_my_orders') 
                                            : t('buttons.request_access'))  
                                        : t('buttons.signin_or_login')
                                    }
                                </Tooltip>
                            }
                        >
                            <PartsOrderButton variant="secondary"
                                onClick={() => 
                                { 
                                    if (!user?._id) {
                                        navigator("/signin"); // Navigate to sign-in page
                                    } else if (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.vehicleParts) && user.subscriptions.vehicleParts.length > 0)) {
                                        navigator("/subscriptions?tab=parts-order"); // Navigate to subscriptions page
                                    } else {
                                        handleShowModal(); // Show modal for requesting a package
                                    }
                                }}    
                            >
                                {user?._id ? 
                                    (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.vehicleParts) && user.subscriptions.vehicleParts.length > 0) 
                                        ? t('buttons.view_my_orders') 
                                        : t('buttons.request_access'))  
                                    : t('buttons.signin_or_login')
                                }
                            </PartsOrderButton>
                        </OverlayTrigger>
                        {!user?._id && <RestrictionText>{t('content.login_to_order')}</RestrictionText>}
                    </TextContent>
                </CarPartsInnerContent>
            </TabContentContainer>

            <SvcAutoPartsModal 
                showModal={showModal} 
                handleCloseModal={handleCloseModal} 
                user={user} 
            />
        </>
    );
};

export default SvcPartsAuto;
