/* File: /src/config/images.js  */ 
// Logos
import ata2goLogo           from "../assets/images/webp/logo.webp";
import ata2goLogo512        from "../assets/images/webp/ata2goLogo_512.webp";
import ata2goLogo1024       from "../assets/images/webp/ata2goLogo_1024.webp";
import ata2goLogoLarge      from "../assets/images/webp/ata2goLogo_large.webp";

import bannerBgImage        from "../assets/images/webp/bannerBgImage.webp"; // not in use
import homeBannerImage      from "../assets/images/webp/homebanner1.webp";
import privacyBgImage       from "../assets/images/webp/privacyBgImage.webp";
import aboutUsSectionImage  from "../assets/images/webp/aboutUsSectionImage.webp";
import pageHeaderBgImage    from "../assets/images/webp/pageHeaderBgImage.webp";
import privacySectionImg    from "../assets/images/webp/privacySectionImg.webp";
import affiliatemarketing   from "../assets/images/webp/affiliatemarketing.webp";
import interact             from "../assets/images/webp/interac-logo.webp";
import wwwInternet          from "../assets/images/webp/wwwInternet.webp";
import vehicleParts         from "../assets/images/webp/vehicleParts.webp";
import stayTuned            from "../assets/images/webp/stayTuned.webp"
import workImage from "../assets/images/img/work-3.jpg";
import AudioLogo from "../assets/images/img/AudioLogo.gif";



// ----- SVG Images -----
// Common
import { ReactComponent as CheckCircleIcon } from "../assets/images/common/checkCircleIcon.svg";
import { ReactComponent as ChevronDownIcon } from "../assets/images/common/chevronDown.svg";
import { ReactComponent as ChevronLeftIcon } from "../assets/images/common/chevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "../assets/images/common/chevronRight.svg";
import { ReactComponent as DownloadIcon } from "../assets/images/common/downloadIcon.svg";
import { ReactComponent as DownScrollBlueIcon } from "../assets/images/common/downScrollBlueIcon.svg";
import { ReactComponent as DownScrollIcon } from "../assets/images/common/downScrollIcon.svg";
import { ReactComponent as EyeOffIcon } from "../assets/images/common/eyeOffIcon.svg";
import { ReactComponent as EyeOnIcon } from "../assets/images/common/eyeOnIcon.svg";
import { ReactComponent as ReturnIcon } from "../assets/images/common/return.svg";
import { ReactComponent as SepratorLine } from "../assets/images/common/sepratorLine.svg";
import { ReactComponent as UploadIcon } from "../assets/images/common/uploadIcon.svg";

// Header
import { ReactComponent as HomeIcon } from "../assets/images/header/home.svg";
import { ReactComponent as LoginIcon } from "../assets/images/header/login.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/header/logout.svg";
import { ReactComponent as SubscriptionsIcon } from "../assets/images/header/user.svg";
import { ReactComponent as UserCircleIcon } from "../assets/images/header/userCircle.svg";
import { ReactComponent as UserIcon } from "../assets/images/header/user.svg";
import { ReactComponent as UserSettingsIcon } from "../assets/images/header/settings.svg";
import { ReactComponent as BillingDollarIcon } from "../assets/images/header/dollar.svg";

// Contact Section
import { ReactComponent as CallIcon } from "../assets/images/callIcon.svg";
import { ReactComponent as LocationIcon } from "../assets/images/locationIcon.svg";
import { ReactComponent as MailIcon } from "../assets/images/mailIcon.svg";

//Social Media
import { ReactComponent as FacebookIcon } from "../assets/images/socialMedia/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/images/socialMedia/instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/images/socialMedia/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../assets/images/socialMedia/youtube.svg";

//Services Media
import { ReactComponent as HomeInternetIcon } from "../assets/images/services/homeInternetIcon.svg";
import { ReactComponent as CarIcon } from "../assets/images/services/carIcon.svg";
import { ReactComponent as CourseIcon } from "../assets/images/services/courseIcon.svg";
import { ReactComponent as MarketingBGSVGImg } from "../assets/images/services/marketingBGImg.svg";
import { ReactComponent as MarketingIcon } from "../assets/images/services/marketingIcon.svg";

//Documents
import DocIdCanadaNationalId    from "../assets/images/img/documents/DocId-Canada-National-id.png.webp";
import DocIdCanadaPassport      from "../assets/images/img/documents/DocId-Canada-Passport.jpg";
import DocIdCanadaPermResident  from "../assets/images/img/documents/DocId-Canada-PermResident.jpg";
import DocIdQCDriversLicense    from "../assets/images/img/documents/DocId-QC-DriversLicense.jpg";
import DocIdRAMQ                from "../assets/images/img/documents/DocId-RAMQ.jpg.webp";



//Flags
import FlagBahamas              from "../assets/images/flags/flags-bs-bahamas.svg";
import FlagCanada               from "../assets/images/flags/flags-ca-canada.svg";
import FlagDominicanRepublic    from "../assets/images/flags/flags-do-dominicanRepublic.svg";
import FlagHaiti                from "../assets/images/flags/flags-ht-haiti.svg";
import FlagTurksAndCaicos       from "../assets/images/flags/flags-tc-turkAndCaicos.svg";
import FlagUnitedStates         from "../assets/images/flags/flags-us-unitedStates.svg";

/* File: /src/config/images.js  */ 

import FlagCountryBahamas              from "../assets/images/flags_svgPng/flag_of_the_Bahamas.svg.png";
import FlagCountryCanada               from "../assets/images/flags_svgPng/flag_of_Canada.svg.png";
import FlagCountryDominicanRepublic    from "../assets/images/flags_svgPng/flag_of_the_Dominican_Republic.svg.png";
import FlagCountryHaiti                from "../assets/images/flags_svgPng/flag_of_Haiti.svg.png";
import FlagCountryTurksAndCaicos       from "../assets/images/flags_svgPng/flag_of_the_Turks_and_Caicos_Islands.svg.png";
import FlagCountryUnitedStates         from "../assets/images/flags_svgPng/flag_of_the_United_States.svg.png";


import afficialtemarketingVactor from "../assets/images/webp/afficialtemarketingVactor.webp";
import carPartsSectionBg from "../assets/images/webp/carPartsSectionBg.webp";
import userProfileComment from "../assets/images/img/user-profile-comment.png";

import AuthenticatorAuthy from "../assets/images/img/Authenticator-Authy.png";
import AuthenticatorGoogle from "../assets/images/img/Authenticator-Google.png";
import AuthenticatorMicrosoft from "../assets/images/img/Authenticator-Microsoft.png";



export const SVGImages = {
    CallIcon,
    CarIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CourseIcon,
    DownloadIcon,
    DownScrollBlueIcon,
    DownScrollIcon,
    EyeOffIcon,
    EyeOnIcon,
    FacebookIcon,
    HomeIcon,
    HomeInternetIcon,
    InstagramIcon,
    LocationIcon,
    LoginIcon,
    LogoutIcon,
    MailIcon,
    MarketingBGSVGImg,
    MarketingIcon,
    ReturnIcon,
    SepratorLine,
    SubscriptionsIcon,
    TwitterIcon,
    UploadIcon,
    UserCircleIcon,
    UserIcon,
    UserSettingsIcon,
    BillingDollarIcon,
    YoutubeIcon,
    FlagBahamas,
    FlagCanada,
    FlagDominicanRepublic,
    FlagHaiti,
    FlagTurksAndCaicos,
    FlagUnitedStates,
};

export const FlagImages = {
    FlagCountryBahamas,
    FlagCountryCanada,
    FlagCountryDominicanRepublic,
    FlagCountryHaiti,
    FlagCountryTurksAndCaicos,
    FlagCountryUnitedStates,
};

export const Images = {
    aboutUsSectionImage,
    afficialtemarketingVactor,
    affiliatemarketing,
    ata2goLogo,
    ata2goLogo1024,
    ata2goLogo512,
    ata2goLogoLarge,
    bannerBgImage,
    carPartsSectionBg,
    homeBannerImage,
    interact,
    pageHeaderBgImage,
    privacyBgImage,
    privacySectionImg,
    userProfileComment,
    AuthenticatorAuthy,
    AuthenticatorGoogle,
    AuthenticatorMicrosoft,
    wwwInternet,
    vehicleParts,
    AudioLogo,
    DocIdCanadaNationalId,
    DocIdCanadaPassport,
    DocIdCanadaPermResident,
    DocIdQCDriversLicense,
    DocIdRAMQ,
    stayTuned,
    workImage
};
