/* REAL - SETTINGS - BILLING  */
/* File: /src/pages/Settings/Billing/index.jsx  */
import { useTranslation 
}                                               from 'react-i18next';
import                                              "./billing.css";
import                                              './JJInvoiceModal.css';
import moment                                   from "moment";  
import { useNavigate }                          from "react-router-dom";
import React, 
       { useState, 
        useEffect, 
        useContext 
        }                                       from "react";

import { Button, 
         Tooltip, 
         OverlayTrigger 
        }                                       from "react-bootstrap";
import { toast }                                from "react-toastify";

import { FaRegEye }                             from "react-icons/fa";
import { TabContentContainer, 
         TableContainer 
       }                                        from "../../Tabs/tabContentStyle";
import TableComponent                           from "../../../components/UI/TableComponent";
import { staggerVariants, 
         titleVariants 
        }                                       from "../../../utils/FmVariants";
import { useGetBillsByUserQuery,
         useGetAllBillingsQuery 
       }                                        from "../../../services/orderBillingApi";
import { UserContext }                          from "../../../App";
import   BillingModal                           from "../../Modal/BillingModal";
import   BillingVehiclePartsModal               from "../../Modal/BillingVehiclePartsModal";
import   generatePDF, 
        { Resolution, Margin }                  from "react-to-pdf";


const SetBilling = () => {
    const { t } = useTranslation('settingsBilling');
    const STG_BILLING_FILE_NAME         = "ata2go-billing";
    const STG_TPS_RATE                  = 0.05;
    const STG_TVQ_RATE                  = 0.09975;
    const navigator                     = useNavigate();
    const { user }                      = useContext(UserContext);

    // Executing both queries and depending on logged in user will select 'data'
    // const { data: billingListUser }     = useGetBillsByUserQuery(user?._id);
    const { data: billingListUser }     = useGetBillsByUserQuery({ userId: user?._id, source: "SETTINGS - BILLING" });

    const { data: billingListAll } = useGetAllBillingsQuery(undefined, {
        skip: user?.email !== "support@ata2go.com", // Skip if the condition is not met
    });

    const billingList                   = user?.email === "support@ata2go.com" ? billingListAll : billingListUser;
    
    const [show,        setShow]        = useState(false);
    const [billDetail,  setBillDetail]  = useState([]);
    const [billList,    setBillList]    = useState([]);
    const [userId,      setUserId]      = useState("");

    const options = {
        filename:           `${STG_BILLING_FILE_NAME}-${billDetail?.invoiceNumber?.slice(-7)}.pdf`, 
  
        method:             "save",
        resolution:         Resolution.EXTREME,

        page: {
            margin:         Margin.SMALL,
            format:         "letter",
            orientation:    "portrait",
        },

        canvas: {
            mimeType:       "image/jpeg",
            qualityRatio:   1,
        },

        overrides: {
            pdf:            {  compress: true,  },
            canvas:         {   useCORS: true,  },
        },
    };

    const getTargetElement = () => document.getElementById("container");

    const downloadPdf = () => {
        generatePDF(getTargetElement, options).then(() => {
            toast.success("Invoice ready");
        });
    };  

    const getTotal          = (price, qnty = 1) => price * qnty * (1 + STG_TPS_RATE + STG_TVQ_RATE);

    const getFirstName = user => Array.isArray(user) && user.length > 0 ? user[0].firstName : "";

    const getLastName = user => Array.isArray(user) && user.length > 0 ? user[0].lastName : "";
    
    const getAddress = user => Array.isArray(user) && user.length > 0 ? user[0].address : "";
    
    const getCompanyName = user => Array.isArray(user) && user.length > 0 ? user[0].companyName : "";
    
    const getCity = user => Array.isArray(user) && user.length > 0 ? user[0].city : "";
    
    const getPostCode = user => Array.isArray(user) && user.length > 0 ? user[0].postCode : "";
    
    const getState = user => Array.isArray(user) && user.length > 0 ? user[0].state : "";
    

    const getTaxTPS         = amount => amount * 0.05;

    const getTaxTVQ         = amount => amount * 0.09975;

    const getLastSevenDigits = id => id ? id.slice(-7) : "";

    useEffect(() => {
        if (billingList?.data) { 
            const newBillingList = [];

            billingList.data.forEach(billing => {
                const product = [];
                billing.items.forEach(item => {
                    /* The 'forEach' are from the Billing.Controllers */
                    if (item?.itemType === "learning") {
                        billing?.courses?.forEach(courses => {
                            if (courses._id === item.item) {
                                product.push({
                                    service:    courses.courseName + " - " + courses.courseLevel,
                                    price:      courses?.price,
                                    quantity:   item.quantity,
                                    subTotal:      getTotal(courses?.price, item.quantity),
                                });
                            }
                        });
                    } 
                    if (item?.itemType === "internet") {
                        billing?.internets?.forEach(internets => {
                            if (internets._id === item.item) {
                                product.push({
                                    service:    "Internet: " + internets.downloadSpeed  + " / " + internets.uploadSpeed + " Mbps",
                                    price:      internets?.price,
                                    quantity:   item.quantity,
                                    subTotal:      getTotal(internets?.price, item.quantity),
                                });
                            }
                        });
                    }
                    if (item?.itemType === "vehicleParts") {
                        billing?.carpartsorders?.forEach(carpartsorders => {
                            if (carpartsorders._id === item.item) {
                                carpartsorders.carParts.forEach(part => {
                                    /* This portion we push to Vehicle Modal of Billing */ 
                                    product.push({
                                        year:       part.year,
                                        brand:      part.brand,
                                        model:      part.model,
                                        service:    part.partName,
                                        price:      part.price,
                                        quantity:   part.quantity,
                                        subTotal:   part.subTotal,
                                        hold:       part.hold,
                                    });
                                });
                            }
                        });
                    }
                    
                });

                const allTotal = product.reduce((acc, item) => acc + item.subTotal, 0);
                
                newBillingList.push({
                    /* This portion we push to Vehicle & Services Modal of Billing */ 
                    invoiceNumber:  billing.invoiceNumber,
                    date:           billing.date,
                    totalPrice:     billing.totalPrice,
                    userAddress:    getAddress(billing.users),
                    userCity:       getCity(billing.users),
                    userPostCode:   getPostCode(billing.users),
                    userState:      getState(billing.users),
                    firstName:      getFirstName(billing.users),
                    lastName:       getLastName(billing.users),
                    items:          product,
                    Total:          allTotal,
                    taxTPS:         getTaxTPS(allTotal),
                    taxTVQ:         getTaxTVQ(allTotal),
                    user:           billing.user,
                    companyName:    getCompanyName(billing.users),
                    paymentReceived: Array.isArray(billing.paymentReceived)
                        ? billing.paymentReceived.map(payment => ({
                            date: payment.date,
                            amount: payment.amount
                        }))
                        : [],
                    totalPayments:          billing.totalPayments,
                    lastBillBalance:        billing.lastBillBalance,
                    balanceBroughtForward : billing.balanceBroughtForward,
                    status: billing.status,
                });
            });
            if (user?.role === "admin") {
                setBillList(newBillingList);
            } else {
                const billList = newBillingList.filter(item => item.user === user?._id);
                setBillList(billList);
            }
        }
    }, [billingList, user]);

    return (
        <>
            <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                        <Button variant="primary" className="ms-2" onClick={() => navigator("/subscriptions?tab=parts-quotes")}>
                            {t('buttons.parts_quotes')}
                        </Button>
                        <Button variant="primary" className="ms-2" onClick={() => navigator("/subscriptions")}>
                            {t('buttons.my_subscriptions')}
                        </Button>
                    </div>
                    <TableComponent variants={titleVariants} isDataTable={+false}>
                        <thead>
                            <tr>
                                <th>{t('table_headers.id')}</th>
                                <th>{t('table_headers.invoice_number')}</th>
                                <th>{t('table_headers.description')}</th>
                                <th>{t('table_headers.date')}</th>
                                <th>{t('table_headers.total')}</th>
                                <th>{t('table_headers.details')}</th>
                                <th>{t('table_headers.status')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {billList?.map((data, i) => (
                                <>
                                    <tr key={i + 1}>
                                        {/* ID */}
                                        <td> {i < 100 ? `00${i + 1}` : `${i}`} </td>

                                        {/* Invoice Number */}
                                        <td> {data?.invoiceNumber} </td>

                                        {/* Description */}
                                        <td>{data?.items?.map(item => item.service).join(', ')}</td>

                                        {/* Date */}
                                        <td> {moment(data?.date).format('YYYY-MM-DD')}</td>

                                        {/* Total */}
                                        <td> ${data?.totalPrice?.toFixed(2)} </td>

                                        {/* Details */}
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-view">{t('tooltips.view_bill')}</Tooltip>}>
                                            <td style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    const paymentDetails = data.paymentReceived?.[0] || { date: null, amount: null };
                                                    setBillDetail({
                                                        ...data,
                                                        paymentDate: paymentDetails.date,
                                                        paymentAmount: paymentDetails.amount,
                                                    });
                                                    setUserId(getLastSevenDigits(data.user));
                                                    setShow(true);
                                                }}
                                            >
                                                <FaRegEye />
                                            </td>
                                        </OverlayTrigger>

                                        {/* Status */}
                                        <td>{data.status}</td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </TableComponent>
                </TableContainer>
            </TabContentContainer>

            {billDetail?.invoiceNumber?.startsWith('INV') ? (
                <BillingModal
                    show={show}
                    handleClose={() => setShow(false)}
                    billDetail={billDetail}
                    userId={userId}
                    downloadPdf={downloadPdf}
                />
            ) : (
                <BillingVehiclePartsModal
                    show={show}
                    handleClose={() => setShow(false)}
                    billDetail={billDetail}
                    userId={userId}
                    downloadPdf={downloadPdf}
                />
            )}

            <p>
                <strong>{t('modals.payable_to')}</strong><br /><br />
                {t('modals.etransfer_note')}<br /><br />
            </p>
        </>
    );
};

export default SetBilling;


