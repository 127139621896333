/* REAL - ROUTING URLS - Point to Pages */
/* File: /src/App.js */

import                                                   "bootstrap/dist/css/bootstrap.min.css";
import                                                   "./App.scss";
import React, 
       { 
        createContext, 
        useEffect, 
        useState 
        }                                           from "react";
import { 
        toast, 
        ToastContainer 
        }                                           from "react-toastify";
import                                                   "react-toastify/dist/ReactToastify.css";
import { 
        Route, 
        Routes, 
        useLocation 
        }                                           from "react-router-dom";
import { Image }                                    from "react-bootstrap";
import { Images }                                   from "./config/images";
import Header                                       from "./pages/Header";
import useTokenExpiryCheck                          from "./hooks/useTokenExpiryCheck";
import ScrollToHashElement                          from "./components/UI/ScrollToHashElement";

/* DASHBOARD =================================== */
import ManageAffiliate                              from "./pages/Dashboard/DashboardPages/Users-Affiliates/ManageAffiliates";
import ManageUser                                   from "./pages/Dashboard/DashboardPages/Users-All/ManageUser";

import ManageBilling                                from "./pages/Dashboard/DashboardPages/Billing-Invoices/ManageBilling";

import ManagePartsRequests                          from "./pages/Dashboard/DashboardPages/Manage-PartsRequests/ManagePartsRequests";
import ManageUserDocuments                          from "./pages/Dashboard/DashboardPages/Manage-UserDocs/ManageUserDocuments";
import ManageTransactions                           from "./pages/Dashboard/DashboardPages/Manage-Transactions/ManageTransactions";
import ManageVideos                                 from "./pages/Dashboard/DashboardPages/Manage-Promos/ManageVideoPromos";

import ManageMarketings                             from "./pages/Dashboard/DashboardPages/Services-Marketings/Marketings";
import ManageInternet                               from "./pages/Dashboard/DashboardPages/Services-Internet/HomeInternet";
import ManageVehiclePart                            from "./pages/Dashboard/DashboardPages/Services-VehiclePart/VehiclePart";
import ManageLearning                               from "./pages/Dashboard/DashboardPages/Services-Learning/Learning";
import ManageTranscripts                            from "./pages/Dashboard/DashboardPages/Services-Transcripts/Transcripts";
import ManageRemittances                            from "./pages/Dashboard/DashboardPages/Services-Remittances/Remittances";

import ManageInterests                              from "./pages/Dashboard/DashboardPages/Lists-Interests/ManageInterests";
import ManagePartsList                              from "./pages/Dashboard/DashboardPages/Lists-Parts/ManagePartsList";
import ManageVehicles                               from "./pages/Dashboard/DashboardPages/Lists-Vehicle/ManageVehicle";

import ManageAdminSettings                          from "./pages/Dashboard/DashboardPages/Admin-Settings/ManageAdminSettings";

/* OTHER  =================================== */
import UpdateProfile                                from "./pages/Dashboard/DashboardPages/UpdateProfile/UpdateProfile";
import ManageOrder                                  from "./pages/Dashboard/DashboardPages/ManageOrders/ManageOrder";
import ManageServices                               from "./pages/Dashboard/DashboardPages/ManageServices/ManageServices";


/* AUTHORIZATION REQUIREMENT =================================== */
import RequiredAffiliate                            from "./components/Authentication/RequiredAffiliate";
import RequiredDashboard                            from "./components/Authentication/RequiredDashboard";
import RequiredDashboardVideo                       from "./components/Authentication/RequiredDashboardVideo";
import RequiredPkg                                  from "./components/Authentication/RequiredPkg";
import RequiredUser                                 from "./components/Authentication/RequiredUser";
import RequiredUsersAuth                            from "./components/Authentication/RequiredUsersAuth";

/* ************************ =================================== */
import { useAutoLoginQuery  }                       from "./services/userUserApi";
import PrivacyPage                                  from "./pages/privacyPage/PrivacyPage";
import TermsServicePage                             from "./pages/TermsServicesPage/TermsServicePage";

import VerifyEmail                                  from "./pages/Auth/VerifyEmail/VerifyEmail";
import DashboardLayout                              from "./pages/Dashboard/DashboardLayout/DashboardLayout";

const Home                      = React.lazy(() => import("./pages/Home/index"));
const SiteSignInPage            = React.lazy(() => import("./pages/Auth/SignIn/index"));
const SignUp                    = React.lazy(() => import("./pages/Auth/SignUp"));
const ForgotPassword            = React.lazy(() => import("./pages/Auth/ForgotPassword"));
const Settings                  = React.lazy(() => import("./pages/Settings"));
const Subscriptions             = React.lazy(() => import("./pages/Subscriptions"));
const ResetPassword             = React.lazy(() => import("./pages/Auth/ForgotPassword/ResetPassword"));
const NewPassword               = React.lazy(() => import("./pages/Auth/ForgotPassword/NewPassword"));
const Page404                   = React.lazy(() => import("./pages/404Page"));
const MyPromo                   = React.lazy(() => import("./pages/MyPromo"));

export const UserContext        = createContext();
export const ServiceContext     = createContext();

function App() {
    const Source_Name                   = "ROUTING URLS - App.js";
    const [user,        setUser]        = useState(null);
    const [refetch,     setRefetch]     = useState(0);
    const [loading,     setLoading]     = useState(false);
    const [show,        setShow]        = useState(false);
    const [serviceId,   setServiceId]   = useState(1);
    // eslint-disable-next-line 
    const [token,       setToken]       = useState(localStorage.getItem("token"));
    const location                      = useLocation();
    const handleClose                   = () => setShow(false);
    const handleShow                    = () => setShow(true);
    const currentPath                   = location.pathname;
    const commonProps                   = { show, handleShow, handleClose };
    const { data, error, isLoading: queryLoading } = useAutoLoginQuery(
        { source: Source_Name },
        { skip: !token, refetchOnMountOrArgChange: refetch }
    );

    useTokenExpiryCheck();


    /* Section 01.00 - useEffect -  [token, data, error, refetch] */
    useEffect(() => {
        if (token) {
            console.log("[APP.js] - Getting autoLogin, with token", token);
            setLoading(true);

            if (data) {
                console.log("[APP.js] - autoLogin data:", data);
                setUser(data.result);
            }
            if (error) {
                console.error("[APP.js] - autoLogin error:", error);
                if (error.status === "FETCH_ERROR") {
                    toast.error("Could not Fetch from Server. Check connectivity!");
                }
            }
            setLoading(false);
        }
    }, [token, data, error, refetch]);


    if (queryLoading || loading) {
        return <div>Loading...</div>;
    }


    return (
        <div className="App">
            <ServiceContext.Provider value={{ serviceId, setServiceId }}>
                <UserContext.Provider value={{ user, setUser, refetch, setRefetch, loading }}>
                    <ScrollToHashElement />
                    {!currentPath.split("/").includes("dashboard") && <Header />}
                    {/* <Layout> */}
                    <Routes>
                        <Route path="/"                     element={<LazySuspenseRoute>                        <Home />                                </LazySuspenseRoute>} />
                        <Route path="/signin"               element={<LazySuspenseRoute>                        <SiteSignInPage />                      </LazySuspenseRoute>} />
                        <Route path="/signup"               element={<LazySuspenseRoute>                        <SignUp />                              </LazySuspenseRoute>} />
                        <Route path="/forget-password"      element={<LazySuspenseRoute>                        <ForgotPassword />                      </LazySuspenseRoute>} />
                        <Route path="/verify-email"         element={<LazySuspenseRoute>                        <VerifyEmail />                         </LazySuspenseRoute>} />
                        <Route path="/verify-code/:email"   element={<LazySuspenseRoute>                        <ResetPassword />                       </LazySuspenseRoute>} />
                        <Route path="/update-pass/:id"      element={<LazySuspenseRoute>                        <NewPassword />                         </LazySuspenseRoute>} />
                        <Route path="/settings"             element={<LazySuspenseRoute>    <RequiredUser>      <Settings />        </RequiredUser>     </LazySuspenseRoute>} />
                        <Route path="/subscriptions"        element={<LazySuspenseRoute>    <RequiredUser>      <Subscriptions />   </RequiredUser>     </LazySuspenseRoute>} />
                        <Route path="/videos"               element={<LazySuspenseRoute>    <RequiredUser>      <MyPromo />         </RequiredUser>     </LazySuspenseRoute>} />
                        <Route path='/privacy'              element={<LazySuspenseRoute>                        <PrivacyPage />                         </LazySuspenseRoute>} />
                        <Route path='/terms-services'       element={<LazySuspenseRoute>                        <TermsServicePage />                    </LazySuspenseRoute>} />  
                        {/* dashboard Routes */}
                        <Route
                            path="/dashboard"               element={<LazySuspenseRoute>    <RequiredDashboard> <DashboardLayout /> </RequiredDashboard> </LazySuspenseRoute> }>
                            <Route path="user"              element={<LazySuspenseRoute>    <RequiredUsersAuth> <ManageUser         {...commonProps} /> </RequiredUsersAuth> </LazySuspenseRoute>} />
                            <Route path="billing"           element={<LazySuspenseRoute>    <RequiredUsersAuth> <ManageBilling      {...commonProps} /> </RequiredUsersAuth> </LazySuspenseRoute>} />
                            <Route path="transactions"      element={<LazySuspenseRoute>    <RequiredUsersAuth> <ManageTransactions {...commonProps} /> </RequiredUsersAuth> </LazySuspenseRoute>} />                     
                            <Route path="affiliate"         element={                       <RequiredAffiliate> <ManageAffiliate />             </RequiredAffiliate>} />
                            <Route path="video-upload"      element={                       <RequiredDashboardVideo> <ManageVideos />           </RequiredDashboardVideo>} />
                            <Route path="manage-category"   element={                       <RequiredPkg>       <ManageInterests />             </RequiredPkg>} />
                            <Route path="service"           element={                       <RequiredPkg>       <ManageServices />              </RequiredPkg>} />
                            <Route path="orders"            element={                       <RequiredPkg>       <ManageOrder />                 </RequiredPkg>} />
                            <Route path="internet"          element={                       <RequiredPkg>       <ManageInternet />              </RequiredPkg>} />
                            <Route path="vehiclePart"       element={                       <RequiredPkg>       <ManageVehiclePart />           </RequiredPkg>} />
                            <Route path="manage-userDoc"    element={                       <RequiredPkg>       <ManageUserDocuments />         </RequiredPkg>} />
                            <Route path="technology"        element={                       <RequiredPkg>       <ManageLearning />              </RequiredPkg>} />
                            <Route path="remittance"        element={                       <RequiredPkg>       <ManageRemittances />           </RequiredPkg>} />
                            <Route path="transcript"        element={                       <RequiredPkg>       <ManageTranscripts />           </RequiredPkg>} />
                            <Route path="marketing"         element={                       <RequiredPkg>       <ManageMarketings />            </RequiredPkg>} />
                            <Route path="vehicle-parts"     element={                       <RequiredDashboardVideo> <ManagePartsList />        </RequiredDashboardVideo>} />
                            <Route path="manage-cars"       element={                       <RequiredDashboardVideo> <ManageVehicles />         </RequiredDashboardVideo>} />
                            <Route path="sudo-profile/:id"  element={                       <RequiredDashboardVideo> <UpdateProfile />          </RequiredDashboardVideo>} />
                            <Route path="vehicle-parts-req" element={                       <RequiredDashboardVideo> <ManagePartsRequests />    </RequiredDashboardVideo>} />
                            <Route path="admin-settings"    element={                       <RequiredDashboardVideo> <ManageAdminSettings />    </RequiredDashboardVideo>} />
                        </Route>
                        <Route path="*"                     element={<LazySuspenseRoute>                        <Page404 />                             </LazySuspenseRoute>} />

                    </Routes>
                </UserContext.Provider>
            </ServiceContext.Provider>
            <ToastContainer />
        </div>
    );
}

const LazySuspenseRoute = ({ children }) => {
    return (
        <React.Suspense fallback={<SiteLoader message="Loading content..." />}>
            {children}
        </React.Suspense>
    );
};




export const SiteLoader = () => {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <div className="logoBox">
                <Image src={Images.ata2goLogo} width={180} fluid />
            </div>
        </div>
    );
};

export default App;
