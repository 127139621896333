/* REAL - SIDEBAR LEFT SIDE */
import                              "../DashboardLayout/DashboardLayout.css";
import logo                         from "../../../assets/images/ata2goLogo.png";
import { UserContext }              from "../../../App";
import { DiTechcrunch }             from "react-icons/di";
import { NavLink, 
        useNavigate 
        }                           from "react-router-dom";
import { BiCategory }               from "react-icons/bi";
import React, 
        { 
            useContext, 
            useEffect, 
            useState 
        }                           from "react";
import { FaBars, 
        FaUserAlt, 
        FaSignOutAlt, 
        FaVideo, 
        FaWifi, 
        FaUsers,
        FaHome, 
        FaTools, 
        FaTruckPickup, 
        FaList, 
        FaReceipt 
        }                           from "react-icons/fa";
import { RiSettings4Line,
         RiMoneyDollarCircleLine,
        }                           from "react-icons/ri";
import { TbWriting }                from "react-icons/tb";
import { 
        GoChevronDown,
        GoChevronRight,
        }                           from "react-icons/go";
import { GiAcousticMegaphone }      from "react-icons/gi";


const Sidebar = () => {
    const [isOpen, setIsOpen]   = useState(true);
    const [activeAccordion, setActiveAccordion] = useState(null); // Add state for active accordion
    const { user, setUser }     = useContext(UserContext);
    const navigate              = useNavigate();
    const toggle                = () => setIsOpen(!isOpen);
    const hasRole               = (roles) => roles.includes(user?.role);

    /* Section 01.00 - useEffect - []  */
    useEffect(() => {
        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if (isMobileDevice) {
            setIsOpen(false); // Set to false for mobile devices
        }
    }, []);

    /* Section 02.00 - handleLogout  */
    const handleLogout  = () => {
        const confirmed = window.confirm("Are you sure you want to log out?");
        if (confirmed) {
            localStorage.removeItem("token"); // Clear user session or token
            setUser({});                      // Reset user state
            navigate("/");                    // Redirect to the homepage or login page
        } else {
            // If user canceled, do nothing
            return;
        }
    };
    
    /* Section 03.00 - NavItem  */
    const NavItem = ({ to, icon: Icon, text, isOpen }) => (
        <NavLink  to={to}  className={({ isActive }) => isActive ? "dashboard-link active" : "dashboard-link"}>
            <div className="icon"><Icon /></div>
            {isOpen && <div className="link_text">{text}</div>}
        </NavLink>
    );
      
    /* Section 04.00 - NavItemLogout  */
    const NavItemLogout = ({ icon: Icon, text, isOpen, onClick }) => {
        return (
          <div className="dashboard-link-last" onClick={onClick}>
            <div className="icon"><Icon /></div>
            {isOpen && <div className="link_text">{text}</div>}
          </div>
        );
      };

    /* Section 05.00 - Accordion  */
    const Accordion = ({ title, children, isOpen }) => {
        const isActive = activeAccordion === title;
        const toggleAccordion = () => setActiveAccordion(isActive ? null : title);

        return (
            <div className={`accordion-item ${isActive ? 'active' : ''}`}>
                <div className="accordion-title" onClick={toggleAccordion}>
                    {title} {isActive ? <GoChevronDown /> : <GoChevronRight />}
                </div>
                {isActive && <div>{children}</div>}
            </div>
        );
    };

    return (
        <div className="container-dashboard">
            <div className={`sidebar ${isOpen ? 'open' : 'collapsed'}`}>
                <div className="top_section">
                    <img src={logo}      alt="Logo"       className="logo"      style={{ display: isOpen ? "block" : "none", height: "28px", width: "40px" }}   />
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">      <FaBars onClick={toggle} />     </div>
                </div>

                <div className="">
                    
                    <Accordion title="Users" isOpen={isOpen}>
                        {hasRole(["admin", "power", "billing", "audit", "support"]) && (
                                <NavItem to="/dashboard/user"               isOpen={isOpen}         text="All"      icon={FaUsers} />
                        )}

                        

                        {hasRole(["admin", "power", "support"]) && (
                                <NavItem to="/dashboard/affiliate"          isOpen={isOpen}         text="Affiliates"       icon={FaUsers} />
                        )}
                    </Accordion>
                    
                    <Accordion title="Billing" isOpen={isOpen}>
                        {hasRole(["admin", "power", "billing", "audit", "support"]) && (
                                <NavItem to="/dashboard/billing"            isOpen={isOpen}         text="Invoices"         icon={FaReceipt} />
                        )}
                    </Accordion>

                    <Accordion title="Manage" isOpen={isOpen}>
                        {hasRole(["admin", "power", "affiliate", "support"]) && (
                                <NavItem to="/dashboard/video-upload"       isOpen={isOpen}         text="Promo"      icon={FaVideo} />
                        )}

                        {hasRole(["admin", "power", "support"]) && (
                            <>
                                <NavItem to="/dashboard/vehicle-parts-req"  isOpen={isOpen}         text="Parts-Requests"   icon={FaList} />
                            </>
                        )}

                        {hasRole(["admin", "power", "support"]) && (
                            <>
                                <NavItem to="/dashboard/manage-userDoc"  isOpen={isOpen}         text="User-Docs"   icon={FaList} />
                            </>
                        )}

                        {hasRole(["admin", "power", "support"]) && (
                            <>
                                <NavItem to="/dashboard/transactions"  isOpen={isOpen}         text="Transactions"   icon={FaList} />
                            </>
                        )}
                    </Accordion>

                    <Accordion title="Services" isOpen={isOpen}>
                        {hasRole(["admin", "power", "billing", "support"]) && (  
                            <>
                                <NavItem to="/dashboard/marketing"          isOpen={isOpen}         text="Marketing"        icon={GiAcousticMegaphone}   />
                                <NavItem to="/dashboard/internet"           isOpen={isOpen}         text="Internet"         icon={FaWifi}   />
                                <NavItem to="/dashboard/vehiclePart"        isOpen={isOpen}         text="Vehicle-Part"     icon={FaTruckPickup} />
                                <NavItem to="/dashboard/technology"         isOpen={isOpen}         text="Learning"         icon={DiTechcrunch} />
                                <NavItem to="/dashboard/transcript"         isOpen={isOpen}         text="Transcripts"      icon={TbWriting} />
                                <NavItem to="/dashboard/remittance"         isOpen={isOpen}         text="Remittances"      icon={RiMoneyDollarCircleLine} />
                            </>
                        )}
                    </Accordion>

                    <Accordion title="Lists" isOpen={isOpen}>
                        {hasRole(["admin", "power", "support"]) && (
                            <>
                                <NavItem to="/dashboard/manage-category"    isOpen={isOpen}         text="Interests"   icon={BiCategory} />
                                <NavItem to="/dashboard/manage-cars"        isOpen={isOpen}         text="Vehicle"     icon={FaTruckPickup} />
                                <NavItem to="/dashboard/vehicle-parts"          isOpen={isOpen}         text="Parts"       icon={FaTools} />
                            </>
                        )}
                    </Accordion>

                    

                    <Accordion title="Admin" isOpen={isOpen}>

                        {hasRole(["admin"]) && (
                            <>
                                <NavItem to="/dashboard/admin-settings"     isOpen={isOpen}         text="Settings"   icon={RiSettings4Line} />
                            </>
                        )}
                    </Accordion>

                    <Accordion title="My User" isOpen={isOpen}>
                        <NavItem to="/settings"                     isOpen={isOpen}         text="Settings"         icon={FaUserAlt} />
                        <NavItem to="/subscriptions"                isOpen={isOpen}         text="Services"    icon={FaUserAlt} />
                    </Accordion>

                    <Accordion title="Menu" isOpen={isOpen}>
                        <NavItem to="/"                             isOpen={isOpen}         text="Home"             icon={FaHome} />
                
                        <NavItemLogout                              isOpen={isOpen}         text="Logout"           icon={FaSignOutAlt}  onClick={handleLogout}/>
                    </Accordion>
                    

                </div>
            </div>
        </div>
    );
};

export default Sidebar;
