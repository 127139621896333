/* REAL - SETTINGS - REFERRALS */
/* File: /src/pages/Settings/Referrals/index.jsx  */
import { useTranslation 
}                                               from 'react-i18next';
import { 
        FaChevronDown, 
        FaChevronUp 
        }                                           from 'react-icons/fa';
import { Button, 
         Col, 
         Form, 
         Row,
     //    Modal,
         Tooltip, 
         OverlayTrigger, 
         Image,
        }                                           from "react-bootstrap";
import { 
         useState }                                 from "react";
import { toast }                                    from "react-toastify";
import { useNavigate }                              from "react-router-dom";
import { FormControlField, 
         FormLabelText, 
        }                                           from "./ReferralsStyle";
import { TabContentContainer }                      from "../../Tabs/tabContentStyle";

import { staggerVariants, 
         titleVariants }                            from "../../../utils/FmVariants";
import {
        useGetReferralsQuery,
        useCreateReferralMutation,
        }                                           from "../../../services/userUserStoreApi";
import {
        useListServicesQuery,
        }                                           from  "../../../services/svcServiceStoreApi";

import                                              "./ReferralsStyle.css";
import BootstrapTable                               from "react-bootstrap-table-next";
import paginationFactory                            from "react-bootstrap-table2-paginator";
import moment                                       from "moment";
import { Images }                                    from "../../../config/images";


const ModuleDocumentInfo = ({ user }) => {
    const { t } = useTranslation('settingsReferrals');
    const Source_Name           = "SETTINGS - REFERRALS";
    const STG_DateTime_Format                                   = "YYYY-MM-DD, HH:mm:ss";
    const navigate                                              = useNavigate();
    const [refEmail,                setRefEmail]                = useState("");
    const [selectedService,         setSelectedService]         = useState(""); // State for the selected service
    const [showReferralAFriend,     setShowReferralAFriend]     = useState(false); // State for toggling 'Referral A Friend'
    const [showReferralList,        setShowReferralList]        = useState(false); // State for toggling 'Referral List'
    const [showRewards,             setShowRewards]             = useState(false); // State for toggling 'Rewards'

    const { data: UserStore_ReferralList }                      = useGetReferralsQuery({ id: user?._id, source: Source_Name });
    const { data: Services_List}                                = useListServicesQuery({ source: Source_Name });

    const [UserStore_CreateReferral]                            = useCreateReferralMutation();
    const referrals = UserStore_ReferralList?.data || [];  // Use the data from the API, fallback to an empty array if not available



    // Define table columns
    const columns = [
        {
            dataField: "refEmail",
            text: t('table_headers.referral_email'),  // Use translation for "Referral Email"
            sort: true,
        },
        {
            dataField: "refDate",
            text: t('table_headers.referral_date'),  // Use translation for "Referral Date"
            sort: true,
            formatter: (cell) => moment(cell).format(STG_DateTime_Format),
        },
        {
            dataField: "refStatus",
            text: t('table_headers.status'),  // Use translation for "Status"
            sort: true,
        },
        {
            dataField: "refStatusDate",
            text: t('table_headers.status_date'),  // Use translation for "Status Date"
            sort: true,
            formatter: (cell) => moment(cell).format(STG_DateTime_Format),
        },
        {
            dataField: "refReward",
            text: t('table_headers.reward'),  // Use translation for "Reward"
            sort: true,
        },
        {
            dataField: "refService",
            text: t('table_headers.service'),  // Use translation for "Service"
            sort: true,
        },
    ];

    // Define table pagination options
    const paginationOptions = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    const handle_SendReferral = async () => {
        // Perform necessary validation, e.g., checking if refEmail is valid
        if (!refEmail) {
            toast.error("Please enter a valid email address.");
            return;
        }
    
        // Create a data object that contains the necessary fields
        const data = {
            refEmail,                  // The email of the person being referred
            refUserId: user?._id,      
            refService: selectedService,   // The service selected from the dropdown
        };

        try {
            const response = await UserStore_CreateReferral({ data, source: Source_Name }).unwrap();

            // Handle response, e.g., show success message
            if (response.status) {
                toast.success(`${response.message}`);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("An error occurred while sending the referral.", error);
        }
    };

    
    
return (
    <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>

        {/*  HEADER MY REFERRALS */}
        <div className="d-flex justify-content-between align-items-center">
            <div className="mt-4">
                <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>
                    {t('titles.my_referrals_rewards')}
                </h5>
            </div>
            <div className="d-flex">
                <OverlayTrigger 
                    placement="top" 
                    overlay={<Tooltip id="tooltip-mypromo">{t('tooltips.modify_contact')}</Tooltip>} 
                >
                    <Button 
                        variant="primary" 
                        className="ms-2" 
                        onClick={() => navigate("/settings?tab=contact-info")}
                    >
                        {t('buttons.modify_contact')}
                    </Button>
                </OverlayTrigger>
            </div>
        </div>


        <hr className="mt-1" />

        <div className="row">
            {/*  REFERRAL SECTION  */}
            {/* Toggleable Referral List Section */}
            <h5 className="mb-0 pb-0" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setShowReferralAFriend(!showReferralAFriend)}>
                {t('titles.refer_a_friend')} {showReferralAFriend ? <FaChevronUp /> : <FaChevronDown />}
            </h5>
            <h6 className="mb-0 pb-0" style={{ color: 'black' }}>
                {t('descriptions.enter_email_of_loved_one')}
            </h6>
            <span><br /></span>
            <Row style={{ display: showReferralAFriend ? 'block' : 'none' }}>
                <Col md={6} lg={6} xl={4}>
                    <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
                        <FormLabelText variants={titleVariants}>{t('labels.referral_email')}</FormLabelText>
                        <FormControlField
                            required                   type="text"            variants={titleVariants}       
                            value={refEmail}           placeholder={t('placeholders.person_email')}          onChange={e => setRefEmail(e.target.value)}
                        />{" "}
                    </Form.Group>{" "}

                    {/* Dropdown for selecting service */}
                    <Form.Group className="mb-4">
                        <FormLabelText variants={titleVariants}>
                            {t('labels.select_service')} ({t('labels.marketing_always_included')})
                        </FormLabelText>
                        <Form.Control 
                            as="select" 
                            value={selectedService} 
                            onChange={(e) => setSelectedService(e.target.value)}
                        >
                            <option value="">{t('placeholders.select_service')}</option>
                            {/* Check if Services_List.data exists and is an array, and filter to include only published services */}
                            {Array.isArray(Services_List?.data) && 
                                Services_List.data
                                    .filter(service => service.published) // Filter to only include services with published as true
                                    .map((service) => (
                                        <option key={service.serviceName} value={service.serviceName}>
                                            {service.serviceName}
                                        </option>
                                    ))
                            }
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" onClick={handle_SendReferral}>
                        {t('buttons.send')}
                    </Button>
                </Col>
            </Row>




            <span><br /></span>
            <span><br /></span>
            {/* Toggleable Referral List Section */}
            <h5 className="mb-0 pb-0" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setShowReferralList(!showReferralList)}>
                {t('titles.referral_list')} {showReferralList ? <FaChevronUp /> : <FaChevronDown />}
            </h5>
            <h6 className="mb-0 pb-0" style={{ color: 'black' }}>
                {t('descriptions.list_of_people_helped')}
            </h6>
            <span><br /></span>

            {showReferralList && (
                <div className="table-responsive">
                    <BootstrapTable
                        bootstrap4
                        keyField="refEmail"
                        data={referrals} // Referral data state
                        columns={columns}
                        pagination={paginationOptions}
                        striped
                        hover
                        condensed
                    />
                </div>
            )}



            <span><br /></span>
            <span><br /></span>
            {/* Toggleable Rewards Section */}
            <h5 className="mb-0 pb-0" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setShowRewards(!showRewards)}>
                {t('titles.rewards')} {showRewards ? <FaChevronUp /> : <FaChevronDown />}
            </h5>

            {showRewards && (
                <>
                    <h6 className="mb-0 pb-0" style={{ color: 'black' }}>
                        {t('descriptions.reward_efforts_message')}
                    </h6>
                    <Image src={Images.stayTuned} style={{ width: '30%' }} fluid />

                    <span><br /></span>
                    <span><br /></span>
                </>
            )}

            <span><br /></span>
            <span><br /></span>

            <h6 className="mb-0 pb-0" style={{ color: 'black' }}>
                {t('descriptions.keep_referring_message')}
            </h6>

            <span><br /></span>
            <span><br /></span>


        </div>


    </TabContentContainer>

    

    );
};

export default ModuleDocumentInfo;